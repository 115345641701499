import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logout-btn',
  templateUrl: './logout-btn.component.html',
  styleUrls: ['./logout-btn.component.css'],
})
export class LogoutBtnComponent {
  constructor(private router: Router,private msalService:MsalService) {}

  logoutHandler() {
      this.msalService.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    
    //localStorage.clear();
    //this.router.navigate(['/']);
  }
}
