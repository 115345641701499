<div mat-dialog-content style="width: 470px; height: 535px; max-height: 550px; text-align: center">
  <div style="text-align: center">
    <img
      mat-card-sm-image
      style="width: auto; height: auto; max-width: 400px; max-height: 100px; margin-bottom: 10px"
      src="{{ settingsService.orgDetails.logoURL }}"
      alt="Photo of a Shiba Inu"
    />
  </div>
  <p style="text-align: center">Edit Company Social Links</p>
  <form [formGroup]="editCompanyUrl">
    <mat-form-field style="width: 90%; margin: auto">
      <mat-label>Facebook Link</mat-label>
      <input type="text" matInput formControlName="facebookLink" placeholder="Enter Facebook Link" />
      <mat-error *ngIf="facebookLink.hasError('required')"> Facebook Link <strong>required</strong> </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 90%; margin: auto">
      <mat-label>Twitter Link</mat-label>
      <input type="text" matInput formControlName="twitterLink" placeholder="Enter Twitter Link" />
      <mat-error *ngIf="twitterLink.hasError('required')"> Twitter Link <strong>required</strong> </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 90%; margin: auto">
      <mat-label>Instagram Link</mat-label>
      <input type="text" matInput formControlName="instagramLink" placeholder="Enter Instagram Link" />
      <mat-error *ngIf="instagramLink.hasError('required')"> Instagram Link <strong>required</strong> </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 90%; margin: auto">
      <mat-label>Linkedin Link</mat-label>
      <input type="text" matInput formControlName="linkdinLink" placeholder="Enter Linkedin Link" />
      <mat-error *ngIf="linkdinLink.hasError('required')"> Linkedin Link <strong>required</strong> </mat-error>
    </mat-form-field>
    <div mat-dialog-actions class="flex-c">
      <button
        extended
        mat-stroked-button
        (click)="onNoClick()"
        style="height: 56px; border-radius: 30px; margin-right: 20px; width: 100px"
      >
        Cancel
      </button>
      <button
        mat-fab
        extended
        color="primary"
        [disabled]="!editCompanyUrl.valid"
        [mat-dialog-close]="{facebookLink:editCompanyUrl.value.facebookLink,twitterLink:editCompanyUrl.value.twitterLink,instagramLink:editCompanyUrl.value.instagramLink,linkdinLink:editCompanyUrl.value.linkdinLink}"
        style="display: block; width: 100px"
      >
        Update
      </button>
    </div>
  </form>
</div>
