
export enum Template {
    Static = 1,
    Animated = 2
}

export enum Status {
    Unknown = 0,
    Active = 1,
    Inactive = 2,
    Deleted = 3,
    Canceled = 4,
    Expired = 5
}

export enum PaymentStatus {
    Pending = 0,
    Completed = 1,
    Failed = 2
}

export enum EmailSend {
    None = 0,
    Selected = 1,
    All = 2
}

export enum EmailStatus {
    Failed = 0,
    Delivered = 1
}

export enum Heading {
    System = 1,
    Own = 2
}

export enum Rated {
    Unknown = 0,
    Negative = 1,
    Normal = 2,
    Positive = 3,
    Unhappy = 4,
    Angry = 5
}

export enum Role {
    Owner = 3,
    SuperAdmin = 2,
    Admin = 1,
    User = 0
}

export enum IPStatus {
    None = 0,
    Active = 1,
    Blocked = 2,
    Restricted = 3
}

export enum UnsubscribeType {
    None = 0,
    Restricted = 1,
    AllBlocked = 2
}