<div style="padding: 30px">
  <div class="">
    <h1 class="mb-20">New Server</h1>
    <p>You are connecting to your SMTP service</p>
  </div>
  <div class="mt-10" [formGroup]="emailForm">
    <mat-radio-group aria-label="Select an option"  formControlName="type" (ngModelChange)="onChange($event)">
      <mat-radio-button value="0">SMTP</mat-radio-button>
      <mat-radio-button value="1">API Key</mat-radio-button>
    </mat-radio-group>
    <p>API Secret Key</p>
    <mat-form-field class="w-100p">
      <input type="text" maxlength="200" matInput  formControlName="api_Key"/>
    </mat-form-field>
    <p>Partner Secret Key</p>
    <mat-form-field class="w-100p">
      <input type="text" maxlength="200" matInput  formControlName="partner_Secret_Key"/>
    </mat-form-field>
    <p>Domain</p>
    <mat-form-field class="w-100p">
      <input type="text" maxlength="100" matInput  formControlName="domain"/>
    </mat-form-field> 
    <p>Host</p>
    <mat-form-field class="w-100p">
      <input type="text" maxlength="100" matInput  formControlName="smtp_Host"/>
    </mat-form-field>
    <p>SMTP username</p>
    <mat-form-field class="w-100p">
      <input type="text" maxlength="100" matInput  formControlName="smtp_UserName"/>
    </mat-form-field>
    <p>SMTP Password</p>
    <mat-form-field class="w-100p">
      <input type="password" maxlength="100" matInput  formControlName="smtp_Password"/>
    </mat-form-field>
    <p>From Email</p>
    <mat-form-field class="w-100p">
      <input type="email" maxlength="100" matInput  formControlName="email_From"/>
    </mat-form-field>
    <p>From Name</p>
    <mat-form-field class="w-100p">
      <input type="text" maxlength="100" matInput  formControlName="email_From_Name"/>
    </mat-form-field>
    <p>SMTP Port</p>
    <mat-form-field class="w-100p">
      <input type="number" maxlength="3" matInput  formControlName="smtp_Port"/>
    </mat-form-field>
  </div>
   
  <div mat-dialog-actions class="flex-c">
    <button class="mr-20" (click)="onSubmit(emailForm.value)" mat-fab extended color="primary" style="display: block; width: 100px">
      Save
    </button>
    <button extended mat-stroked-button (click)="onCancel()"  style="height: 56px; border-radius: 30px; margin-right: 20px; width: 100px">
      Cancel
    </button>
  </div>
</div>
