import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/services/auth.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { ImportStaffMember } from '../staff-member/staff-member.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

export class CsvData {
  public name: any;
  public email: any;
}

@Component({
  selector: 'app-marketing-list',
  templateUrl: './marketing-list.component.html',
  styleUrls: ['./marketing-list.component.css'],
})
export class MarketingListComponent {
  @ViewChild('csvReader') csvReader: any;
  records: any[] = [];
  jsondatadisplay: any;
  recordNotFound: Boolean = false;
  loadingImportedStaff: boolean = false;
  organizationId: string = '';
  isListLoaded: boolean = false;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: any[] = ['name', 'email',];
  downloadReport: any = [];
  allSurveyList: any[] = [];
  userTokenData: any;
  staffMembers: any[] = [];
  isMarkitingListCreated: boolean = false;
  allCustomerLists: any[] = [];
  selectedList: any = {
    listName: '',
  };

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(
    private authService: MsalService,
    public _toastr: ToastrService,
    public authSer: AuthService,
    private _contactService: ContactsService,
    private _dialog: MatDialog
  ) {
    this.userTokenData = this.authSer.decodeToken();
    this.organizationId = this.userTokenData.organizationId;
    this.isListLoaded = false;
    this._contactService.getAllCustomerLists(this.organizationId).subscribe((allLists: any) => {
      this.isListLoaded = true;
      if (allLists.length === 0) {
        this.recordNotFound = true;

        return;
      }

      this.allCustomerLists = allLists;
      this.selectedList = this.allCustomerLists[0];
      this._contactService.getSingleListData(this.selectedList._id).subscribe((singleList: any) => {
        if (singleList.contacts.length === 0) {
          this.recordNotFound = true;
        }
        this.isListLoaded = true;
        this.dataSource = singleList.contacts.map((list: any) => {
          return { name: list.name, email: list.email };
        });
      });
    });
  }

  exportAsXLSX(): void {
    this._contactService.exportAsExcelFile(this.downloadReport, 'sample');
  }

  getSingleList(list: any) {
    this.isListLoaded = false;
    this._contactService.getSingleListData(list._id).subscribe(res => {
      this.isListLoaded = true;
      this.dataSource = res.contacts.map((list: any) => {
        return { name: list.name, email: list.email };
      });
    });
  }

  // to open the dialog when react pack selected
  openStaffImportDialog(enterAnimationDuration: string, exitAnimationDuration: string, importedUser: any[]): void {
    let dialogRef = this._dialog.open(ImportStaffMember, {
      width: '670px',
      height: '500px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        users: importedUser,
        title: 'Imported Customer List',
        btnTxt: 'Add Customers',
        type: 'marketingList',
        search: 'Search Customer',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      console.log('data', res);
      // received data from dialog-component
      if (res) {
        const contacts = [];
        for (let user of res.data) {
          if (user.selected === true) {
            const data = {
              _id: '',
              name: user.name,
              email: user.email,
              customerListId: '',
              status: 1,
            };

            contacts.push(data);
          }
        }

        const payload = {
          _id: '',
          listName: res.addListName.value,
          createdByUserId: this.userTokenData.userId,
          orgId: this.organizationId,
          createdBy: this.userTokenData?.displayName,
          isPrivate: true,
          status: 1,
          contacts,
        };
        this.isMarkitingListCreated = true;
        this._contactService.addCustomerListName(payload).subscribe(
          res => {
            this._toastr.success('', 'Marketing List created');
            this.isMarkitingListCreated = false;
          },
          err => {
            this._toastr.error('', 'Something went wrong ');
            this.isMarkitingListCreated = false;
          }
        );
      }
    });
  }

  logout() {
    this.authService.logoutPopup({
      postLogoutRedirectUri: '/dashboard',
      mainWindowRedirectUri: '/dashboard',
    });
  }

  // Mat table functions
  dataSource = new MatTableDataSource<any>();

  uploadListener($event: any): void {
    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

        let headersRow = this.getHeaderArray(csvRecordsArray);

        const ImportedUsers = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
        this.records = ImportedUsers.filter(user => this.validateEmail(user.email));
        if (this.records.length === 0) {
          alert('Please import valid .csv file with name and email.');
          this.fileReset();
        } else {
          this.records = this.records.map((ele: any) => {
            ele.selected = false;
            ele.isMatchingWithSearch = true;
            ele.displayName = ele.name;
            ele.email = ele.email;

            return ele;
          });
          this.openStaffImportDialog('0ms', '0ms', this.records);
        }
      };

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };
    } else {
      alert('Please import valid .csv file.');
      this.fileReset();
    }
  }

  // check etension
  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord: CsvData = new CsvData();
        csvRecord.name = curruntRecord[0].trim();
        csvRecord.email = curruntRecord[1].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
    this.jsondatadisplay = '';
  }

  validateEmail(email: any) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
}
