import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  EditSurveyHeadingModel,
  RatingSurveySetupComponent,
  StaticIconsDialog,
} from './pages/ratings-survey-setup/ratings-survey-setup.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CustomerFeedbackComponent } from './pages/customer-feedback/customer-feedback.component';
import {
  AddStaffMember,
  ImportStaffMember,
  RemoveStaffMember,
  StaffMemberComponent,
} from './pages/staff-member/staff-member.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { AuthenticationGuard } from './authentication.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { HttpRequestInterceptor } from './httpInterceptor';
import { NgChartsModule } from 'ng2-charts';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxStripeModule } from 'ngx-stripe';

import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
  InteractionType,
} from '@azure/msal-browser';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalBroadcastService,
  MsalService,
  MsalGuard,
  MsalRedirectComponent,
  MsalModule,
  MsalInterceptor,
} from '@azure/msal-angular';
import { CommonModule } from '@angular/common';
import { AllSurveysComponent, ViewSingleSurveyModel } from './pages/all-surveys/all-surveys.component';
import { StaffCopySurveyComponent } from './pages/staff-copy-survey/staff-copy-survey.component';
import { LogoutBtnComponent } from './components/logout-btn/logout-btn.component';
import EditCompanySocialUrl, {
  ResetPassword,
  EditCompanyDetails,
  PaymentModal,
  PremiumPlanModal,
  SettingsComponent,
  ViewInvoiceDetails,
  EmailSetupForm,
} from './pages/settings/settings.component';
import { HandleErrorsInterceptorInterceptor } from './handle-errors-interceptor.interceptor';
import {
  SurveyJSCreatorComponent,
  CreateEditSurveyProjectModal,
  ImportContactsModal,
  SendSurveyEmailDialog,
} from './pages/survey-js-creator/survey-js-creator.component';
import { SurveyCreatorModule } from 'survey-creator-angular';
import { DisplaySurveyToFillComponent } from './pages/survey-js-creator/display-survey-to-fill/display-survey-to-fill.component';
import { SurveyModule } from 'survey-angular-ui';
import { ResultDashboardComponent } from './pages/survey-js-creator/dashboard/dashboard.component';

import { setLicenseKey } from 'survey-core';
import { CustomerFillSurveyComponent } from './pages/ratings-survey-setup/customer-fill-survey/customer-fill-survey.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import {
  DocumentAnalysisComponent,
  ViewProcessedResultModel,
} from './pages/document-analysis/document-analysis.component';
import { MarketingListComponent } from './pages/marketing-list/marketing-list.component';
import { StaffDashboardComponent } from './pages/staff-dashboard/staff-dashboard.component';
import { UnsubscribingComponent } from './pages/unsubscribing/unsubscribing.component';
import { UnsubscriberListComponent } from './pages/unsubscriber-list/unsubscriber-list.component';
import { environment } from 'src/environments/environment';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {  EmailSetupViewComponent } from './pages/settings/email-setup-view';
import { MatFormFieldModule } from '@angular/material/form-field';
setLicenseKey('ZGEwZGYyNGYtYjY1Yy00NjRiLTkxNjUtNjY4M2ViZDAzODZiOzE9MjAyNC0xMS0xMywyPTIwMjQtMTEtMTMsND0yMDI0LTExLTEz');

const GRAPH_ENDPOINT = 'Enter_the_Graph_Endpoint_Herev1.0/me';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


export function loggerCallback(logLevel: LogLevel, message: string) { console.log(message);}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes]
    },
    loginFailedRoute: '/'
  };
}
// export function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication({
//     auth: {
//       clientId: environment.msalConfig.auth.clientId,
//       authority: environment.msalConfig.auth.authority,//'https://login.microsoftonline.com/common',
//       redirectUri: environment.msalConfig.auth.redirectUri,
//     },
//     cache: {
//       cacheLocation: BrowserCacheLocation.LocalStorage,
//       storeAuthStateInCookie: isIE, // set to true for IE 11
//     },
//     system: {
//       loggerOptions: {
//         loggerCallback,
//         logLevel: LogLevel.Info,
//         piiLoggingEnabled: false,
//       },
//     },
//   });
// }

// export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
//   const protectedResourceMap = new Map<string, Array<string>>();
//   protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);

//   return {
//     interactionType: InteractionType.Redirect,
//     protectedResourceMap,
//   };
// }

// export function MSALGuardConfigFactory(): MsalGuardConfiguration {
//   return {
//     interactionType: InteractionType.Redirect,
//     authRequest: {
//       scopes: environment.apiConfig.scopes,
//     },
//   };
// }

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RatingSurveySetupComponent,
    StaticIconsDialog,
    ImportStaffMember,
    AddStaffMember,
    RemoveStaffMember,
    EditSurveyHeadingModel,
    DashboardComponent,
    CustomerFeedbackComponent,
    StaffMemberComponent,
    OverviewComponent,
    LoaderComponent,
    AllSurveysComponent,
    StaffCopySurveyComponent,
    LogoutBtnComponent,
    SettingsComponent,
    ResetPassword,
    EditCompanyDetails,
    EditCompanySocialUrl,
    ViewInvoiceDetails,
    PaymentModal,
    PremiumPlanModal,
    ViewSingleSurveyModel,
    SurveyJSCreatorComponent,
    SendSurveyEmailDialog,
    CreateEditSurveyProjectModal,
    DisplaySurveyToFillComponent,
    ResultDashboardComponent,
    ImportContactsModal,
    CustomerFillSurveyComponent,
    PageNotFoundComponent,
    DocumentAnalysisComponent,
    ViewProcessedResultModel,
    MarketingListComponent,
    StaffDashboardComponent,
    UnsubscribingComponent,
    UnsubscriberListComponent,
    UserProfileComponent,
    EmailSetupViewComponent,
    EmailSetupForm
  ],
  imports: [
    BrowserModule,
    NgxStripeModule.forRoot(
      'pk_test_51Jst84IxICjzN1vhk4nfeoP7hqUov9iQoP25vz8ESnhkFSu1CSKnAoSLBePyUvfQWk8LRoGVqaSlVGoXiR4UN4oV00hPYHaBs9'
    ),
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgChartsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      maxOpened: 1,
      progressBar: true,
    }),
    MsalModule,
    SurveyCreatorModule,
    SurveyModule,
    MatDialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleErrorsInterceptorInterceptor,
      multi: true,
    },
    // {provide:MatDialogRef , useValue:{} },
    // { provide: MAT_DIALOG_DATA, useValue: {} },
    AuthenticationGuard,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
