<div mat-dialog-content style="width: 500px; height: 600px; max-height: 380px">
  <div style="text-align: center">
    <img
      mat-card-sm-image
      style="width: auto; height: auto; max-width: 400px; max-height: 100px; margin-bottom: 10px"
      src="{{ settingsService.orgDetails.logoURL }}"
      alt="Photo of a Shiba Inu"
    />
  </div>
  <p style="text-align: center">Add Staff Members</p>
  <form [formGroup]="addStaffForm">
    <mat-form-field style="width: 100%; margin: auto">
      <input type="text" matInput formControlName="staffName" placeholder="Enter staff full name" />
      <mat-error *ngIf="staffName.hasError('required')"> Full name is <strong>required</strong> </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%; margin: auto">
      <input type="email" matInput formControlName="staffEmail" placeholder="Enter staff email" />
      <mat-error *ngIf="staffEmail.hasError('email') && !staffEmail.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="staffEmail.hasError('required')"> Email is <strong>required</strong> </mat-error>
    </mat-form-field>
    <div mat-dialog-actions class="flex-c">
      <button
        extended
        mat-stroked-button
        (click)="onNoClick()"
        style="height: 56px; border-radius: 30px; margin-right: 20px; width: 100px"
      >
        Cancel
      </button>
      <button
        mat-fab
        extended
        [mat-dialog-close]="{ displayName: addStaffForm.value.staffName, mail: addStaffForm.value.staffEmail, selected: true }"
        color="primary"
        [disabled]="!addStaffForm.valid"
        style="display: block; width: 100px"
      >
        Add
      </button>
    </div>
  </form>
</div>
