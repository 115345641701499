<section class="page_404">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="col-sm-10 col-sm-offset-1 text-center" style="width: 700px; margin: auto; margin-top: 80px">
          <div class="four_zero_four_bg">
            <h1 class="text-center" style="text-align: center">404</h1>
          </div>

          <div class="contant_box_404" style="text-align: center; margin-top: 30px">
            <h3 class="h2">Look like you're lost</h3>

            <p>the page you are looking for not avaible!</p>

            <a [routerLink]="['/']" class="link_404">Go to Home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
