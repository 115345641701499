import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PeriodicElement } from '../customer-feedback/customer-feedback.component';
import { SelectionModel } from '@angular/cdk/collections';
import { StaffDashboardService } from 'src/app/services/staff-dashboard.service';
import { AuthService } from 'src/app/services/auth.service';
import { Chart, ChartOptions, ChartType} from 'chart.js';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-staff-dashboard',
  templateUrl: './staff-dashboard.component.html',
  styleUrls: ['./staff-dashboard.component.css'],
})
export class StaffDashboardComponent {
  organizationId: any;  
   
  @ViewChild("npsChart") npschart:ElementRef<any> | undefined;
  isAllRatingsLoaded: boolean = false;
  canvas: any;
  ctx: any;
  negative:any=[];
  positive:any=[];
  neutral:any=[];  
  subscriptions: Subscription[] = [];
  // Mat table functions
  dataSource: any = [];
  recordNotFound: boolean = false;
  displayedColumns: any[] = ['name', 'positive', 'negative', 'angry', 'totalResponse', 'NPS', 'temperature'];
  labels:any= ["Jan","Feb", "Mar", "Apr", "May","Jun","Jul", "Aug","Sep","Oct","Nov","Dec"];
  selection = new SelectionModel<PeriodicElement>(true, []);

  constructor(private _staffDashboardService: StaffDashboardService, public _authSer: AuthService) {
    const userTokenData = this._authSer.decodeToken();
    this.organizationId = userTokenData.organizationId;
   
    this.subscriptions[ this.subscriptions.length+1]= this._staffDashboardService.getAllRatings(this.organizationId).subscribe(
      res => {
        this.isAllRatingsLoaded = true;
        this.dataSource = res;
        if (res.length === 0) {
          this.recordNotFound = true;
        }
      },
      err => {
        this.isAllRatingsLoaded = true;
      }
    );
   console.log(this.npschart)
  
  }
  ngAfterViewInit() {
    this.canvas = this.npschart?.nativeElement; 
    this.ctx = this.canvas?.getContext('2d');
    let chartOptions:ChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      borderColor:"green",
      indexAxis: 'x',
      scales: {
        x: {
          grid: {
            display:false,
            color: "green",
          },
          ticks: {},
          pointLabels: {
              display: true,
          },
          title: {
              display: true,
              text: "Month",
              color: "green",
              font: {
                  weight: "bold",
                  size: 16,
                  lineHeight: 2,
              },
          },
      },
      
       
        y: {
          grid: {
            display: false
          },
          ticks: {
            maxTicksLimit:100},
          pointLabels: {
              display: true,
          },
          title: {
              display: false,
              text: "Number",
              color: "green",
              font: {
                  weight: "bold",
                  size: 16,
                  lineHeight: 2,
              },
          },
        }
      }
    };
    this.subscriptions[ this.subscriptions.length+1]= this._staffDashboardService.getAllChartRatings(this.organizationId).subscribe(
      res => {
        this.isAllRatingsLoaded = true;
        this.dataSource = res;
        res?.forEach((el:any) => {
          this.negative.push({x:this.labels[el.month-1],y:el.negative})
          this.positive.push({x:this.labels[el.month-1],y:el.positive})
          this.neutral.push({x:this.labels[el.month-1],y:el.neutral})
        });
       console.log(this.negative,this.positive,)
       new Chart(this.ctx, {
        type: 'line',
        data: {
          //labels:this.labels,
          datasets: [
            {
              label: "Positive",
              data: this.positive,//[0, 0, 33.33, 0, 0, 0, 0, 0, 0, 0, 0, 5.56],
              fill: true,
              borderColor: "green",
              backgroundColor: "rgba(125, 218, 88, 0.5)",
              borderWidth: 1
            },
            {
              label: "Neutral",
              data: this.neutral,//[50, 100, 0, 0, 0, 25, 0, 50, 0, 8.33, 9.26, 11.11],
              fill: true,
              backgroundColor: "rgba(254, 153, 0, 0.5)",
              borderColor: "yellow",
              borderWidth: 1
            },
            {
              label: "Negative",
              data:this.negative,// [5, 40, 6, 20, 0, 50, 0, 50, 0, 40 , 9, 80],
              fill: true,
              backgroundColor: "rgba(228, 8, 10, 0.5)",
              borderColor: "red",
              borderWidth: 1
            },
        
          ]
        },
        // data: {
        //   datasets: [{
        //     label: 'Höhenlinie',
        //     backgroundColor: "rgba(255, 99, 132,0.4)",
        //     borderColor: "rgb(255, 99, 132)",
        //     fill: true,
        //     data: [
        //       { x: 1, y: 2 },
        //       { x: 2500, y: 2.5 },
        //       { x: 3000, y: 5 },
        //       { x: 3400, y: 4.75 },
        //       { x: 3600, y: 4.75 },
        //       { x: 5200, y: 6 },
        //       { x: 6000, y: 9 },
        //       { x: 7100, y: 6 },
        //     ],
        //   }]
        // },
        options:chartOptions
      });
      },
      err => {
        this.isAllRatingsLoaded = true;
      }
    );
    
    
  }
  exportAsXLSX(): void {
    let downloadReport: any = this.dataSource.map((res: any) => {
      return {
        Name: res.staffMemberName,
        Negative: res.negative,
        Positive: res.positive,
        Neutral: res.neutral,
        'Total Response': res.count,
        NSP: res.nps,
        Temperature: res.temperature,
      };
    });
    this._staffDashboardService.exportAsExcelFile(downloadReport, 'Rating List');
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

