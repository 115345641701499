<div>
  <mat-card class="shadow-none">
    <mat-card-content style="padding: 20">
      <mat-tab-group mat-align-tabs="center" class="surveyJS-import-tab">
        <mat-tab label="USERS LIST">
          <div class="flex-c mt-20" style="gap: 60px">
            <div class="email-clients" class="flex flex-col w-100p">
              <h4>Users List</h4>
              <div class="flex-c">
                <table>
                  <tr>
                    <th>Name</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Users</th>
                    <th>Actions</th>
                  </tr>
                  <tr *ngIf="allUsersList.length === 0 && !loadingUsersList">
                    <td colspan="5">
                      <h3 style="text-align: center; margin-top: 16px">No Users List</h3>
                    </td>
                  </tr>
                  <tr *ngFor="let list of allUsersList">
                    <td style="width: 30%">{{list.listName}}</td>
                    <td style="width: 25%">{{list.createdByName}}</td>
                    <td style="width: 20%">{{getDateAndTime(list.createdAt)}}</td>
                    <td>{{list.listUsers.length}}</td>
                    <td style="width: 90px">
                      <button
                        (click)="viewListHandler(list.listUsers, list._id)"
                        style="background-color: green"
                        mat-mini-fab
                      >
                        <mat-icon>remove_red_eye</mat-icon>
                      </button>
                      <button
                        (click)="openUserListRemoveDialog('0ms', '0ms', list._id)"
                        mat-mini-fab
                        style="background-color: red; margin-left: 10px"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="data.isHrSurvey" style="height: 200px" label="IMPORT FROM EMAIL CLIENTS">
          <div class="flex-c mt-20" style="gap: 60px">
            <div class="email-clients" class="flex flex-col w-100p">
              <h4>Import Staff Members from Email</h4>
              <div class="flex-c">
                <div style="padding: 10px; border: 1px solid gray; border-radius: 10px">
                  <img src="../../../assets/images/ms-365.png" style="width: 200px; height: 40px; margin-top: 8px" />
                </div>
                <button mat-raised-button class="ml-10" color="accent" *ngIf="loginDisplay" (click)="logout()">
                  Logout
                </button>
                <div
                  *ngIf="!loginDisplay"
                  class="flex flex-col ml-20"
                  style="width: 200px; justify-content: center; align-items: center"
                >
                  <h2 style="font-weight: 400 !important">Are you Admin</h2>
                  <mat-slide-toggle
                    class="example-margin allow-user"
                    color="accent"
                    [(ngModel)]="isAzureAdminChecked"
                    [checked]="isAzureAdminChecked"
                  >
                  </mat-slide-toggle>
                </div>
                <button
                  mat-fab
                  [disabled]="!isAzureAdminChecked"
                  extended
                  color="primary"
                  style="display: block"
                  class="ml-10"
                  (click)="login()"
                >
                  Import Staff
                </button>
                <!-- <span class="material-icons-outlined"> info </span> -->
                <mat-icon
                  matTooltip="To import staff you must be Azure AD Admin"
                  matTooltipPosition="above"
                  aria-hidden="false"
                  aria-label="Azure AD info"
                  fontIcon="info"
                  style="margin-left: 10px"
                  color="warn"
                ></mat-icon>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="IMPORT FROM CSV">
          <div class="mt-20" style="gap: 60px">
            <h4 class="mt-20">Upload csv file of Staff Members</h4>
            <div class="flex-c">
              <div class="flex-c mt-20" style="gap: 30px">
                <img src="../../../assets/images/csv upload.svg" />
                <div>
                  <input
                    type="file"
                    #csvReader
                    name="Upload CSV"
                    id="txtFileUpload"
                    (change)="uploadListener($event)"
                    accept=".csv"
                    style="font-size: 20px"
                  />
                  <span class="file-name ml-20">{{ selectedFile?.name }}</span>
                  <mat-icon
                    matTooltip="Please upload csv file with two columns 'name' and 'email'"
                    matTooltipPosition="above"
                    aria-hidden="false"
                    aria-label="Admin"
                    fontIcon="info"
                    style="margin-left: 10px; margin-bottom: -6px"
                    color="warn"
                  ></mat-icon>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Marketing List">
          <div class="flex-c mt-20" style="gap: 60px">
            <div class="email-clients" class="flex flex-col w-100p">
              <h4>Marketing List</h4>
              <div class="flex-c">
                <table>
                  <tr>
                    <th>Name</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                  <tr *ngIf="allMarketingList.length === 0 && !loadingMarketingList">
                    <td colspan="5">
                      <h3 style="text-align: center; margin-top: 16px">No Marketing List</h3>
                    </td>
                  </tr>
                  <tr *ngFor="let list of allMarketingList">
                    <td style="width: 40%">{{list.listName}}</td>
                    <td style="width: 30%">{{list.createdBy}}</td>
                    <td style="width: 20%">{{getDateAndTime(list.createdAt)}}</td>
                    <td style="width: 10%">
                      <button
                        (click)="viewListHandler([], list._id, true)"
                        style="background-color: green"
                        mat-mini-fab
                      >
                        <mat-icon>remove_red_eye</mat-icon>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
<div
  *ngIf="loadingImportedStaff"
  class="flex-c"
  style="height: 100%; width: 100%; position: absolute; top: 0; z-index: 99"
>
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="loadingUsersList" class="flex-c" style="height: 100%; width: 100%; position: absolute; top: 0; z-index: 99">
  <mat-spinner></mat-spinner>
</div>
