import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-unsubscribing',
  templateUrl: './unsubscribing.component.html',
  styleUrls: ['./unsubscribing.component.css'],
})
export class UnsubscribingComponent {
  isCodeSend: boolean = false;
  emailid: string = '';
  data: any;
  verificationcode: string = '';
  selectedType: string = '0';
  constructor(private authService: AuthService, private toastr: ToastrService,private router:Router) {}
  onSend() {
    if (this.emailid) {
      this.authService.sendverificationCode(this.emailid).subscribe(res => {
        this.data =res;

        if (this.data) {
          this.isCodeSend = true;
          this.toastr.success('Verification code sent successfully on your email!');
        } else this.toastr.error('Invalid Email address!');
      });
    }
  }
  onSubmit() {
    if (this.emailid && this.selectedType != '0' && this.verificationcode) {
      const payload = {
        UserId: this.data.userId,
        OrganizationId:this.data.orgid,
        Code: this.verificationcode,
        Email: this.data.email,
        Type: Number(this.selectedType),
      };
      this.authService.unsubscribedEmail(payload).subscribe(res => {
        if (res) {
          this.toastr.success(res);
          this.router.navigate(['/']);
        }
      });
    }else this.toastr.warning("Please fill the required fields")
  }
}
