<div mat-dialog-content>
  <div style="text-align: center">
    <img
      mat-card-sm-image
      style="width: 190px; height: 43px; margin-bottom: 10px"
      src="../../../assets/images/aspective-logo.png"
      alt="Photo of a Shiba Inu"
    />
  </div>
  <p style="text-align: center">Please write to us for Premium Plan, we'll get back to you!!</p>
  <form [formGroup]="premiumPlanForm">
    <mat-form-field style="width: 100%; margin: auto">
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        formControlName="message"
        placeholder="Enter Your message"
      ></textarea>
      <mat-error *ngIf="message.hasError('required')"> Message is <strong>required</strong> </mat-error>
    </mat-form-field>
    <div mat-dialog-actions class="flex-c">
      <button
        extended
        mat-stroked-button
        (click)="onNoClick()"
        style="height: 56px; border-radius: 30px; margin-right: 20px; width: 100px"
      >
        Cancel
      </button>
      <button
        mat-fab
        extended
        color="primary"
        [disabled]="!premiumPlanForm.valid"
        [mat-dialog-close]="{ message: premiumPlanForm.value.message}"
        style="display: block; width: 160px"
      >
        Send Message
      </button>
    </div>
  </form>
</div>
