import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer360 } from '../constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  servierUrl = Customer360.serverUrl;

  constructor(private httpClient: HttpClient) {}
}
