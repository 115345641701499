<div class="flex-sb" style="align-items: flex-start !important; padding: 5%">
    <div class="flex flex-col" style="width: 40%" [ngStyle]="{ order:2 }">
    
   
    
  
      <!-- for register -->
      <div style="flex-grow: 1" >
        <mat-card style="width: 400px; height: auto; box-shadow: none">
          <h1 style="padding-top: 0;text-align: center;">Confirm your account Details</h1>
          <mat-card-content style="padding: 0">
            <form
              [formGroup]="registerForm"
              class="example-form"
              (ngSubmit)="onRegisterFormSubmit(registerForm.value)"
              style="display: flex; flex-direction: column; align-items: center"
            >
              <mat-form-field class="example-full-width">
                <mat-label>Full Name</mat-label>
                <input type="text" matInput formControlName="fullName" placeholder="Enter your full name" />
                <mat-error *ngIf="fullName.hasError('required')"> Full name is <strong>required</strong> </mat-error>
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Company Name</mat-label>
                <input type="text" matInput formControlName="companyName" placeholder="Enter your company name" />
                <mat-error *ngIf="companyName.hasError('required')">
                  Company name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Email</mat-label>
                <input type="email" class="disabled" matInput formControlName="registerEmail" placeholder="Enter your email" />
  
                <mat-error *ngIf="registerEmail.hasError('email') && !registerEmail.hasError('required')">
                  Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="registerEmail.hasError('required')"> Email is <strong>required</strong> </mat-error>
              </mat-form-field>
             
             
              <mat-checkbox
                style="width: 100%"
                class="example-margin mb-20"
                [(ngModel)]="agreementChecked"
                [ngModelOptions]="{ standalone: true }"
                >Agree with our Terms & Policies</mat-checkbox
              >
              <button
                mat-fab
                extended
                [disabled]="!registerForm.valid || !agreementChecked"
                color="primary"
                class="login-btn"
                style="display: block; width: 100%"
              >
                <app-loader *ngIf="isCreateSignUpClicked"></app-loader>
                <span>Create Account</span>
              </button>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!-- <div style="width: 55%; align-self: stretch; padding-top: 80px">
      <img
        style="width: 600px; height: 375px; margin: 0"
        src="../../../assets/images/login-main-img.jpeg"
        alt="Photo of a Shiba Inu"
      />
    </div> -->
  </div>