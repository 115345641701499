<div style="padding: 15px 45px">
  <div class="setup-container flex-sb" style="align-items: flex-start !important">
    <div class="flex-sb">
      <h1>All Surveys</h1>
    </div>
    <div>
      <div class="flex" style="justify-content: end">
        <app-logout-btn></app-logout-btn>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="isSurveysLoaded" style="margin: auto; margin-top: 130px; text-align: center">
      <mat-spinner style="margin: auto"></mat-spinner>
    </div>
    <table *ngIf="!isSurveysLoaded" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- customerName Column -->
      <ng-container matColumnDef="surveyName">
        <th mat-header-cell *matHeaderCellDef>Survey Name</th>
        <td mat-cell *matCellDef="let element">{{ element.surveyName }}</td>
      </ng-container>

      <!-- rating Column -->
      <!-- <ng-container matColumnDef="_id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">
        <p style="width: 50px; text-overflow: ellipsis; overflow: hidden; margin-bottom: 0">
          {{ element._id }}
        </p>
      </td>
    </ng-container> -->

      <!-- message Column -->
      <ng-container matColumnDef="userEmail">
        <th mat-header-cell *matHeaderCellDef>Sender Email</th>
        <td mat-cell *matCellDef="let element">{{ element.userEmail }}</td>
      </ng-container>

      <!-- message Column -->
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>
          <span>Created At</span>
          <mat-icon
            matTooltip="MMDDYY, HHMMSS"
            matTooltipPosition="above"
            aria-hidden="false"
            aria-label="Active"
            fontIcon="info"
            style="margin-left: 10px; margin-bottom: -6px"
            color="warn"
          ></mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">{{ getDateAndTime(element.createdDate) }}</td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox [checked]="isAllActive === true"> </mat-checkbox>
          <span>Active</span>
          <mat-icon
            matTooltip="check/uncheck to make Survey active"
            matTooltipPosition="above"
            aria-hidden="false"
            aria-label="Active"
            fontIcon="info"
            style="margin-left: 10px; margin-bottom: -6px"
            color="warn"
          ></mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (change)="updateSurveyStatus(element, $event)" [checked]="element.status === 1"> </mat-checkbox>
        </td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>
          <span>View Survey</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-raised-button
            color="primary"
            style="background-color: dimgray; padding: 7px"
            (click)="openSingleSurveyDialog('0ms', '0ms', { survey: element.survey, type: 'survey' })"
          >
            View
          </button>
        </td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="editStaff">
        <th mat-header-cell *matHeaderCellDef>
          <span>Edit Staff</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-raised-button
            color="primary"
            style="padding: 7px"
            (click)="this.openStaffImportDialog('0ms', '0ms', element)"
          >
            Edit Staff & Send Survey
          </button>
        </td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef>
          <span>Remove</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-raised-button
            color="warn"
            style="padding: 7px"
            (click)="openStaffRemoveDialog('0ms', '0ms', element._id, 'Are you sure to remove this Survey?')"
          >
            Remove
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
    </table>
    <h3 *ngIf="emptyDataSource" style="text-align: center; margin-top: 20px">No Survey created yet!!</h3>
  </div>
</div>
