<div style="padding: 15px 45px">
  <div class="setup-container flex-sa" style="align-items: flex-start !important">
    <div style="width: 25%">
      <h1 style="margin-top: 10px">Ratings Dashboard</h1>
    </div>
    <div style="width: 45%" class="flex">
      <!-- <button mat-fab extended color="primary" style="display: block; width: 40%; height: 50px !important">
        Show Feedbacks
      </button>
      <button
        mat-fab
        extended
        disabled=""
        color="primary"
        class="ml-15"
        style="display: block; width: 40%; height: 50px !important"
      >
        Show Reviews
      </button> -->
    </div>
    <div style="width: 30%; text-align: right">
      <button mat-fab extended color="primary" class="mr-20" (click)="exportAsXLSX()">Download Survey Summary</button>
      <app-logout-btn></app-logout-btn>
    </div>
  </div>

  <div style="margin-top: 60px">
    <div class="list-options flex-sb">
      <div class="flex flex-col" style="flex-grow: 1">
        <h2 style="font-weight: 400 !important">Filter by Rating</h2>
        <div class="flex" style="margin-left: -10px">
          <mat-checkbox class="example-margin" [(ngModel)]="selectedRatingsType[1]" (click)="ratingTypeFilterHandler()"
            >Negative</mat-checkbox
          >
          <mat-checkbox class="example-margin" [(ngModel)]="selectedRatingsType[2]" (click)="ratingTypeFilterHandler()"
            >Neutral</mat-checkbox
          >
          <mat-checkbox class="example-margin" [(ngModel)]="selectedRatingsType[3]" (click)="ratingTypeFilterHandler()"
            >Positive</mat-checkbox
          >
          <mat-checkbox class="example-margin" [(ngModel)]="selectedRatingsType[4]" (click)="ratingTypeFilterHandler()"
            >Unhappy</mat-checkbox
          >
          <mat-checkbox class="example-margin" [(ngModel)]="selectedRatingsType[5]" (click)="ratingTypeFilterHandler()"
            >Angry</mat-checkbox
          >
        </div>
      </div>

      <div class="flex">
        <div class="flex flex-col ml-20" style="width: 250px">
          <h2 style="font-weight: 400 !important">Filter by Survey</h2>
          <mat-form-field class="flex-row">
            <mat-select [value]="selectedSurvey.surveyName" name="food">
              <mat-option
                *ngFor="let survey of allSurveyList"
                [value]="survey.surveyName"
                (click)="getSingleSurveyRatings(survey)"
              >
                {{ survey.surveyName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex flex-col ml-20" style="width: 250px">
          <h2 style="font-weight: 400 !important">Filter by Staff</h2>
          <mat-form-field class="flex-row">
            <mat-label>Select a Staff Member</mat-label>
            <mat-select [(ngModel)]="selectedTitle" name="food">
              <mat-option value="All Staff" (click)="staffFilterHandler(null, true)"> All Staff </mat-option>
              <mat-option
                *ngFor="let staff of staffMembers"
                [value]="staff.value"
                (click)="staffFilterHandler(staff, false)"
              >
                {{ staff.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex flex-col ml-20" style="width: 200px">
          <h2 style="font-weight: 400 !important">Filter by Time Range</h2>
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date" #dateRangeStart />
              <input
                matEndDate
                formControlName="end"
                placeholder="End date"
                #dateRangeEnd
                (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isSurveysDataLoaded" style="margin: auto; margin-top: 130px; text-align: center">
    <mat-spinner style="margin: auto"></mat-spinner>
  </div>
  <div *ngIf="!isSurveysDataLoaded" style="position: relative">
    <div class="flex-sb" style="margin-top: 50px">
      <h1 *ngIf="headCount === 0" style="position: absolute; left: 26%">No Data to display</h1>
      <div style="display: block; width: 70%; background-color: #e5e5e5; border-radius: 10px">
        <canvas
          style="height: 500px; width: 700px; padding: 20px; display: block"
          baseChart
          class="chart"
          [data]="barChartData"
          [options]="barChartOptions"
          [type]="barChartType"
          (chartHover)="chartHovered($event)"
          (chartClick)="chartClicked($event)"
        >
        </canvas>
      </div>
      <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 25%">
        <div style="height: 133px; width: 200px; position: relative; margin-left: -156px">
          <div class="vertical" style="position: absolute">
            <p style="margin: 0">Negative</p>
            <progress class="col1" style="height: 40px" [value]="negCount" [max]="headCount"></progress>
          </div>
          <div class="vertical" style="position: absolute; left: 60px">
            <p style="margin: 0">Neutral</p>
            <progress class="col2" style="height: 40px" [value]="avgCount" [max]="headCount"></progress>
          </div>
          <div class="vertical" style="position: absolute; left: 120px">
            <p style="margin: 0">Positive</p>
            <progress class="col3" style="height: 40px" [value]="posCount" [max]="headCount"></progress>
          </div>
          <div class="vertical" style="position: absolute; left: 180px">
            <p style="margin: 0">Unhappy</p>
            <progress class="col4" style="height: 40px" [value]="unhCount" [max]="headCount"></progress>
          </div>
          <div class="vertical" style="position: absolute; left: 240px">
            <p style="margin: 0">Angry</p>
            <progress class="col5" style="height: 40px" [value]="angCount" [max]="headCount"></progress>
          </div>
        </div>
        <div>
          <canvas
            style="height: 270px; width: 270px; margin-top: 20px"
            baseChart
            class="chart"
            [data]="doughnutChartData"
            [type]="doughnutChartType"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
