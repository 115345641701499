import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer360 } from '../constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentAnalysisService {
  servierUrl = Customer360.serverUrl;

  constructor(private httpClient: HttpClient) {}

  // to send the document for analysis
  sendDocumentForAnalysis(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/DocumentUpload/AddDocument`, data);
  }

  // to get the list of previous documents analysed
  getDocumentResultList(orgId: string): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/DocumentUpload/GetDocUploadByOrgId?orgId=${orgId}`);
  }
}
