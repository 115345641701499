<style>
  ::ng-deep.payment-success > div {
    background-color: rgb(10, 115, 33) !important;
  }

  ::ng-deep.payment-fail > div {
    background-color: red !important;
  }
</style>
<div mat-dialog-content style="width: 500px; height: 550px">
  <div style="text-align: center">
    <img
      mat-card-sm-image
      style="width: 190px; height: 43px; margin-bottom: 10px"
      src="../../../assets/images/aspective-logo.png"
      alt="Photo of a Shiba Inu"
    />
  </div>
  <p style="text-align: center">
    Enter Details for Payment: &nbsp;<span style="color: green; font-weight: bold; font-size: 20px"
      >${{data.amount}}</span
    >
  </p>
  <form [formGroup]="paymentForm" ngxStripeCardGroup [elementsOptions]="elementsOptions">
    <mat-form-field style="width: 100%; margin: auto">
      <input type="text" matInput formControlName="address" placeholder="Enter address" />
      <mat-error *ngIf="address.hasError('required')"> Address is <strong>required</strong> </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%; margin: auto">
      <input type="email" matInput formControlName="zipCode" placeholder="Enter zip code" />
      <mat-error *ngIf="zipCode.hasError('required')"> Zip code is <strong>required</strong> </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%; margin: auto">
      <input type="email" matInput formControlName="city" placeholder="Enter city name" />
      <mat-error *ngIf="city.hasError('required')"> City name is <strong>required</strong> </mat-error>
    </mat-form-field>
    <img src="../../../assets/images/stripe.png" style="width: 200px; margin: auto; display: block" />
    <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
      <ngx-stripe-payment [clientSecret]="clientSecret"> </ngx-stripe-payment>
    </ng-container>
    <div mat-dialog-actions class="flex-c">
      <button
        extended
        mat-stroked-button
        (click)="onNoClick()"
        style="height: 56px; border-radius: 30px; margin-right: 20px; width: 100px"
      >
        Cancel
      </button>
      <button
        mat-fab
        extended
        color="primary"
        (click)="pay()"
        [disabled]="!paymentForm.valid || paymentInProcess"
        style="display: block; width: 165px"
      >
        Make Payment
      </button>
    </div>
  </form>
  <div
    *ngIf="paymentInProcess"
    style="top: 238px; position: absolute; left: 0; right: 0; margin-left: auto; margin-right: auto"
  >
    <mat-spinner style="margin: auto"></mat-spinner>
  </div>
</div>
