<div style="padding: 15px 45px">
  <div class="setup-container flex-sb" style="align-items: flex-start !important">
    <div class="flex-sb">
      <h1>Customer Feedback</h1>
      <mat-form-field class="ml-20 naveen">
        <input type="text" matInput placeholder="Search" (input)="onSearchChange($event)" />
        <mat-icon matPrefix class="my-icon">search</mat-icon>
      </mat-form-field>
    </div>
    <div>
      <div class="download-btn">
        <button mat-fab extended color="primary" class="mr-20" (click)="exportAsXLSX(surveyName)">
          Download Feedback
        </button>
        <app-logout-btn></app-logout-btn>
      </div>
    </div>
  </div>
  <div style="margin-top: 50px">
    <div class="list-options flex">
      <div class="flex flex-col" style="width: 300px">
        <div class="flex flex-col" style="width: 300px">
          <h2 style="font-weight: 400 !important">Filter by Survey</h2>
          <mat-form-field class="flex-row">
            <mat-select #surveyName [value]="selectedSurvey.surveyName" name="food">
              <mat-option
                *ngFor="let survey of allSurveyList"
                [value]="survey.surveyName"
                (click)="getSingleSurveyFeedback(survey)"
              >
                {{ survey.surveyName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-col ml-20" style="width: 200px">
        <h2 style="font-weight: 400 !important">Filter by Staff</h2>
        <mat-form-field class="flex-row">
          <mat-label>Show All</mat-label>
          <mat-select [(ngModel)]="selectedStaf" name="food">
            <mat-option value="All" (click)="allStaffRatingHandler()"> All Staff </mat-option>
            <mat-option *ngFor="let staff of staffMembers" [value]="staff.value" (click)="staffFilterHandler(staff)">
              {{ staff.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex flex-col ml-20" style="width: 200px">
        <h2 style="font-weight: 400 !important">Filter by Rating</h2>
        <mat-form-field class="flex-row">
          <mat-label>Show All</mat-label>
          <mat-select [(ngModel)]="selectedRate" name="food">
            <mat-option value="0" (click)="allRatingHandler()"> All Ratings </mat-option>
            <mat-option *ngFor="let opt of ratings" [value]="opt.value" (click)="ratingFilterHandler(opt)">
              {{ opt.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex flex-col ml-20" style="width: 300px">
        <h2 style="font-weight: 400 !important">Filter by Time Range</h2>
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date" #dateRangeStart />
            <input
              matEndDate
              formControlName="end"
              placeholder="End date"
              #dateRangeEnd
              (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="results"></div>
  </div>
  <div>
    <div *ngIf="!isFeedbackLoaded" style="margin: auto; margin-top: 130px; text-align: center">
      <mat-spinner style="margin: auto"></mat-spinner>
    </div>
    <table *ngIf="isFeedbackLoaded" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- customerName Column -->
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Customer Name</th>
        <td mat-cell *matCellDef="let element">{{ element.fullName }}</td>
      </ng-container>

      <!-- rating Column -->
      <ng-container matColumnDef="rate">
        <th mat-header-cell *matHeaderCellDef>Rating</th>
        <td mat-cell *matCellDef="let element">{{ getRatingType(element.rate) }}</td>
      </ng-container>

      <!-- message Column -->
      <ng-container matColumnDef="feedback">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td
          style="cursor: pointer; position: relative"
          mat-cell
          *matCellDef="let element"
          (click)="openSingleSurveyDialog('0ms', '0ms', { text: element.feedback, type: 'text' })"
        >
          <span
            *ngIf="element?.maxMLType?.length > 0"
            style="position: absolute; top: 3px; border-radius: 11px; padding: 0 5px"
            [ngStyle]="{ 'background-color': element.color }"
            >{{ element.maxMLType }}</span
          >
          <span> {{ element.feedback }}</span>
        </td>
      </ng-container>
     <!-- rating Column -->
     <ng-container matColumnDef="rating">
      <th mat-header-cell *matHeaderCellDef>Rate</th>
      <td mat-cell *matCellDef="let element">{{ element.rate }}</td>
    </ng-container>
      <!-- id Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.email }}</p>
        </td>
      </ng-container>

      <!-- date Column -->
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">{{ this.formatDate(element.createdDate) }}</td>
      </ng-container>

      <!-- staffMember Column -->
      <ng-container matColumnDef="staffMember">
        <th mat-header-cell *matHeaderCellDef>Staff Member</th>
        <td mat-cell *matCellDef="let element">{{ element.staffMember }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
    </table>
    <h3 *ngIf="isFeedbackLoaded && originDataSource.length === 0" style="text-align: center; margin-top: 20px">
      No feedback received yet!!
    </h3>
  </div>
</div>
