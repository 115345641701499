<div mat-dialog-content style="padding: 20px">
  <h4 style="text-align: center">If you wish to permanently delete your account, you can do so by sending an email to our Customer Support Team at <a href="mailto:support@aspective.cloud">support@aspective.cloud</a> </h4>
<p>We take data privacy and security seriously, and we will ensure that your account is fully deleted within 48 working hours of receiving your request. Please note that once your account is deleted, all data associated with your account will be permanently removed, including any data stored on our platform. We recommend downloading any data or information that you wish to keep before requesting account deletion. If you have any questions or concerns about deleting your account, our customer support team is always available to assist you.</p>
</div>
<div mat-dialog-actions class="flex-c" style="padding: 20px">
  <button
    extended
    mat-stroked-button
    (click)="onNoClick()"
    style="height: 56px; border-radius: 30px; margin-right: 20px; width: 100px"
  >
    Cancel
  </button>
  <button
    mat-fab
    extended
    color="primary"
    style="align-self: flex-start"
    [mat-dialog-close]="{ userId: data.userId }"
    cdkFocusInitial
    style="height: 56px; border-radius: 30px; margin-right: 20px; width: 100px"
  >
    {{data.btnTxt}}
  </button>
</div>
