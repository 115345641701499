<div style="padding: 15px 45px">
  <div class="setup-container flex-sb" style="align-items: flex-start !important">
    <div class="flex-sb">
      <h1>Staff Members</h1>
    </div>
    <div>
      <div class="flex" style="justify-content: end">
        <app-logout-btn></app-logout-btn>
      </div>
    </div>
  </div>
  <div class="add-agent-tab">
    <div>
      <mat-card class="shadow-none">
        <mat-card-content style="padding: 0">
          <mat-tab-group mat-align-tabs="center" class="agent-tab">
            <mat-tab label="IMPORT FROM EMAIL CLIENTS">
              <div class="flex-c mt-20" style="gap: 60px">
                <div class="email-clients" class="flex flex-col w-100p">
                  <h4>Import Staff Members from Email</h4>
                  <div class="flex-c">
                    <!-- <mat-radio-group class="agent-import-btn">
                      <mat-radio-button value="1"
                        ><img
                          src="../../../assets/images/google-suit.png"
                          style="width: 93%; height: 70px; margin-top: 8px"
                      /></mat-radio-button> -->
                    <!-- <mat-radio-button value="2" style="margin-left: 20px"> -->
                    <div style="padding: 10px; border: 1px solid gray; border-radius: 10px">
                      <img
                        src="../../../assets/images/ms-365.png"
                        style="width: 200px; height: 40px; margin-top: 8px"
                      />
                    </div>
                    <!-- </mat-radio-button> -->
                    <!-- </mat-radio-group> -->
                    <button mat-raised-button class="ml-10" color="accent" *ngIf="loginDisplay" (click)="logout()">
                      Logout
                    </button>
                    <div
                      *ngIf="!loginDisplay"
                      class="flex flex-col ml-20"
                      style="width: 200px; justify-content: center; align-items: center"
                    >
                      <h2 style="font-weight: 400 !important">Are you Admin</h2>
                      <mat-slide-toggle
                        class="example-margin allow-user"
                        color="accent"
                        [(ngModel)]="isAzureAdminChecked"
                        [checked]="isAzureAdminChecked"
                      >
                      </mat-slide-toggle>
                    </div>
                    <button
                      mat-fab
                      [disabled]="!isAzureAdminChecked"
                      extended
                      color="primary"
                      style="display: block"
                      class="ml-10"
                      (click)="login()"
                    >
                      Import Staff
                    </button>
                    <!-- <span class="material-icons-outlined"> info </span> -->
                    <mat-icon
                      matTooltip="To import staff you must be Azure AD Admin"
                      matTooltipPosition="above"
                      aria-hidden="false"
                      aria-label="Azure AD info"
                      fontIcon="info"
                      style="margin-left: 10px"
                      color="warn"
                    ></mat-icon>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="IMPORT FROM CSV">
              <h4 class="mt-20">Upload csv file of Staff Members</h4>
              <div class="flex-c">
                <div class="flex-c mt-20" style="gap: 30px">
                  <img src="../../../assets/images/csv upload.svg" />
                  <div>
                    <input
                      type="file"
                      #csvReader
                      name="Upload CSV"
                      id="txtFileUpload"
                      (change)="uploadListener($event)"
                      accept=".csv"
                      style="font-size: 20px"
                    />
                    <span class="file-name ml-20">{{ selectedFile?.name }}</span>
                    <mat-icon
                      matTooltip="Please upload csv file with two columns 'name' and 'email'"
                      matTooltipPosition="above"
                      aria-hidden="false"
                      aria-label="Admin"
                      fontIcon="info"
                      style="margin-left: 10px; margin-bottom: -6px"
                      color="warn"
                    ></mat-icon>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="mt-40">
      <div class="flex-sb">
        <h3>Add Staff Members</h3>
        <div class="flex">
          <button mat-fab extended color="primary" style="display: block" (click)="openAddStaffMemberDialog()">
            Add Staff
          </button>
          <button
            *ngIf="this.authSer.isSuperAdmin()"
            mat-fab
            extended
            color="primary"
            style="display: block"
            class="ml-20"
            (click)="openAssignAdminRoleModal('0ms', '0ms', dataSource)"
          >
            Assign Admin Role
          </button>
        </div>
      </div>
      <div>
        <div *ngIf="!isStaffLoaded" style="margin: auto; margin-top: 130px; text-align: center">
          <mat-spinner style="margin: auto"></mat-spinner>
        </div>
        <table *ngIf="isStaffLoaded" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- customerName Column -->
          <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef>Full Name</th>
            <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
          </ng-container>

          <!-- rating Column -->
          <ng-container matColumnDef="_id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element">{{ element._id }}</td>
          </ng-container>

          <!-- message Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
          </ng-container>

          <!-- Checkbox Column ****** Might use this code for disable/block the user from login ******-->
          <!-- <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="allAdminCheckHandler($event, 'status')" [checked]="isAllActive === true">
              </mat-checkbox>
              <span>Active</span>
              <mat-icon
                matTooltip="Staff selected to receive the survey email"
                matTooltipPosition="above"
                aria-hidden="false"
                aria-label="Active"
                fontIcon="info"
                style="margin-left: 10px; margin-bottom: -6px"
                color="warn"
              ></mat-icon>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox (change)="adminCheckHandler($event, element, 'status')" [checked]="element.status === 1">
              </mat-checkbox>
            </td>
          </ng-container> -->

          <!-- Checkbox Column -->
          <ng-container matColumnDef="admin">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox disabled (change)="allAdminCheckHandler($event, 'role')" [checked]="isAllAdmin === true">
              </mat-checkbox>
              <span>Admin</span>
              <mat-icon
                matTooltip="These users will be Acting as Admin"
                matTooltipPosition="above"
                aria-hidden="false"
                aria-label="Admin"
                fontIcon="info"
                style="margin-left: 10px; margin-bottom: -6px"
                color="warn"
              ></mat-icon>
            </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <mat-checkbox
                  disabled
                  (change)="adminCheckHandler($event, element, 'role')"
                  [checked]="element.role === 1"
                >
                </mat-checkbox>
              </div>
            </td>
          </ng-container>

          <!-- Checkbox Column -->
          <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef>
              <span>Options</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                *ngIf="element._id !== this.authSer.decodeToken()._id"
                style="text-decoration: underline; color: blue; cursor: pointer"
                (click)="openStaffRemoveDialog('0ms', '0ms', element._id)"
                >Remove</span
              >
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="loadingImportedStaff"
  class="flex-c"
  style="height: 100%; width: 100%; position: absolute; top: 0; z-index: 9"
>
  <mat-spinner style="top: -200px"></mat-spinner>
</div>
