import { Component, Inject, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';

import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { getCurrentDateTime } from 'src/app/utility';
import { ToastrService } from 'ngx-toastr';
import { Constant } from 'src/app/Constant';
import { RemoveStaffMember } from '../staff-member/staff-member.component';
import { Router } from '@angular/router';

// import * as html2pdf from 'html2pdf.js';
declare var html2pdf: any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent {
  selectedPlan!: any;
  planToUpdate: any = {};
  initialSelectedPlan: any = {};
  promoCodeApplyDetails: any = {};
  promocode: string = '';
  allPromocodes: any[] = [];
  premiumPlan = {
    _id: 4,
    planName: 'Premium ',
    surveys: 'N/A',
    staff: 'N/A',
    admin: 'N/A',
    monthlyResponse: 'N/A',
    price: 'N/A',
  };
  plans!: any[];
  companyName: string = '';
  companyEmail: string = '';
  companyId: string = '';
  paymentHandler: any = null;
  userTokenData: any;
  upgradePlanSelected: boolean = false;
  isPromoValid: boolean = true;
  subscriptionData: any[] = [];
  logoFileToUpload!: File;
  companyDetails: any;
  emailProvider: any;
  constructor(
    public dialog: MatDialog,
    public settingsService: SettingsService,
    public auth: AuthService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.userTokenData = this.auth.decodeToken();
    this.settingsService.getOrgDetails(this.userTokenData.organizationId).subscribe(
      res => {
        this.companyName = res.name;
        this.companyEmail = res.email;
        this.companyId = res._id;
        this.companyDetails = res;
      },
      err => {
        console.log('something went wrong!!');
      }
    );

    this.promoCodeApplyDetails = {
      initialPrice: this.selectedPlan?.price,
    };

    this.settingsService.getAllPromoCode().subscribe(
      res => {
        this.allPromocodes = res;
      },
      err => {
        console.log('something went wrong!!');
      }
    );

    this.settingsService.getAllPlans().subscribe(res => {
      this.plans = res;
      this.plans.push(this.premiumPlan);
      this.selectedPlan = this.plans[0];
      this.planToUpdate = JSON.parse(JSON.stringify(this.plans[0]));
      this.initialSelectedPlan = { ...this.plans[0] };
    });
  }

  getAllInvoicesData(orgId: string) {
    this.settingsService.getAllInvoices(orgId).subscribe(
      res => {
        this.subscriptionData = res;
      },
      err => console.log('something went wrong!!')
    );
  }

  ngOnInit() {
    this.getAllInvoicesData(this.userTokenData.organizationId);
    this.getMailSetting()
  }

  openEmailSetupFormHandler(val: number) {
    let dialogR = this.dialog.open(EmailSetupForm, {
      height: '600px',
      width: '700px',
      data: { provider: val },
    });
    dialogR.afterClosed().subscribe(res=>{
      this.getMailSetting()
     })
  }
getMailSetting(){
  this.settingsService.getEmailSettingByEmailId(this.userTokenData.email).subscribe((res: any) => {
    this.emailProvider=res[0]?.mailSetting.provider+"";
})
}
  selectPlanHandler(plan: any) {
    this.selectedPlan = JSON.parse(JSON.stringify(plan));
    this.planToUpdate = JSON.parse(JSON.stringify(plan));
    this.promoCodeApplyDetails = {
      initialPrice: this.selectedPlan.price,
      selectedPlanId: plan._id,
      planName: plan.planName,
    };
  }

  openEditCompanyDetailsDialog(enterAnimationDuration: string, exitAnimationDuration: string, details: any) {
    let dialogRef = this.dialog.open(EditCompanyDetails, {
      width: '500px',
      height: '390px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { ...details },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const compData = {
          _id: this.userTokenData.organizationId,
          name: data.companyName,
          phoneNumber: '',
          email: data.companyEmail,
          address: '',
          status: 1,
          createdDate: new Date(),
          createdBy: '',
          lastModifiedBy: '',
          lastModifiedDate: new Date(),
        };
        this.companyName = data.companyName;
        this.companyEmail = data.companyEmail;

        this.settingsService.updateCompanyInfo(this.userTokenData.organizationId, compData).subscribe(
          res => {
            console.log('organization details updated!!');
          },
          err => {
            console.log('something went wrong: ', err);
          }
        );
      }
    });
  }

  //Open Edit Company URL Dialog
  openEditCompanyUrlDialog(enterAnimationDuration: string, exitAnimationDuration: string, details: any) {
    let dialogRef = this.dialog.open(EditCompanySocialUrl, {
      enterAnimationDuration,
      exitAnimationDuration,
      data: { ...details },
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const compData = {
          _id: this.userTokenData.organizationId,
          facebookLink: data.facebookLink,
          twitterLink: data.twitterLink,
          pintrestLink: '',
          instagramLink: data.instagramLink,
          linkdinLink: data.linkdinLink,
        };

        this.companyDetails.facebookLink = data.facebookLink;
        this.companyDetails.twitterLink = data.twitterLink;
        this.companyDetails.instagramLink = data.instagramLink;
        this.companyDetails.linkdinLink = data.linkdinLink;

        this.settingsService.updateCompanySocialLinks(compData).subscribe(
          res => {
            console.log('organization Social Links updated!! ');
          },
          err => {
            console.log('something went wrong: ', err);
          }
        );
      }
    });
  }

  // Open deactivate account Dialog
  openDeactivateAccountModal() {
    let dialogRef = this.dialog.open(RemoveStaffMember, {
      width: '400px',
      height: '180px',
      data: { title: 'Deactivate Your Account', btnTxt: 'Deactivate Account' },
    });
    dialogRef.afterClosed().subscribe(res => {});
  }

  // Open Reset Password Dialog
  openResetPasswordDialog() {
    let dialogRef = this.dialog.open(ResetPassword, {
      width: '400px',
      height: '270px',
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('data: ', data);
        const PayData = {
          _id: this.userTokenData.userId,
          password: data.newPassword,
        };
        this.settingsService.updatePassword(PayData).subscribe(RES => {
          this.toastr.success('Password changed successfully', Constant.shared.sucess);
        });
      }
    });
  }

  openInvoiceDownloadModal(enterAnimationDuration: string, exitAnimationDuration: string, details: any) {
    let dialogRef = this.dialog.open(ViewInvoiceDetails, {
      width: '700px',
      height: '710',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { ...details },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
      }
    });
  }

  isUpgradeHandler() {
    this.selectedPlan._id !== 1 && this.selectedPlan._id !== 4
      ? (this.upgradePlanSelected = true)
      : (this.upgradePlanSelected = false);

    if (this.selectedPlan._id === 4) {
      this.openPremiumPlanModal(this.selectedPlan);
    }
  }

  ApplyPromoCode() {
    const activePromoCode: any = this.allPromocodes.filter(promo => promo.couponName === this.promocode)[0];

    if (activePromoCode) {
      this.promoCodeApplyDetails.isPromoApplied = true;
      this.promoCodeApplyDetails.appliedPromo = activePromoCode;
      this.promoCodeApplyDetails.discountedPrice =
        this.promoCodeApplyDetails.initialPrice - activePromoCode.discountAmount;
      this.planToUpdate.price = this.promoCodeApplyDetails.discountedPrice;
      this.isPromoValid = true;
    } else {
      this.isPromoValid = false;
      this.promoCodeApplyDetails.appliedPromo = null;
      this.promoCodeApplyDetails.discountedPrice = this.promoCodeApplyDetails.initialPrice;
      this.planToUpdate.price = this.promoCodeApplyDetails.initialPrice;
    }
  }

  onViewDetailClick(subData: any) {
    this.openInvoiceDownloadModal('0ms', '0ms', subData);
  }

  onFileSelected(files: any): void {
    this.logoFileToUpload = files[0];
  }

  onSubmit(): void {
    const fd = new FormData();
    fd.append('imageFile', this.logoFileToUpload, this.logoFileToUpload.name);
    this.settingsService.uploadLogo(fd, this.userTokenData.organizationId).subscribe(
      res => {
        this.settingsService.orgDetails = res;
        console.log('res: ', res);
      },
      err => {
        console.log(err);
      }
    );
  }

  // stripe payment code
  makePayment() {
    this.openPaymentDialog();
  }

  openPaymentDialog(): void {
    const dialogRef = this.dialog.open(PaymentModal, {
      data: { amount: this.planToUpdate.price, promoDetails: this.promoCodeApplyDetails },
    });

    // dialogRef.afterClosed().subscribe(data => {
    //   if (data) {
    //     console.log('payment modal refresh: ', data);
    //     // setTimeout(() => {
    //     this.ngOnInit();
    //     // }, 7000);
    //   }
    // });
  }

  openPremiumPlanModal(details: any) {
    let dialogRef = this.dialog.open(PremiumPlanModal, {
      width: '500px',
      height: '320px',
      data: { ...details },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
      }
    });
  }

  getDateAndTime(time: string) {
    return getCurrentDateTime(time);
  }

  // to open the dialog when click on remove project button
  openAccountDeactivateDialog(enterAnimationDuration: string, exitAnimationDuration: string, userId: any): void {
    let dialogRef = this.dialog.open(RemoveStaffMember, {
      width: '400',
      height: '600',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { userId, title: 'Are you sure to deactivate your account?', btnTxt: 'Yes' },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        console.log('res: ', res);
        this.settingsService.deactivateAccount(res.userId.orgId).subscribe(res => {
          this.toastr.error('Account deactivated', Constant.shared.error);
          this.router.navigate(['/login']);
        });
      }
    });
  }
}

// Reset Password Dialog
@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.html',
})
export class ResetPassword {
  resetPassword = new FormGroup({
    newPassword: new FormControl('', [Validators.required]),
  });

  get newPassword(): any {
    return this.resetPassword.get('newPassword');
  }

  constructor(public dialogRef: MatDialogRef<ResetPassword>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

//Edit Company Social URL
@Component({
  selector: 'edit-company-social-url',
  templateUrl: 'edit-company-social-url.html',
})
export default class EditCompanySocialUrl {
  editCompanyUrl = new FormGroup({
    facebookLink: new FormControl(this.data.facebookLink, [Validators.required]),
    twitterLink: new FormControl(this.data.twitterLink, [Validators.required]),
    instagramLink: new FormControl(this.data.instagramLink, [Validators.required]),
    linkdinLink: new FormControl(this.data.linkdinLink, [Validators.required]),
  });

  get facebookLink(): any {
    return this.editCompanyUrl.get('facebookLink');
  }
  get twitterLink(): any {
    return this.editCompanyUrl.get('twitterLink');
  }

  get instagramLink(): any {
    return this.editCompanyUrl.get('instagramLink');
  }

  get linkdinLink(): any {
    return this.editCompanyUrl.get('linkdinLink');
  }

  constructor(
    public dialogRef: MatDialogRef<EditCompanySocialUrl>,
    public settingsService: SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
}

//Edit Company Details
@Component({
  selector: 'edit-company-details',
  templateUrl: 'edit-company-details.html',
})
export class EditCompanyDetails {
  companyDetails: any = null;
  editCompanyDetails = new FormGroup({
    companyName: new FormControl(this.data.companyName, [Validators.required]),
    companyEmail: new FormControl(this.data.companyEmail, [Validators.required, Validators.email]),
  });

  get companyName(): any {
    return this.editCompanyDetails.get('companyName');
  }

  get companyEmail(): any {
    return this.editCompanyDetails.get('companyEmail');
  }

  constructor(
    public dialogRef: MatDialogRef<EditCompanyDetails>,
    public settingsService: SettingsService,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'Premium-plan-contact',
  templateUrl: 'Premium-plan-contact.html',
})
export class PremiumPlanModal {
  premiumPlanForm = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });

  get message(): any {
    return this.premiumPlanForm.get('message');
  }

  constructor(public dialogRef: MatDialogRef<PremiumPlanModal>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'view-invoice-details',
  templateUrl: 'view-invoice-details.html',
})
export class ViewInvoiceDetails {
  invoiceDetails: any = null;
  userTokenData!: any;

  constructor(
    public dialogRef: MatDialogRef<ViewInvoiceDetails>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService
  ) {
    this.userTokenData = this.auth.decodeToken();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  download() {
    var element = document.getElementById('invoiceTable');
    var opt = {
      margin: [0, -0.1, 0, 0.1],
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();

    // Old monolithic-style usage:
    html2pdf(element, opt);
  }

  getDateAndTime(time: string) {
    return getCurrentDateTime(time);
  }
}

@Component({
  selector: 'payment-modal',
  templateUrl: 'payment-modal.html',
})
export class PaymentModal {
  stripeCardValid: boolean = false;
  paymentInProcess = false;
  userTokenData: any;
  @ViewChild(StripePaymentElementComponent) card!: StripePaymentElementComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  paymentForm = new FormGroup({
    address: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
  });

  get address(): any {
    return this.paymentForm.get('address');
  }

  get zipCode(): any {
    return this.paymentForm.get('zipCode');
  }

  get city(): any {
    return this.paymentForm.get('city');
  }

  constructor(
    public dialogRef: MatDialogRef<PaymentModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stripeService: StripeService,
    private auth: AuthService,
    public settingsService: SettingsService,
    private toastr: ToastrService
  ) {
    this.userTokenData = this.auth.decodeToken();
    this.settingsService.createPaymentIntent(data.amount * 100).subscribe((pi: any) => {
      this.elementsOptions.clientSecret = pi.clientSecret;
    });
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  pay() {
    if (this.paymentForm.valid) {
      this.paymentInProcess = true;
      this.stripeService
        .confirmPayment({
          elements: this.card.elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                name: this.userTokenData.displayName,
                email: this.userTokenData.email,
                address: {
                  line1: this.paymentForm!.get('address')!.value || '',
                  postal_code: this.paymentForm!.get('zipCode')!.value || '',
                  city: this.paymentForm!.get('city')!.value || '',
                },
              },
            },
          },
          redirect: 'if_required',
        })
        .subscribe(result => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.toastr.success(Constant.shared.errorMsg, Constant.shared.error);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              var date = new Date(); // Now
              date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
              const paymentInfoData = {
                _id: '',
                userId: this.userTokenData.userId,
                organizationId: this.userTokenData.organizationId,
                couponId: this.data?.promoDetails?.appliedPromo?._id || '0',
                transctionId: result.paymentIntent.id,
                couponName: this.data?.promoDetails?.appliedPromo?.couponName || '',
                couponAmount: this.data?.promoDetails?.appliedPromo?.discountAmount,
                address: this.paymentForm!.get('address')!.value || '',
                city: this.paymentForm!.get('city')!.value || '',
                zip: this.paymentForm!.get('zipCode')!.value || '',
                planId: `${this.data?.promoDetails?.selectedPlanId}`,
                planName: `${this.data?.promoDetails?.planName}`,
                billedAmount: this.data?.promoDetails?.discountedPrice || this.data?.promoDetails?.initialPrice,
                actualAmount: this.data?.promoDetails?.initialPrice,
                startDate: new Date(),
                endDate: date,
                status: 1,
                lastPaymentDate: '2023-10-09T15:31:32.947Z',
                nextPaymentDate: '2023-11-09T15:31:32.947Z',
                paymentMethod: 'card',
                autoRenew: true,
                createdDate: '2023-10-09T15:31:32.947Z',
              };
              this.settingsService.savePaymentInfo(paymentInfoData).subscribe(
                res => {
                  this.toastr.success(Constant.shared.paymentSuccess, Constant.shared.sucess);
                },
                err => {
                  this.toastr.success(Constant.shared.errorMsg, Constant.shared.error);
                }
              );
              // Show a success message to your customer
              this.toastr.success(Constant.shared.paymentSuccess, Constant.shared.sucess);
              this.onNoClick();
            }
          }
        });
    }
  }

  onChange(event: any) {
    if (event.type === 'change') {
      this.stripeCardValid = event.complete;
    }
  }
}

@Component({
  selector: 'email-setup-form',
  templateUrl: 'email-setup-form.html',
  styleUrls: ['./settings.component.css'],
})
export class EmailSetupForm {
  userTokenData: any;
  @Input() provider: number = 0;
  getMailSettingData: any;
  emailForm: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<EmailSetupForm>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService,
    private settingsService: SettingsService
  ) {
    this.userTokenData = auth.decodeToken();
    this.provider = data.provider;
    this.emailForm = new FormGroup({
      type: new FormControl('', [Validators.required]),
      api_Key: new FormControl(),
      partner_Secret_Key: new FormControl('', []),
      domain: new FormControl('', []),
      smtp_Host: new FormControl('', []),
      smtp_Port: new FormControl('', []),
      smtp_UserName: new FormControl('', []),
      smtp_Password: new FormControl('', []),
      email_From: new FormControl('', []),
      email_From_Name: new FormControl('', []),
    });
  }

  ngOnInit() {
    this.settingsService.getEmailSettingByEmailId(this.userTokenData.email).subscribe((res: any) => {
      this.getMailSettingData = res[0];
      if(this.getMailSettingData)
      this.emailForm.patchValue({
        type: this.getMailSettingData?.mailSetting?.type == 1 ? '1' : '0',
        api_Key: this.getMailSettingData?.mailSetting?.api_Key,
        partner_Secret_Key: this.getMailSettingData?.mailSetting?.partner_Secret_Key,
        domain: this.getMailSettingData?.mailSetting?.domain,
        smtp_Host: this.getMailSettingData?.mailSetting?.smtp_Host,
        smtp_Port: this.getMailSettingData?.mailSetting?.smtp_Port,
        smtp_UserName: this.getMailSettingData?.mailSetting?.smtp_UserName,
        smtp_Password: this.getMailSettingData?.mailSetting?.smtp_Password,
        email_From: this.getMailSettingData?.mailSetting?.email_From,
        email_From_Name: this.getMailSettingData?.mailSetting?.email_From_Name,
      });
    });
  }
  onChange(value: any) {
    if (value == 1) {
      this.emailForm.controls['api_Key'].addValidators(Validators.required);
      this.emailForm.controls['partner_Secret_Key'].addValidators(Validators.required);
      this.emailForm.controls['domain'].addValidators(Validators.required);

      this.emailForm.controls['smtp_Host'].clearValidators();
      this.emailForm.controls['smtp_Port'].clearValidators();
      this.emailForm.controls['smtp_UserName'].clearValidators();
      this.emailForm.controls['smtp_Password'].clearValidators();
      this.emailForm.controls['email_From'].clearValidators();
    } else {
      this.emailForm.controls['api_Key'].clearValidators();
      this.emailForm.controls['partner_Secret_Key'].clearValidators();
      this.emailForm.controls['domain'].clearValidators();

      this.emailForm.controls['smtp_Host'].addValidators(Validators.required);
      this.emailForm.controls['smtp_Port'].addValidators(Validators.required);
      this.emailForm.controls['smtp_UserName'].addValidators(Validators.required);
      this.emailForm.controls['smtp_Password'].addValidators(Validators.required);
      this.emailForm.controls['email_From'].addValidators(Validators.required);
    }
    this.emailForm.updateValueAndValidity();
  }
  onSubmit(val: any) {
    if (!this.emailForm.valid) {
      this.emailForm.markAllAsTouched();
      this.emailForm.markAsDirty();
      return;
    }
    const payload = {
      userId: this.userTokenData.userId,
      userEmail: this.userTokenData.email,
      userName: this.userTokenData.displayName,
      mailSetting: {
        api_Key: val.api_Key,
        partner_Secret_Key: val.partner_Secret_Key,
        smtp_Host: val.smtp_Host,
        smtp_Port: val.smtp_Port,
        smtp_UserName: val.smtp_UserName,
        smtp_Password: val.smtp_Password,
        email_From: val.email_From,
        email_From_Name: val.email_From_Name,
        domain: val.domain,
        type: Number(val.type),
        provider: this.provider,
      },
      _id: this.getMailSettingData?._id ?? '',
    };
    this.settingsService.addUpdateEmailSetting(payload).subscribe((res: any) => {
      this.dialogRef.close()
    });
  }
  onCancel() { this.dialogRef.close()}
}
