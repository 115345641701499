import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { StaffMembersService } from 'src/app/services/staff-members.service';
import { ImportStaffMember, RemoveStaffMember } from '../staff-member/staff-member.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SurveyService } from 'src/app/services/survey.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { getCurrentDateTime } from '../../utility';
import { ToastrService } from 'ngx-toastr';
import { Constant } from 'src/app/Constant';

@Component({
  selector: 'app-all-surveys',
  templateUrl: './all-surveys.component.html',
  styleUrls: ['./all-surveys.component.css'],
})
export class AllSurveysComponent {
  isSurveysLoaded = false;
  // Mat table functions
  displayedColumns: string[] = [
    'surveyName',
    // '_id',
    'userEmail',
    'createdDate',
    'status',
    'view',
    'editStaff',
    'remove',
  ];
  dataSource = new MatTableDataSource<any>([]);
  organizationId: string = '';
  isAllActive = false;
  isAllAdmin = false;
  selection = new SelectionModel<any>(true, []);
  organizationStaff: any[] = [];
  userTokenData: any;
  emptyDataSource = false;

  constructor(
    public authSer: AuthService,
    public staffService: StaffMembersService,
    public dialog: MatDialog,
    public surveyService: SurveyService,
    private toastr: ToastrService
  ) {
    this.userTokenData = this.authSer.decodeToken();
    this.organizationId = this.userTokenData.organizationId;
    this.isSurveysLoaded = true;
    this.surveyService.getAllOrganizationSurvey(this.organizationId).subscribe((res: any) => {
      this.dataSource = res;
      this.isSurveysLoaded = false;
      if (res.length === 0) {
        this.emptyDataSource = true;
      }
    });
    this.staffService.getAllUsers(this.organizationId).subscribe(res => {
      this.organizationStaff = res;
    });
  }

  ngOnInit() {
    if (!this.authSer.isSuperAdmin()) {
      this.displayedColumns = [
        'surveyName',
        // '_id',
        'userEmail',
        'createdDate',
        'status',
        'view',
        'editStaff',
      ];
    }
  }

  // to open the dialog when react pack selected
  openStaffRemoveDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    userId: any[],
    title: string
  ): void {
    let dialogRef = this.dialog.open(RemoveStaffMember, {
      width: '400px',
      height: '180px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { userId, title, btnTxt: 'Remove' },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        // const data: any = [];
        // for (let user of Object.entries(this.dataSource)) {
        //   if (user[1]._id !== res.userId) {
        //     data.push(user[1]);
        //   }
        // }
        // this.dataSource = data;
        // this.staffService.removeStaffMember(res.userId).subscribe(() => {
        //   console.log('user removed!!');
        // });
      }
    });
  }

  // to open the dialog when react pack selected
  openStaffImportDialog(enterAnimationDuration: string, exitAnimationDuration: string, surveyData: any): void {
    let surveyStaffMembers = surveyData.selectedUserList;
    surveyStaffMembers = surveyStaffMembers.map((ele: any) => ele.email);
    const allUsersForStaff: any[] = [];

    for (let user of this.organizationStaff) {
      const data: any = {};
      if (surveyStaffMembers.indexOf(user.email) !== -1) {
        data.selected = true;
      } else {
        data.selected = false;
      }
      data.isMatchingWithSearch = true;
      allUsersForStaff.push({ ...user, ...data });
    }
    let dialogRef = this.dialog.open(ImportStaffMember, {
      width: '870px',
      height: '500px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        users: allUsersForStaff,
        title: 'Check/Uncheck Staff for Survey',
        btnTxt: 'Send Survey to all Staff',
        btnTxt2: 'Send Survey to New Staff',
        isSendToAllEmailBtn: true,
        search: 'Search Staff',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        let selectedUsers = res.data.filter((user: any) => user.selected);
        selectedUsers = selectedUsers.map((user: any) => {
          return { userId: user._id, email: user.email };
        });
        const apiData = {
          _id: surveyData._id,
          userId: this.userTokenData.userId,
          organizationId: this.userTokenData.organizationId,
          surveySetupId: surveyData._id,
          surveyName: '',
          survey: surveyData.survey,
          selectedUserList: selectedUsers,
          createdDate: new Date(),
          expireDate: new Date(),
          emailSend: res.sendEmailTo,
          status: 1,
          lastModifiedDate: new Date(),
        };
        surveyData.selectedUserList = selectedUsers;
        this.surveyService.UpdateSurveyStaffListById(apiData).subscribe((res: any) => {
          this.toastr.success(Constant.shared.SurveyEmailSent, Constant.shared.sucess);
        });
      }
    });
  }

  updateSurveyStatus(survey: any, event: MatCheckboxChange) {
    const status = event.checked == false ? 2 : 1;
    survey.status = status;
    const data = {
      _id: survey._id,
      status,
    };
    this.surveyService.UpdateSurveyStatusById(data).subscribe(res => {});
  }

  // to open the dialog when react pack selected
  openSingleSurveyDialog(enterAnimationDuration: string, exitAnimationDuration: string, data: any): void {
    let dialogRef = this.dialog.open(ViewSingleSurveyModel, {
      width: '450px',
      height: '240px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: data,
    });
  }

  getDateAndTime(time: string) {
    return getCurrentDateTime(time);
  }
}

@Component({
  styleUrls: ['./all-surveys.component.css'],
  selector: 'view-single-survey',
  templateUrl: 'view-survey.html',
})
export class ViewSingleSurveyModel {
  constructor(
    public dialogRef: MatDialogRef<ViewSingleSurveyModel>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer
  ) {
    console.log('data: ', data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
