import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyjsCreatorService } from 'src/app/services/surveyjs-creator.service';
import { Model } from 'survey-core';

@Component({
  selector: 'app-display-survey-to-fill',
  templateUrl: './display-survey-to-fill.component.html',
  styleUrls: ['./display-survey-to-fill.component.css'],
})
export class DisplaySurveyToFillComponent {
  model!: Model;
  routeParams!: any;
  surveyLoadingToFill = false;
  companyName: string = '';

  constructor(private activatedRoute: ActivatedRoute, public surveyCreatorService: SurveyjsCreatorService) {}
  ngOnInit() {
    this.surveyLoadingToFill = true;
    this.activatedRoute.params.subscribe(params => {
      this.routeParams = params;
      this.surveyCreatorService.getSingleSurvey(this.routeParams.surveyId).subscribe(res => {
        this.companyName = res.companyName;
        this.surveyLoadingToFill = false;
        const survey = new Model(res.json);
        // saveSurveyResult;
        survey.onComplete.add((sender, options) => {
          const data = {
            _id: '',
            json: JSON.stringify(sender.data),
            userId: '',
            createdAt: new Date(),
            ...this.routeParams,
          };
          this.surveyCreatorService.saveSurveyResult(data).subscribe(res => {
            console.log('result saved!!: ', res);
          });
          // console.log(JSON.stringify(sender.data, null, 3));
        });
        this.model = survey;
      });
    });
  }
}
