import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FeedbackService } from 'src/app/services/feedback.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SurveyService } from 'src/app/services/survey.service';
import { OverviewService } from 'src/app/services/overview.service';
import { StaffMembersService } from 'src/app/services/staff-members.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewSingleSurveyModel } from '../all-surveys/all-surveys.component';
import { CustomerFeedbackService } from 'src/app/services/customer-feedback.service';

export interface PeriodicElement {
  customerName: string;
  rating: string;
  message: string;
  id: number;
  date: string;
  staffMember: string;
}

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.css'],
})
export class CustomerFeedbackComponent {
  selectedStaf!: string;
  selectedRate!: string;
  isFeedbackLoaded: boolean = false;
  userTokenData: any;
  selectedStaff: any = 'All';
  selectedRating: any = '0';
  selectedSurvey: any = {
    surveyName: '',
  };
  allSurveyList: any[] = [];

  ratings: any[] = [
    { value: '1', viewValue: 'Negative' },
    { value: '2', viewValue: 'Neutral' },
    { value: '3', viewValue: 'Positive' },
    { value: '4', viewValue: 'Unhappy' },
    { value: '5', viewValue: 'Angry' },
  ];

  getRatingType(val: number) {
    switch (val) {
      case 1:
        return 'Negative';
      case 2:
        return 'Neutral';
      case 3:
        return 'Positive';
      case 4:
        return 'Unhappy';
      case 5:
        return 'Angry';
      default:
        return 'No Rating';
    }
  }

  staffMembers: any[] = [];
  uniqueStaffMember: any = new Map();

  // Mat table functions
  displayedColumns: string[] = ['fullName', 'rate', 'feedback','rating', 'email', 'createdDate', 'staffMember'];
  dataSource = new MatTableDataSource<any>([]);
  originDataSource: any[] = [];
  selection = new SelectionModel<PeriodicElement>(true, []);

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  allOrgUsers: any[] = [];

  constructor(
    public feedbackSerive: FeedbackService,
    private auth: AuthService,
    private surveyService: SurveyService,
    private overViewService: OverviewService,
    private staffService: StaffMembersService,
    public dialog: MatDialog,
    private _customerFeedback: CustomerFeedbackService
  ) {
    this.userTokenData = this.auth.decodeToken();
    this.isFeedbackLoaded = false;
    this.surveyService.getAllOrganizationSurvey(this.userTokenData.organizationId).subscribe((allSurvey: any) => {
      this.allSurveyList = allSurvey;
      if (this.allSurveyList.length) {
        this.selectedSurvey = this.allSurveyList[0];
        this.overViewService.getSingleSurveyRating(this.selectedSurvey._id).subscribe(allRating => {
          this.originDataSource = allRating.filter((feedback: any) => {
            if (feedback.fullName && feedback.feedback.length) {
              const mlData = this.getColor(feedback);
              feedback.color = mlData.color;
              feedback.maxMLType = mlData.maxMLType;
              return feedback;
            }
          });
          this.dataSource = JSON.parse(JSON.stringify(this.originDataSource));
          this.isFeedbackLoaded = true;
        });

        this.staffService.getAllUsers(this.userTokenData.organizationId).subscribe(res => {
          this.allOrgUsers = res;
          const surveyStaffList = allSurvey[0].selectedUserList.map((user: any) => user.email);
          this.staffMembers = res.filter((user: any) => surveyStaffList.indexOf(user.email) !== -1);
          this.staffMembers = this.staffMembers.map((user: any) => {
            return { value: user.displayName, viewValue: user.displayName };
          });
        });
      } else {
        this.isFeedbackLoaded = true;
      }
    });
  }

  exportAsXLSX(surveyName: any): void {
    const temDataSource: any = this.dataSource;
    const downloadReport = temDataSource.map((feedback: any) => {
      return {
        CustomerName: feedback.fullName,
        Email: feedback.email,
        Date: feedback.createdDate,
        Message: feedback.maxMLType,
        StaffMember: feedback.staffMember,
        SurveyName: surveyName.value,
        Rating: this.getRatingType(feedback.rate),
      };
    });

    this._customerFeedback.exportAsExcelFile(downloadReport, surveyName.value);
  }

  getColor(obj: any) {
    const max = Math.max(parseFloat(obj?.negative), parseFloat(obj?.neutral), parseFloat(obj?.positive));

    if (max === obj?.negative) {
      return { color: '#808080', maxMLType: 'Negative' };
    }

    if (max === obj?.neutral) {
      return { color: '#cc5500', maxMLType: 'Neutral' };
    }

    if (max === obj?.positive) {
      return { color: '#259e0d', maxMLType: 'Positive' };
    }

    return { color: '#000', maxMLType: '' };
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.dataSource = JSON.parse(JSON.stringify(this.originDataSource)).filter((feedback: any) => {
      if (
        new Date(feedback.createdDate) >= new Date(dateRangeStart.value) &&
        new Date(feedback.createdDate) <= new Date(dateRangeEnd.value)
      ) {
        return feedback;
      }
    });
  }

  ratingFilterHandler(ratingObj: any) {
    this.selectedRating = ratingObj.value;

    if (this.selectedStaff !== 'All') {
      this.dataSource = JSON.parse(JSON.stringify(this.originDataSource)).filter((feedback: any) => {
        if (feedback.rate == ratingObj.value && feedback.staffMember === this.selectedStaff) {
          return feedback;
        }
      });
    } else {
      this.dataSource = JSON.parse(JSON.stringify(this.originDataSource)).filter((feedback: any) => {
        if (feedback.rate == ratingObj.value) {
          return feedback;
        }
      });
    }
  }

  allRatingHandler() {
    this.selectedRating = '0';
    if (this.selectedStaff !== 'All') {
      this.dataSource = JSON.parse(JSON.stringify(this.originDataSource)).filter((feedback: any) => {
        if (feedback.staffMember === this.selectedStaff) {
          return feedback;
        }
      });
    } else {
      this.dataSource = JSON.parse(JSON.stringify(this.originDataSource));
    }
  }

  staffFilterHandler(staffMemObj: any) {
    this.selectedStaff = staffMemObj.value;
    if (this.selectedRating !== '0') {
      this.dataSource = JSON.parse(JSON.stringify(this.originDataSource)).filter((feedback: any) => {
        if (feedback.staffMember == staffMemObj.value && feedback.rate == this.selectedRating) {
          return feedback;
        }
      });
    } else {
      this.dataSource = JSON.parse(JSON.stringify(this.originDataSource)).filter((feedback: any) => {
        if (feedback.staffMember == staffMemObj.value) {
          return feedback;
        }
      });
    }
  }

  allStaffRatingHandler() {
    this.selectedStaff = 'All';
    if (this.selectedRating !== '0') {
      this.dataSource = JSON.parse(JSON.stringify(this.originDataSource)).filter((feedback: any) => {
        if (feedback.rate == this.selectedRating) {
          return feedback;
        }
      });
    } else {
      this.dataSource = JSON.parse(JSON.stringify(this.originDataSource));
    }
  }

  onSearchChange(event: Event) {
    const searchValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource = JSON.parse(JSON.stringify(this.originDataSource)).filter((feedback: any) => {
      if (feedback.fullName.toLowerCase().indexOf(searchValue) > -1) {
        return feedback;
      }
    });
  }

  formatDate(date: string) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return year + '/' + month + '/' + day;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.customerName + 1}`;
  }

  getSingleSurveyFeedback(survey: any) {
    this.isFeedbackLoaded = false;
    this.overViewService.getSingleSurveyRating(survey._id).subscribe(allRating => {
      this.originDataSource = allRating.filter((feedback: any) => {
        if (feedback.fullName && feedback.feedback.length) {
          const mlData = this.getColor(feedback);
          feedback.color = mlData.color;
          feedback.maxMLType = mlData.maxMLType;
          return feedback;
        }
      });
      this.dataSource = JSON.parse(JSON.stringify(this.originDataSource));
      this.isFeedbackLoaded = true;
    });

    const surveyStaffList = survey.selectedUserList.map((user: any) => user.email);
    this.staffMembers = this.allOrgUsers.filter((user: any) => surveyStaffList.indexOf(user.email) !== -1);
    this.staffMembers = this.staffMembers.map((user: any) => {
      return { value: user.displayName, viewValue: user.displayName };
    });
  }

  // to open the dialog when react pack selected
  openSingleSurveyDialog(enterAnimationDuration: string, exitAnimationDuration: string, data: any): void {
    let dialogRef = this.dialog.open(ViewSingleSurveyModel, {
      width: '450px',
      height: '240px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: data,
    });
  }
}
