export const environment = {
    production: false,
    msalConfig: {
      auth: {
        clientId: '213ed8c6-85fd-4c4c-8b1e-8d1012ea4e19',
        authority: 'https://login.microsoftonline.com/1a6a2017-d012-4e68-9888-460115161d9b',  
        redirectUri: 'https://nps.aspectivecloud.com/',
      },
    },
    apiConfig: {
      scopes: ['api://213ed8c6-85fd-4c4c-8b1e-8d1012ea4e19/default','openid','profile ','offline_access','user.read',],
      uri: 'https://aspective-cloud-api-linux.azurewebsites.net/',
    },
    encryptionKey: '4512631236589784',
    GA_TRACKING_ID: 'G-SHRBNGZBPX',

}