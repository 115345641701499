import { Component, Input, EventEmitter, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics';
// import 'survey-analytics/survey.analytics.css';
import { MatrixPlotly, SelectBasePlotly } from 'survey-analytics';
import { ActivatedRoute } from '@angular/router';
import { SurveyjsCreatorService } from 'src/app/services/surveyjs-creator.service';

MatrixPlotly.types = ['stackedbar', 'bar', 'pie', 'doughnut'];
SelectBasePlotly.types = ['doughnut', 'bar', 'pie', 'scatter'];

export const json = {
  completedHtml:
    "<p style='font-size:24px;'>Thank you for completing the survey! (please wait for analytics to load ...)<p>",
  pages: [
    {
      name: 'page_info',
      elements: [
        {
          type: 'matrix',
          name: 'Quality',
          title: 'Please indicate if you agree or disagree with the following statements',
          columns: [
            {
              value: 1,
              text: 'Strongly Disagree',
            },
            {
              value: 2,
              text: 'Disagree',
            },
            {
              value: 3,
              text: 'Neutral',
            },
            {
              value: 4,
              text: 'Agree',
            },
            {
              value: 5,
              text: 'Strongly Agree',
            },
          ],
          rows: [
            {
              value: 'affordable',
              text: 'Product is affordable',
            },
            {
              value: 'does what it claims',
              text: 'Product does what it claims',
            },
            {
              value: 'better then others',
              text: 'Product is better than other products on the market',
            },
            {
              value: 'easy to use',
              text: 'Product is easy to use',
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'organization_type',
          title: 'Which of the following best describes you or your organization?',
          choices: [
            {
              value: 'ISV',
              text: 'ISV (building commercial/shrink wrapped software)',
            },
            {
              value: 'Consulting',
              text: 'Software consulting firm (provide development services to other organizations)',
            },
            {
              value: 'Custom',
              text: 'Custom software development (as a freelancer/contractor)',
            },
            {
              value: 'In-house',
              text: 'In-house software development',
            },
            {
              value: 'Hobbyist',
              text: 'Hobbyist (develop apps for personal use)',
            },
          ],
          colCount: 2,
        },
      ],
    },
  ],
};

export const dataFromServer = [
  {
    Quality: {
      affordable: '3',
      'does what it claims': '4',
      'better then others': '5',
      'easy to use': '1',
    },
    organization_type: 'In-house',
  },
  {
    Quality: {
      affordable: '3',
      'does what it claims': '4',
      'better then others': '2',
      'easy to use': '3',
    },
    organization_type: 'Hobbyist',
  },
  {
    Quality: {
      affordable: '3',
      'does what it claims': '4',
      'better then others': '5',
      'easy to use': '1',
    },
    organization_type: 'In-house',
  },
];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class ResultDashboardComponent {
  constructor(private activatedRoute: ActivatedRoute, public surveyCreatorService: SurveyjsCreatorService) {}
  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;
  scrollToTop(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
  ngOnInit() {
    this.scrollToTop;
    // this.activatedRoute.params.subscribe((params: any) => {
    //   this.surveyCreatorService.getSingleSurvey(params.surveyId).subscribe((res: any) => {
    //     const survey = new Model(JSON.parse(res.json));

    //     this.surveyCreatorService.getSurveyResponses(params.surveyId).subscribe(res1 => {
    //       let dataFromServer = res1.map((r: any) => r.json);
    //       dataFromServer = dataFromServer.map((r: any) => JSON.parse(r));
    //       // Imitate an asynchronous call to get data from a server
    //       setTimeout(() => {
    //         const vizPanel = new VisualizationPanel(survey.getAllQuestions(), dataFromServer);

    //         //@ts-ignore
    //         document.getElementById('loadingIndicator').style.display = 'none';
    //         vizPanel.render('surveyDashboardContainer');
    //       });
    //     });
    //   });
    // });

    this.activatedRoute.params.subscribe((params: any) => {
      this.surveyCreatorService.getSingleSurvey(params.surveyId).subscribe((res: any) => {
        const survey = new Model(JSON.parse(res.json));

        this.surveyCreatorService.getSurveyResponses(params.surveyId).subscribe(res1 => {
          let dataFromServer = res1.map((r: any) => r.json);
          dataFromServer = dataFromServer.map((r: any) => JSON.parse(r));
          // Imitate an asynchronous call to get data from a server
          const vizPanel = new VisualizationPanel(survey.getAllQuestions(), dataFromServer);
          //@ts-ignore
          document.getElementById('loadingIndicator').style.display = 'none';
          vizPanel.render('surveyDashboardContainer');
        });
      });
    });
  }
}
