import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthenticationGuard } from './authentication.guard';
import { StaffCopySurveyComponent } from './pages/staff-copy-survey/staff-copy-survey.component';
import { DisplaySurveyToFillComponent } from './pages/survey-js-creator/display-survey-to-fill/display-survey-to-fill.component';
import { ResultDashboardComponent } from './pages/survey-js-creator/dashboard/dashboard.component';
import { CustomerFillSurveyComponent } from './pages/ratings-survey-setup/customer-fill-survey/customer-fill-survey.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UnsubscribingComponent } from './pages/unsubscribing/unsubscribing.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'staffCopySurvey/:orgId/:surveyId/:userId', component: StaffCopySurveyComponent },
  { path: 'customerFillSurvey/:orgId/:surveyId/:userId', component: DisplaySurveyToFillComponent },
  { path: 'customerFillSurvey/:orgId/:surveyId', component: DisplaySurveyToFillComponent },
  { path: 'onlineSurveyDashboard/:orgId/:surveyId', component: ResultDashboardComponent },
  { path: 'fill-survey/:orgId/:surveyId/:userId/:imgName/:rate', component: CustomerFillSurveyComponent },
  { path: 'unsubscribe', component: UnsubscribingComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard,MsalGuard] },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
