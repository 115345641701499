import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-staff-copy-survey',
  templateUrl: './staff-copy-survey.component.html',
  styleUrls: ['./staff-copy-survey.component.css'],
})
export class StaffCopySurveyComponent {
  surveyHTML = '';
  copiedHTML = '';
  isError: boolean = false;
  params: any = {};
  isSurveyLoaded: boolean = false;

  constructor(
    public sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private surveyService: SurveyService
  ) {
    this.route.params.subscribe(params => {
      this.params = params;
    });
  }

  copyMessage() {
    var copyImgBtn: any = document.querySelector('.copy-image');
    copyImgBtn.addEventListener('click', (event: Event) => {
      event.preventDefault();
      var imageElem: any = document.querySelector('.image-class');
      var range = document.createRange();
      range.selectNode(imageElem);
      const a: any = window.getSelection();
      a.addRange(range);

      try {
        // Now that we've selected the anchor text, execute the copy command
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copy image command was ' + msg);
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      // removeRange(range) when it is supported
      const b: any = window.getSelection();
      b.removeAllRanges();
    });
  }

  ngOnInit() {
    this.surveyService.getCurrentStaffSurveyToCopyCode(this.params).subscribe(
      result => {
        console.log('result: ', result);
        if (result == null) {
          this.isError = true;
          this.isSurveyLoaded = true;
        } else {
          this.isSurveyLoaded = true;
          this.surveyHTML = result;
          this.copiedHTML = result;
          this.copyMessage();
        }
      },
      error => {
        this.isError = true;
        this.isSurveyLoaded = true;
      }
    );
  }

  // copy code snackbar
  openCopyTheCodeSnackbar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['blue-snackbar'],
    });
  }
}
