import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer360 } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class StaffMembersService {
  servierUrl = Customer360.serverUrl;
  private httpClient2: HttpClient;

  constructor(private httpClient: HttpClient, private handler: HttpBackend) {
    this.httpClient2 = new HttpClient(handler);
  }

  getAllUsers(orgid: any): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/User/GetUserByOrganizationId?organizationid=${orgid}`);
  }

  // get list of company users
  getMS365UsersList(accessToken: string): Observable<any> {
    const headerDict = {
      ConsistencyLevel: 'eventual',
      Authorization: `Bearer ${accessToken}`,
    };

    const requestOptions: Object = {
      headers: new Headers(headerDict),
    };
    return this.httpClient2.get(`https://graph.microsoft.com/v1.0/users`, requestOptions);
  }

  addStaffMembers(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/User/PostMultipleUser`, data);
  }

  EditStaffMembers(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/User/PostUser`, data);
  }

  removeStaffMember(userId: any): Observable<any> {
    return this.httpClient.delete(`${this.servierUrl}/User/DeleteUser?id=${userId}`);
  }
}
