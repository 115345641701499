import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer360 } from '../constants';
//@ts-ignore
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class SurveyjsCreatorService {
  // servierUrl = 'http://localhost:4000/';
  servierUrl = Customer360.serverUrl;
  constructor(private httpClient: HttpClient) {}

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // to create a new project
  createProject(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/Project/PostProject`, data);
  }
  // to edit a single project details(Name)
  editProjectName(data: any, projectId: string): Observable<any> {
    return this.httpClient.patch(`${this.servierUrl}/Project/UpdateProject?id=${projectId}`, data);
  }
  // to delete a project
  deleteProject(projectId: string): Observable<any> {
    return this.httpClient.delete(`${this.servierUrl}/Project/DeleteProject?id=${projectId}`);
  }
  // to get all the project list
  getAllProjects(orgId: string): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/Project/GetProjectByOrgId?orgId=${orgId}`);
  }

  // Get  Survey By Org Id 
  getSurveyResultByOrgId(orgId: string): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/SurveyResult/GetSurveyResultByOrgId?orgId=${orgId}`);
  }

  //  Get Survey By Survey Id 
  getSurveyBySurveyId(orgId: string): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/SurveyResult/GetSurveyResultBySurveyId?surveyId=${orgId}`);
  }

  // create a new survey with name and isHRSurvey option
  createSurvey(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/OnlineSurveys/PostOnlineSurveys`, data);
  }
  // update survey Name and isHRSurvey option
  editSurveyName(data: any, surveyId: string): Observable<any> {
    return this.httpClient.patch(`${this.servierUrl}/OnlineSurveys/PatchUpdateNameOnlineSurveys?id=${surveyId}`, data);
  }
  //  to get the single survey
  getSingleSurvey(surveyId: any): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/OnlineSurveys/GetOnlineSurveysById?id=${surveyId}`);
  }
  // delete Survey
  deleteSurvey(surveyId: string): Observable<any> {
    return this.httpClient.delete(`${this.servierUrl}/OnlineSurveys/DeleteOnlineSurveys?id=${surveyId}`);
  }

  saveSurveyResult(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/SurveyResult/PostSurveyResult`, data);
  }

  getSurveyResponses(surveyId: string): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/SurveyResult/GetSurveyResultBySurveyId?surveyId=${surveyId}`);
  }

  // create a new survey list
  createSurveyList(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/List/PostList`, data);
  }
  // get all list of organization
  getAllListsOfOrg(orgId: string): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/List/GetListByOrgId?orgId=${orgId}`);
  }
  // delete a user list
  deleteUserList(listId: string): Observable<any> {
    return this.httpClient.delete(`${this.servierUrl}/List/DeleteList?id=${listId}`);
  }

  // send survey by existing list
  sendSurveyToExistingList(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/List/PostExistList`, data);
  }

  // send survey to marketing list
  sendSurveyToMarketingList(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/CustomerListContact/PostExistList`, data);
  }

  saveSurveyJson(json: object, _id: string, updatedByUserId: string) {
    const data = {
      _id,
      updatedByUserId,
      updatedAt: new Date(),
      json: JSON.stringify(json),
    };
    return this.httpClient.patch(`${this.servierUrl}/OnlineSurveys/PatchUpdateOnlineSurveys?id=${_id}`, data);
  }

  //share Survey Email
  SendSurveyEmail(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/Email/SendEmail`, data);
  }
}
