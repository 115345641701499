import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { AuthService } from 'src/app/services/auth.service';
import { OverviewService } from 'src/app/services/overview.service';
import { SettingsService } from 'src/app/services/settings.service';
import { StaffMembersService } from 'src/app/services/staff-members.service';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
})
export class OverviewComponent {
  selectedTitle!: string;
  userTokenData: any;
  isSurveysDataLoaded = true;

  downloadReport: any = [];

  foods: any[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];

  public barChartType: ChartType = 'bar';
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  public barChartData: any;
  public doughnutChartLabels = ['Negative', 'Neutral', 'Positive', 'Unhappy', 'Angry'];
  public doughnutChartData: any;
  public doughnutChartType: ChartType = 'doughnut';
  negCount: number = 0;
  avgCount: number = 0;
  posCount: number = 0;
  unhCount: number = 0;
  angCount: number = 0;
  headCount: number = 0;
  uniqueStaffMember: any = new Map();
  staffMembers: any[] = [];
  AllRatingsCurrentData: any = [];
  selectedRatingsType = [false, false, false, false, false, false];
  allOrgUsers: any[] = [];
  originalCurrentRatingsData: any = [];

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  allSurveyList: any[] = [];
  selectedSurvey: any = {
    surveyName: '',
  };

  constructor(
    public overViewService: OverviewService,
    private auth: AuthService,
    private surveyService: SurveyService,
    private staffService: StaffMembersService,
    public settingsService: SettingsService
  ) {
    this.userTokenData = this.auth.decodeToken();
    this.surveyService.getAllOrganizationSurvey(this.userTokenData.organizationId).subscribe((res: any) => {
      this.isSurveysDataLoaded = false;
      const surveyStaffList = res[0]?.selectedUserList.map((user: any) => user.email);
      this.allSurveyList = res;
      if (this.allSurveyList.length) {
        this.selectedSurvey = this.allSurveyList[0];
        this.allStaffRatingHandler(this.selectedSurvey._id);
      }

      this.staffService.getAllUsers(this.userTokenData.organizationId).subscribe(res => {
        this.allOrgUsers = res;
        this.staffMembers = res.filter((user: any) => surveyStaffList?.indexOf(user.email) !== -1);
        this.staffMembers = this.staffMembers.map((user: any) => {
          return { value: user._id, viewValue: user.displayName, email: user.email };
        });
      });
    });
  }

  exportAsXLSX(): void {
    this.overViewService.exportAsExcelFile(this.downloadReport, 'sample');
  }

  ngOnInit() {}

  allStaffRatingHandler(surveyId: string) {
    this.selectedRatingsType.fill(false);
    this.range.reset();
    this.isSurveysDataLoaded = true;
    this.overViewService.getSingleSurveyRating(surveyId).subscribe(allRating => {
      this.originalCurrentRatingsData = allRating;
      this.AllRatingsCurrentData = JSON.parse(JSON.stringify(this.originalCurrentRatingsData));
      this.generateGraph(allRating, false);
      this.isSurveysDataLoaded = false;
    });
  }

  ratingTypeFilterHandler() {
    const sliceAllRatingType = this.selectedRatingsType.slice(1);
    const isRatingTypeSelected = sliceAllRatingType.some(ele => ele == true);
    if (!isRatingTypeSelected) {
      this.generateGraph(this.AllRatingsCurrentData, false);
    } else {
      this.generateGraph(this.AllRatingsCurrentData, true);
    }
  }

  staffFilterHandler(staffMemObj: any, isAll: boolean) {
    this.selectedRatingsType.fill(false);
    this.range.reset();
    if (!isAll) {
      const userId = staffMemObj.value;
      const userRating = this.originalCurrentRatingsData.filter((rating: any) => rating.userId == userId);
      this.AllRatingsCurrentData = userRating;
      this.generateGraph(userRating, false);
    } else {
      this.AllRatingsCurrentData = JSON.parse(JSON.stringify(this.originalCurrentRatingsData));
      this.generateGraph(this.AllRatingsCurrentData, false);
    }
  }

  getSingleSurveyRatings(survey: any) {
    this.selectedSurvey = survey;
    this.allStaffRatingHandler(survey._id);
    this.selectedTitle = '';
    const surveyStaffList = survey.selectedUserList.map((user: any) => user.email);
    this.staffMembers = this.allOrgUsers.filter((user: any) => surveyStaffList.indexOf(user.email) !== -1);
    this.staffMembers = this.staffMembers.map((user: any) => {
      return { value: user._id, viewValue: user.displayName, email: user.email };
    });
  }

  generateGraph(allRating: any, isType?: boolean, startDate?: HTMLInputElement, endDate?: HTMLInputElement) {
    const barData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Negative',
          stack: 'a',
          backgroundColor: '#808080',
        },
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Neutral',
          stack: 'a',
          backgroundColor: '#cc5500',
        },
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Positive',
          stack: 'a',
          backgroundColor: '#259e0d',
        },
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Unhappy',
          stack: 'a',
          backgroundColor: '#002d62',
        },
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Angry',
          stack: 'a',
          backgroundColor: '#c92929',
        },
      ],
    };
    const dataSets = barData.datasets;
    const donutRating = [0, 0, 0, 0, 0];

    for (let rating of allRating) {
      const currentDate = new Date(rating.createdDate);
      const currentMonth = currentDate.getMonth();

      if (isType) {
        if (this.selectedRatingsType[rating.rate]) {
          dataSets[rating.rate - 1].data[currentMonth] += 1;
          donutRating[rating.rate - 1] += 1;
        }
      } else if (startDate && endDate) {
        if (currentDate >= new Date(startDate.value) && currentDate <= new Date(endDate.value)) {
          dataSets[rating.rate - 1].data[currentMonth] += 1;
          donutRating[rating.rate - 1] += 1;
        }
      } else {
        dataSets[rating.rate - 1].data[currentMonth] += 1;
        donutRating[rating.rate - 1] += 1;
      }
    }
    this.barChartData = barData;
    // Doughnut
    this.doughnutChartData = {
      labels: this.doughnutChartLabels,
      datasets: [{ data: [...donutRating], backgroundColor: ['#808080', '#cc5500', '#259e0d', '#002d62', '#c92929'] }],
    };

    this.negCount = donutRating[0];
    this.avgCount = donutRating[1];
    this.posCount = donutRating[2];
    this.unhCount = donutRating[3];
    this.angCount = donutRating[4];
    this.headCount = donutRating.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const surveySenderDetails = this.staffMembers.filter(user => user?.value === this.selectedTitle)[0];
    this.downloadReport = [
      {
        'Survey For': surveySenderDetails?.viewValue || '',
        'Survey For Email': surveySenderDetails?.email || '',
        'Survey Title': this.selectedSurvey.surveyName || '',
        'Total Response': this.headCount,
        Negative: this.negCount,
        Neutral: this.avgCount,
        Positive: this.posCount,
        Unhappy: this.unhCount,
        Angry: this.angCount,
      },
    ];
  }

  // events
  public chartClicked({ event, active }: { event?: ChartEvent; active?: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent; active?: {}[] }): void {
    // console.log(event, active);
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.selectedRatingsType.fill(false);
    this.generateGraph(this.AllRatingsCurrentData, false, dateRangeStart, dateRangeEnd);
  }
}
