<div style="padding: 15px 45px">   
    
      <div class="add-agent-tab">
        <div class="mt-40">
          <h3>Unsubscriber  List</h3>
    
          <div>
            <div *ngIf="!isListLoaded" style="margin: auto; margin-top: 130px; text-align: center">
              <mat-spinner style="margin: auto"></mat-spinner>
            </div>
            <table *ngIf="isListLoaded" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              
    
              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
              </ng-container>
    
              <!-- isCrmContact Column -->
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Block</th>
                <td mat-cell *matCellDef="let element">{{ element.type }}</td>
              </ng-container>
    
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="recordNotFound" style="text-align: center; margin-top: 50px">
      <h1>no record found</h1>
    </div>
    <div
      *ngIf="loadingImportedStaff"
      class="flex-c"
      style="height: 100%; width: 100%; position: absolute; top: 0; z-index: 9"
    >
      <mat-spinner style="top: -200px"></mat-spinner>
    </div>
