<div style="padding: 15px 45px">
  <div class="setup-container flex-sb" style="align-items: flex-start !important">
    <div style="width: 35%">
      <h1 style="margin-bottom: 60px">Rating Survey Setup</h1>
    </div>
    <div style="width: 25%">
      <h1 style="margin-bottom: 40px"></h1>
    </div>
    <div style="width: 30%; text-align: right">
      <app-logout-btn></app-logout-btn>
    </div>
  </div>
  <div class="setup-container flex-sb" style="align-items: flex-start !important">
    <div class="widget-preview" style="width: 50%" #inputTarget>
      <h2>Widget Preview</h2>
      <mat-card class="shadow-none image-class preview-class">
        <h2 style="font-weight: 500 !important">
          {{ currentSelectedHeading.name }}
        </h2>
        <div class="mt-10 w-100p" style="display: flex; justify-content: space-around; align-items: center">
          <table cellpadding="0" [cellSpacing]="0" class="preTable">
            <tr>
              <td
                style="border-radius: 15px 15px 0 0; border-left: 2px solid #fff; height: 50px; padding-top: 10px"
                *ngFor="
                  let icon of reactionPackItems[previewSurveyData.reactionPack][previewSurveyData.iconSetId].icons;
                  let i = index
                "
              >
                <a
                  class="rm-event"
                  [href]="
                    servierUrl +
                    '/Ratings/rate/' +
                    this.userTokenData.userId +
                    '/' +
                    this.userTokenData.organizationId +
                    '/' +
                    this.getImgName(icon.url) +
                    '/' +
                    getReviewType(i)
                  "
                  ><img src="{{ icon.url }}" height="45" width="45"
                /></a>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 12px; border-left: 2px solid #fff; height: 30px; padding-bottom: 5px"
                *ngFor="
                  let icon of reactionPackItems[previewSurveyData.reactionPack][previewSurveyData.iconSetId].icons;
                  let i = index
                "
              >
                <a
                  class="rm-event"
                  style="text-decoration: none; font-weight: 500"
                  [href]="
                    servierUrl +
                    '/Ratings/rate/' +
                    this.userTokenData.userId +
                    '/' +
                    this.userTokenData.organizationId +
                    '/' +
                    this.getImgName(icon.url) +
                    '/' +
                    getReviewType(i)
                  "
                  >{{ icon.text }}</a
                >
              </td>
            </tr>
          </table>
        </div>
      </mat-card>
      <h2 style="margin-top: 45px">Widget Setup</h2>
      <mat-card
        class="shadow-none flex flex-col mb-20"
        style="border: 1px solid #cccccc; padding: 10px; border-radius: 15px; width: 100%"
      >
        <!-- (click)="variantHeadingeHandler(heading)" click event on option of dropdown -->
        <h2 style="font-weight: 400 !important">Survey Name*</h2>
        <div class="flex">
          <mat-form-field style="width: 80%">
            <input
              type="text"
              maxlength="100"
              #surveyName="ngModel"
              [(ngModel)]="previewSurveyData.surveyName"
              matInput
              placeholder="Enter Survey Name"
              required
            />
            <mat-error *ngIf="!surveyName?.valid && (surveyName?.dirty || surveyName?.touched)">
              Survey name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card>
      <mat-card
        class="shadow-none flex flex-col"
        style="border: 1px solid #cccccc; padding: 10px; border-radius: 15px; width: 100%"
      >
        <!-- (click)="variantHeadingeHandler(heading)" click event on option of dropdown -->
        <h2 style="font-weight: 400 !important">Heading Variants</h2>
        <div class="flex">
          <mat-form-field style="width: 390px">
            <!-- <mat-select [(ngModel)]="previewSurveyData.title" name="food"> -->
            <mat-select [(ngModel)]="currentSelectedHeading.name" name="food">
              <mat-option
                *ngFor="let heading of headingVariant"
                [value]="heading.name"
                (click)="variantHeadingeHandler(heading)"
              >
                {{ heading.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            (click)="openSurveyHeadingEditModel('0ms', '0ms', currentSelectedHeading)"
            mat-fab
            extended
            style="background-color: #3f51b5; margin-left: 10px"
          >
            <mat-icon>edit</mat-icon>
            Edit
          </button>
        </div>
      </mat-card>
    </div>
    <div class="select-reaction" style="width: 40%">
      <h2>Select Reaction Pack</h2>
      <mat-card
        *ngIf="selectedPack[0].isSelected"
        (click)="openDialog('0ms', '0ms', 0, reactionPackItems[0])"
        class="shadow-none flex-c flex-col"
        style="border: 1px solid #cccccc; padding: 15px; border-radius: 20px"
      >
        <div class="flex" *ngIf="previewSurveyData.reactionPack !== 0; then default3; else selected_content3"></div>
        <ng-template #default3>
          <div class="flex-sb mt-10 w-100p">
            <div class="preview-image-signature flex-c flex-col" *ngFor="let icon of reactionPackItems[0][0].icons">
              <img src="{{ icon.url }}" height="45" width="45" />
            </div>
          </div>
        </ng-template>
        <ng-template #selected_content3>
          <div class="flex-sb mt-10 w-100p">
            <div
              class="preview-image-signature flex-c flex-col"
              *ngFor="let icon of reactionPackItems[previewSurveyData.reactionPack][previewSurveyData.iconSetId].icons"
            >
              <img src="{{ icon.url }}" height="45" width="45" />
            </div>
          </div>
        </ng-template>
      </mat-card>
      <mat-card
        *ngIf="selectedPack[1].isSelected"
        (click)="openDialog('0ms', '0ms', 1, reactionPackItems[1])"
        class="shadow-none flex-c flex-col"
        style="border: 1px solid #cccccc; padding: 15px; border-radius: 20px"
      >
        <div class="flex" *ngIf="previewSurveyData.reactionPack !== 1; then default4; else selected_content4"></div>
        <ng-template #default4>
          <div class="flex-sb mt-10 w-100p">
            <div class="preview-image-signature flex-c flex-col" *ngFor="let icon of reactionPackItems[1][2].icons">
              <img src="{{ icon.url }}" height="45" width="45" />
            </div>
          </div>
        </ng-template>
        <ng-template #selected_content4>
          <div class="flex-sb mt-10 w-100p">
            <div
              class="preview-image-signature flex-c flex-col"
              *ngFor="let icon of reactionPackItems[previewSurveyData.reactionPack][previewSurveyData.iconSetId].icons"
            >
              <img src="{{ icon.url }}" height="45" width="45" />
            </div>
          </div>
        </ng-template>
      </mat-card>
      <mat-card
        *ngIf="selectedPack[2].isSelected"
        (click)="openDialog('0ms', '0ms', 2, reactionPackItems[2])"
        class="shadow-none flex-c flex-col"
        style="border: 1px solid #cccccc; padding: 15px 5px; border-radius: 20px"
      >
        <div class="flex" *ngIf="previewSurveyData.reactionPack !== 2; then default5; else selected_content5"></div>
        <ng-template #default5>
          <div class="flex-sb mt-10 w-100p">
            <div class="preview-image-signature flex-c flex-col" *ngFor="let icon of reactionPackItems[2][2].icons">
              <img src="{{ icon.url }}" height="45" width="45" />
            </div>
          </div>
        </ng-template>
        <ng-template #selected_content5>
          <div class="flex-sb mt-10 w-100p">
            <div
              class="preview-image-signature flex-c flex-col"
              *ngFor="let icon of reactionPackItems[previewSurveyData.reactionPack][previewSurveyData.iconSetId].icons"
            >
              <img src="{{ icon.url }}" height="45" width="45" />
            </div>
          </div>
        </ng-template>
      </mat-card>
      <h2 style="font-weight: 400 !important; margin-top: 20px">Select Reaction Pack</h2>
      <div class="flex-sb reaction-pack">
        <div class="flex-c" [ngClass]="{ 'packcount-bg': selectedPack[0].isSelected }" (click)="packCountHandler(0)">
          <span>3</span>
        </div>
        <div class="flex-c" [ngClass]="{ 'packcount-bg': selectedPack[1].isSelected }" (click)="packCountHandler(1)">
          <span>4</span>
        </div>
        <div class="flex-c" [ngClass]="{ 'packcount-bg': selectedPack[2].isSelected }" (click)="packCountHandler(2)">
          <span>5</span>
        </div>
      </div>
      <h2 style="margin-top: 45px">Ready for use</h2>
      <mat-card class="shadow-none">
        <mat-card-content class="flex-sb" style="padding: 0">
          <div class="flex flex-col w-100p">
            <div class="mt-20">
              <a
                mat-fab
                extended
                routerLink="."
                class="ready-btn"
                (click)="getAllUserswithModel()"
                [disabled]="previewSurveyData.surveyName.trim().length === 0"
                >Share by mail
              </a>
              <p class="mt-10" style="color: #a3a3a3">Share it by mail with your staff members</p>
              <ul style="padding: 0; color: red">
                <li>Your staff will be able to copy the survey from email</li>
                <li>Your staff will be able to copy the HTML code from Email</li>
              </ul>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- <div class="options" style="width: 30%">
      <h2>Options</h2>
      <section class="example-section">
        <mat-slide-toggle
          class="example-margin allow-user"
          [color]="color"
          [checked]="previewSurveyData.isRecationOff"
          [(ngModel)]="previewSurveyData.isRecationOff"
        >
          Allow user feedback &nbsp; &nbsp; &nbsp; &nbsp; 50%
        </mat-slide-toggle>
        <p style="color: #a3a3a3" class="mt-15">
          By Default, the Survey Form requesting Customer Feedback, will display 100% of the time.
        </p>
      </section>

      <section class="example-section" style="margin-top: 70px">
        <mat-slide-toggle
          class="example-margin allow-user"
          [color]="color"
          [(ngModel)]="previewSurveyData.isReviewOff"
          [checked]="previewSurveyData.isReviewOff"
        >
          Allow user review &nbsp; &nbsp; &nbsp; &nbsp; 50%
        </mat-slide-toggle>
        <p class="mt-15" style="color: #a3a3a3">
          You can however choose to redirect positive Customer Feedback to your review site, increasing the number of
          company Reviews and improvng your average Rating
        </p>
      </section>
     
    </div> -->
  </div>
</div>

<div
  *ngIf="loadingImportedStaff"
  class="flex-c"
  style="height: 100%; width: 100%; position: absolute; top: 0; z-index: 9"
>
  <mat-spinner style="top: -200px"></mat-spinner>
</div>
