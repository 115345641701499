import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, catchError, filter, throwError } from 'rxjs';
import { Constant } from 'src/app/Constant';
import { AuthService } from 'src/app/services/auth.service';
import { UserProfileComponent } from '../user-profile/user-profile.component';

export function passwordMatch(password: string, confirmPassword: string) {
  return function (form: AbstractControl) {
    const passwordValue = form.get(password)?.value;
    const confirmpasswordValue = form.get(confirmPassword)?.value;

    if (passwordValue === confirmpasswordValue) {
      return null;
    }
    return { passwordMismatchError: true };
  };
}
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  isLoginClicked: boolean = false;
  agreementChecked = false;
  subscriptions: Subscription[] = [];
  UserData: any;
  authenticated: any;
  enableForms: any = {
    isLoginForm: true,
    isForgotPasswordForm: false,
    isRegisterForm: false,
  };
  isCreateSignUpClicked = false;
  isResetBtnClicked = false;
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  forgetPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  registerForm = new FormGroup(
    {
      fullName: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      registerEmail: new FormControl('', [Validators.required, Validators.email]),

      registerPassword: new FormControl('', [Validators.required]),

      registerRepeatPassword: new FormControl('', [Validators.required]),
      acceptTerms: new FormControl(false, [Validators.required]),
    },
    [passwordMatch('registerPassword', 'registerRepeatPassword')]
  );

  // Password Match validator
  // private validateAreEqual(fieldControl: FormControl): any {
  //@ts-ignore
  //   return this.registerForm?.get('registerPassword').value === this.registerForm?.get('registerRepeatPassword').value
  //     ? null
  //     : {
  //         NotEqual: true,
  //       };
  // }

  get email(): any {
    return this.loginForm.get('email');
  }

  get password(): any {
    return this.loginForm.get('password');
  }

  get forgotPasswordEmail(): any {
    return this.forgetPasswordForm.get('email');
  }

  get fullName(): any {
    return this.registerForm.get('fullName');
  }

  get companyName(): any {
    return this.registerForm.get('companyName');
  }

  get registerEmail(): any {
    return this.registerForm.get('registerEmail');
  }

  get registerPassword(): any {
    return this.registerForm.get('registerPassword');
  }

  get registerRepeatPassword(): any {
    return this.registerForm.get('registerRepeatPassword');
  }

  get acceptTerms(): any {
    return this.registerForm.get('acceptTerms');
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  onLoginFormSubmit() {
    this.isLoginClicked = true;
    var loginobj = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    this.authService.loginUser(loginobj).subscribe(
      data => {
        if (!data) {
          this.router.navigate(['/']);
        } else {
          this.router.navigate(['dashboard']);
        }
        this.isLoginClicked = false;
      },
      err => (this.isLoginClicked = false)
    );
  }
  // register form submit
  onRegisterFormSubmit(val: any) {
    this.isCreateSignUpClicked = true;
    var registerobj = {
      userName: this.registerForm.value.fullName,
      organizationName: this.registerForm.value.companyName,
      email: this.registerForm.value.registerEmail,
      password: this.registerForm.value.registerPassword,
      address: '',
      phoneNumber: '',
      status: 1,
    };
    this.authService.registerUser(registerobj).subscribe(
      (data: any) => {
        this.isCreateSignUpClicked = false;
        this.toastr.success(Constant.shared.successfullyRefistered, Constant.shared.sucess);
        this.enableFormHandler('isLoginForm');
      },
      err => {
        if (err.status === 409) {
          this.enableFormHandler('isLoginForm');
        }
        this.isCreateSignUpClicked = false;
      }
    );
  }
  // on forget handler
  onforgetFormSubmit() {
    this.isResetBtnClicked = true;
    const email = this.forgetPasswordForm.value.email || '';
    this.authService.forgotPassword(email).subscribe(
      data => {
        console.log('data: ', data);
        this.isResetBtnClicked = false;
        if (data) {
          this.toastr.success('Your Password sent successfully on your email id', Constant.shared.sucess);
          this.enableFormHandler('isLoginForm');
        } else {
          this.toastr.error(Constant.shared.invalidEmail, Constant.shared.error);
        }
      },
      err => {
        this.isResetBtnClicked = false;
        console.log('error >>>>>>>>>>>>>>: ', err);
      }
    );
  }
  ngOnInit() {
    this.subscriptions[this.subscriptions.length + 1] = this.broadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        // this.checkAndSetActiveAccount();
        this.setLoginDisplay();
      });
    this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
      });
    this.authService.islogin$.subscribe(res => {
      if (res) {
        this.login();
      }
    });
  }
  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
    this.authService.isAuthenticated();
  }
  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginPopup();
    }
    this.authService.islogin.next(false);
  }

  logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  }

  setLoginDisplay() {
    this.isLoginClicked = this.msalService.instance.getAllAccounts().length > 0;
    if (!this.isLoginClicked) {
      this.router.navigate(['/']);
    } else {
      this.getUserProfileAndRole();
    }
  }
  enableFormHandler(formType: string): void {
    for (let cForm in this.enableForms) {
      if (cForm === formType) {
        this.enableForms[formType] = true;
      } else {
        this.enableForms[cForm] = false;
      }
    }
  }
  getUserProfileAndRole() {
    this.subscriptions[this.subscriptions.length + 1] = this.authService.getUserByEmail().subscribe((res: any) => {
      if (!res) {
        console.log(res);
        this.authService.openDialog('0ms', '0ms', this.msalService.instance.getAllAccounts());
      } else {
        res.userId = res._id;
        this.authService.profiledata.set('user', res);
        this.router.navigate(['dashboard']);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
