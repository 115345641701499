<div *ngIf="!isError && isSurveyLoaded" class="flex-c flex-col">
  <div
    class="image-class"
    style="pointer-events: none; padding: 25px"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(surveyHTML)"
  ></div>
  <div class="flex-c flex-col">
    <h2 style="margin-top: 45px">Ready for use</h2>
    <mat-card class="shadow-none">
      <mat-card-content class="flex-sb" style="padding: 0">
        <div class="flex-c w-100p">
          <div class="mt-20">
            <button
              mat-fab
              extended
              class="ready-btn copy-image"
              style="background-color: rgb(98, 177, 78)"
              (click)="copyMessage(); this.openCopyTheCodeSnackbar('Copied to Clipboard', '')"
            >
              Copy Survey
            </button>
          </div>
          <div class="mt-20 ml-20">
            <button
              mat-fab
              extended
              class="ready-btn"
              style="background-color: rgb(98, 177, 78)"
              [cdkCopyToClipboard]="copiedHTML"
              (click)="this.openCopyTheCodeSnackbar('Code Copied to Clipboard', '')"
            >
              Copy Survey Code
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div *ngIf="isError">
  <h1 style="text-align: center; margin-top: 50px">No survey exist, please contact the Survey Admin</h1>
</div>
<div *ngIf="!isSurveyLoaded" style="margin: auto; margin-top: 130px; text-align: center">
  <mat-spinner style="margin: auto"></mat-spinner>
</div>
