import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-customer-fill-survey',
  templateUrl: './customer-fill-survey.component.html',
  styleUrls: ['./customer-fill-survey.component.css'],
})
export class CustomerFillSurveyComponent {
  surveyDetails: any;
  isError = false;
  isSurveyLoaded = false;
  sendSurveyFeedback = new FormGroup({
    fullname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    feedbackmsg: new FormControl('', [Validators.required]),
  });
  surveyParams: any;
  isFeedbackSent = false;
  isSendingFeedback = false;

  get fullname(): any {
    return this.sendSurveyFeedback.get('fullname');
  }

  get email(): any {
    return this.sendSurveyFeedback.get('email');
  }

  get feedbackmsg(): any {
    return this.sendSurveyFeedback.get('feedbackmsg');
  }

  constructor(private activatedRoute: ActivatedRoute, public surveyService: SurveyService, private router: Router) {
    this.activatedRoute.params.subscribe(
      params => {
        this.surveyParams = params;
        this.surveyService.sendReviewAndGetSurvey(params).subscribe(res => {
          this.surveyDetails = res;
          this.isSurveyLoaded = true;
        });
      },
      err => {
        this.isError = true;
      }
    );
  }

  submitFeedback() {
    console.log(this.sendSurveyFeedback.value);
    const data: any = { ...this.sendSurveyFeedback.value };
    data.ratingId = this.surveyDetails.ratingId;
    this.isSendingFeedback = true;

    this.surveyService.sendFeedback(data).subscribe(res => {
      this.isFeedbackSent = true;
      this.isSendingFeedback = false;

      setTimeout(() => {
        this.router.navigate(['/']);
      }, 10000);
    });
  }

  goHome() {
    this.router.navigate(['/']);
  }
}
