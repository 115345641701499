<div class="loader" *ngIf="sendSurveyEmailLoader">
  <mat-spinner></mat-spinner>
</div>
<div style="padding: 10px">
  <span class="d-flex" (click)="closeDialog()" style="justify-content: end"><mat-icon>close</mat-icon></span>
  <div style="margin: 25px">
    <h2 class="top-heading">Send Survey Email</h2>

    <form [formGroup]="sendEmailForm">
      <div class="d-flex">
        <p class="pera-text">Email:</p>

        <div class="w-100">
          <input type="text" formControlName="email" class="inp-text" />
          <div class="err-text">
            <p *ngIf="email.hasError('email') && !email.hasError('required')">Please enter a valid email address</p>
            <p *ngIf="(email.dirty || email.touched) && email.invalid && email.errors.required">
              Email is <strong>required</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <p class="pera-text">Subject:</p>

        <div class="w-100">
          <input type="text" formControlName="subject" class="inp-text" required />
          <p class="text">
            <span *ngIf="(subject.dirty || subject.touched) && subject.invalid && subject.errors.required"
              >Subject is <strong>required</strong></span
            >
          </p>
        </div>
      </div>
      <div class="d-flex">
        <p class="pera-text"></p>
        <div class="w-100">
          <textarea name="" id="" formControlName="message" cols="30" rows="13" class="w-100"></textarea>
          <p class="text">
            <span *ngIf="(message.dirty || message.touched) && message.invalid && message.errors.required"
              >Message is <strong>required</strong></span
            >
          </p>
        </div>
      </div>
    </form>

    <div class="d-flex" style="margin-top: 30px">
      <button
        (click)=" sendSurvryEmail()"
        mat-stroked-button
        extended
        [disabled]="!sendEmailForm.valid"
        style="margin-right: 20px"
      >
        Send
      </button>
      <button extended mat-stroked-button (click)="closeDialog()">Cancel</button>
    </div>
  </div>
</div>
