<div>
  <div style="background-color: #fff; height: 75px; display: flex; justify-content: start">
    <img
      mat-card-sm-image
      style="width: auto; height: auto; max-width: 400px; max-height: 100px; margin: 5px 0 5px 40px"
      src="{{ settingsService.orgDetails.logoURL }}"
      alt="Logo"
    />
    <div style="display:flex;align-self: center;width:100%;right: 0;"><h3 style="display: flex; width: 95%;justify-content: flex-end;">Support Request: <a href="mailto: support@aspective.cloud"> support@aspective.cloud</a></h3></div>
  </div>
  <mat-tab-group [@.disabled]="true" class="left-menu">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">Ratings Dashboard</p>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-overview />
        <!-- <app-document-analysis /> -->
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">NPS Dashboard</p>
        </div>
      </ng-template>
      <div>
        <ng-template matTabContent>
          <app-staff-dashboard />
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">Customer Feedback</p>
        </div>
      </ng-template>
      <div>
        <ng-template matTabContent>
          <app-customer-feedback />
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">Contact List</p>
        </div>
      </ng-template>
      <div>
        <ng-template matTabContent>
          <app-marketing-list />
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">Rating Survey Setup</p>
        </div>
      </ng-template>
      <div>
        <app-ratings-survey-setup />
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">Online Surveys</p>
        </div>
      </ng-template>
      <div>
        <ng-template matTabContent>
          <app-survey-js-creator />
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">Staff Members</p>
        </div>
      </ng-template>
      <div>
        <app-staff-member />
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">All Surveys</p>
        </div>
      </ng-template>
      <div>
        <ng-template matTabContent>
          <app-all-surveys />
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">Review Analysis</p>
        </div>
      </ng-template>
      <div>
        <ng-template matTabContent>
          <app-document-analysis />
        </ng-template>
      </div>
    </mat-tab> 
     <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">Unsubscriber List</p>
        </div>
      </ng-template>
      <div>
        <ng-template matTabContent>
          <app-unsubscriber-list />
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text">Email Setup</p>
        </div>
      </ng-template>
      <div>
        <ng-template matTabContent>
          <email-setup-view />
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-c flex-col">
          <p class="menu-text flex" style="display: flex; align-items: center; font-weight: bold">
            <mat-icon iconPositionEnd>settings</mat-icon> &nbsp; My Account
          </p>
        </div>
      </ng-template>
      <div>
        <ng-template matTabContent>
          <app-settings />
        </ng-template>
      </div>
    </mat-tab>
  
  </mat-tab-group>
</div>

<div style="height: 50px; width: 100%; background-color: #3f51b5; margin-top: 100px" class="flex-c">
  <span style="color: #fff">© Copyright 2023 Aspective Limited, United Kingdom, All Rights Reserved</span>
</div>
