<style>
  .invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
  }

  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
  }

  .invoice-box table td {
    padding: 5px;
    vertical-align: top;
  }

  .invoice-box table tr td:nth-child(2) {
    text-align: right;
  }

  .invoice-box table tr.top table td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
  }

  .invoice-box table tr.information table td {
    padding-bottom: 40px;
  }

  .invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }

  .invoice-box table tr.details td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
  }

  .invoice-box table tr.item.last td {
    border-bottom: none;
  }

  .invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
      width: 100%;
      display: block;
      text-align: center;
    }

    .invoice-box table tr.information table td {
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  /** RTL **/
  .invoice-box.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  }

  .invoice-box.rtl table {
    text-align: right;
  }

  .invoice-box.rtl table tr td:nth-child(2) {
    text-align: left;
  }
</style>
<div class="invoice-box" id="invoiceTable">
  <table cellpadding="0" cellspacing="0">
    <tr class="top">
      <td colspan="2">
        <table>
          <tr>
            <td class="title">
              <img
                style="width: 190px; height: 43px; margin-bottom: 30px"
                src="../../../assets/images/aspective-logo.png"
              />
            </td>

            <td>
              Invoice #: {{data._id}}<br />
              Created: {{getDateAndTime(data.createdDate)}}
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr class="information">
      <td colspan="2">
        <table>
          <tr>
            <td>
              {{userTokenData.displayName}},<br />
              {{userTokenData.email}}<br />
              {{userTokenData.OrgName}}<br />
              {{data.address}}<br />
              {{data.city}}, {{data.zip}}<br />
              <br />
            </td>

            <td>
              Customer Advocates<br />
              11 Times Sq, New York, NY 10036,<br />
              United States
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr class="heading">
      <td>Payment Method</td>

      <td></td>
    </tr>

    <tr class="details">
      <td>Credit Card</td>

      <td></td>
    </tr>

    <tr class="heading">
      <td>Product Details</td>

      <td>Price</td>
    </tr>

    <tr class="item">
      <td>Plan - <strong>Premium </strong>(Monthly)</td>

      <td>${{data.actualAmount}}.00</td>
    </tr>

    <tr class="item" *ngIf="data.couponId.length > 1">
      <td>Applied Promo - <strong>{{data.couponName}}</strong></td>

      <td>-${{data.couponAmount}}.00</td>
    </tr>

    <tr>
      <td></td>

      <td><strong>Total: ${{data.actualAmount - data.couponAmount}}.00</strong></td>
    </tr>
  </table>
</div>
<button
  mat-fab
  extended
  color="primary"
  id="downloadBtn"
  style="display: block; background-color: rgb(98, 177, 78)"
  (click)="download()"
  class="ml-10 mb-20"
>
  Download Invoice
</button>
