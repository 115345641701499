<div class="flex-c m-0">
  <div class="mt-20 mb-20 bg-color">
    <div class="text-center">
      <div class="done-icon mt-20 mb-20">
        <mat-icon>check_circle_outline</mat-icon>
      </div>
      <h2>Sorry about that, you're now off the list.</h2>
      <div class="mt-20 mb-20">
        <img src="assets/images/unsubscribe.png" alt="" />
      </div>
    </div>
    <div>
      <div class="inp-width" style="display: flex; align-items: stretch; align-self: stretch;">
        <mat-form-field>
          <input type="email" matInput [(ngModel)]="emailid" required placeholder="Enter your email" />
        </mat-form-field>
        <button type="submit" style="margin-left: 20px;" mat-fab extended color="primary" [ariaReadOnly]="emailid"
          (click)="onSend()">Send Code</button>
      </div>
      <div *ngIf="isCodeSend">
        <mat-form-field class="inp-width">
          <input type="text" matInput required maxlength="6" [(ngModel)]="verificationcode"
            placeholder="Enter OTP from email" />
        </mat-form-field>

        <div class="inp-width">
          <mat-radio-group [(ngModel)]="selectedType" placeholder="Mode">
            <mat-radio-button class="example-margin" value="1">I don't want emails from this business</mat-radio-button>
            <mat-radio-button class="example-margin" value="2">I don't plan on returning to this
              business</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="flex-c mt-20 mb-20"><button class="inp-width" type="submit" mat-fab extended color="primary"
            (click)="onSubmit()">Submit</button></div>
      </div>
    </div>
  </div>
</div>