import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer360 } from '../constants';
import { Observable, tap } from 'rxjs';
import { PaymentIntent } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  servierUrl = Customer360.serverUrl;
  orgDetails: any = {};

  constructor(private httpClient: HttpClient) {
    this.orgDetails.logoURL = '../../../assets/images/aspective-logo.png';
  }

  // to update the company info in setting page
  updateCompanyInfo(orgId: any, data: any): Observable<any> {
    return this.httpClient.patch(`${this.servierUrl}/Organization/UpdateOrganization?id=${orgId}`, data);
  }
  // to update the company Social Links in setting page
  updateCompanySocialLinks(data: any) {
    return this.httpClient.post(`${this.servierUrl}/Organization/UpdateOrgLink`, data);
  }

  // get all the promocodes
  getAllPromoCode(): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/Coupon/GetAllCouponCode`);
  }

  // get organization details
  getOrgDetails(orgId: any): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/Organization/GetOrganizationById?organizationId=${orgId}`).pipe(
      tap((data: any) => {
        if (data) {
          this.orgDetails = data;
          this.orgDetails.logoURL ? '' : (this.orgDetails.logoURL = '../../../assets/images/aspective-logo.png');
        }
      })
    );
  }

  // get all the promocodes
  getUserDetails(userId: any): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/User/GetUser?id=${userId}`);
  }

  // create payment intent API
  createPaymentIntent(amount: number): Observable<PaymentIntent> {
    // const servierUrl = 'http://localhost:4000';
    return this.httpClient.post<PaymentIntent>(`${this.servierUrl}/Stripe/payment/Intent`, amount);
  }

  // Save payment info once payment successful
  savePaymentInfo(paymentInfoData: any): Observable<any> {
    return this.httpClient.post<PaymentIntent>(`${this.servierUrl}/Subscription/PostSubscriptions`, paymentInfoData);
  }

  // Get all invoices details
  getAllInvoices(orgId: string): Observable<any> {
    return this.httpClient.get<PaymentIntent>(
      `${this.servierUrl}/Subscription/GetSubscriptionsByOrganizationId?organizationId=${orgId}`
    );
  }

  // Upload logo
  uploadLogo(logoFile: any, orgId: string): Observable<any> {
    return this.httpClient.post<any>(`${this.servierUrl}/Organization/UploadLogo?id=${orgId}`, logoFile);
  }

  // Get all Plans
  getAllPlans(): Observable<any> {
    return this.httpClient.get<any>(`${this.servierUrl}/Plans/GetAllPlans`);
  }

  // deactivate account
  deactivateAccount(orgId: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.servierUrl}/Organization/DeleteOrganization?id=${orgId}`);
  }

  // update account password
  updatePassword(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.servierUrl}/User/PatchPasswordUpdateById`, data);
  }

   // Get emailSettings
   getEmailSettingByEmailId(emailid:any): Observable<any> {
    return this.httpClient.get<any>(`${this.servierUrl}/Email/GetEmailConfigByEmailId?emailid=${emailid}`);
  }
   // update account password
   addUpdateEmailSetting(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.servierUrl}/Email/CreateAndPostEmailConfig`, data);
  }
}
