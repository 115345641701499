<div style="padding: 30px">
  <h1 style="text-align: center">Following are result details:</h1>
  <div class="flex">
    <div
      [ngStyle]="{ width: getScore(data?.score?.negative) + '%' }"
      style="height: 20px; background-color: #808080"
      matTooltip="Negative"
      matTooltipPosition="above"
    ></div>
    <div
      [ngStyle]="{ width: getScore(data?.score?.positive) + '%' }"
      style="height: 20px; background-color: #259e0d"
      matTooltip="Positive"
      matTooltipPosition="above"
    ></div>
    <div
      [ngStyle]="{ width: getScore(data?.score?.neutral) + '%' }"
      style="height: 20px; background-color: #cc5500"
      matTooltip="Neutral"
      matTooltipPosition="above"
    ></div>
  </div>
  <div class="flex-c flex-col mt-30">
    <p><strong>Negative: </strong> {{ getScore(data?.score?.negative) }}</p>
    <p><strong>Positive: </strong> {{ getScore(data?.score?.positive) }}</p>
    <p><strong>Neutral: </strong> {{ getScore(data?.score?.neutral) }}</p>
  </div>
</div>
