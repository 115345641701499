import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { SurveyService } from 'src/app/services/survey.service';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer360 } from '../../constants';
import { ImportStaffMember } from '../staff-member/staff-member.component';
import { StaffMembersService } from 'src/app/services/staff-members.service';
import { ToastrService } from 'ngx-toastr';
import { Constant } from 'src/app/Constant';

@Component({
  selector: 'app-ratings-survey-setup',
  templateUrl: './ratings-survey-setup.component.html',
  styleUrls: ['./ratings-survey-setup.component.css'],
})
export class RatingSurveySetupComponent implements OnInit {
  servierUrl: string = '';
  clientUrl: string = '';
  color: ThemePalette = 'accent';
  copyTheCode: any = '';
  headingVariant: any[] = [];
  organizationStaff: any[] = [];
  currentSelectedHeading: any = {};
  userTokenData: any;
  loadingImportedStaff: boolean = false;
  selectedPack: any = [
    { reactionPack: 0, isSelected: true },
    { reactionPack: 1, isSelected: false },
    { reactionPack: 2, isSelected: false },
  ];
  originalHeadingVariants: any[] = [];

  // default survey data
  previewSurveyData: any = {
    _id: '',
    organizationId: '',
    title: '',
    surveyName: '',
    isRecationOff: false,
    isReviewOff: false,
    reactionPack: 0,
    headingType: 1,
    iconSetId: 0,
  };

  // FIXME: the idea is to move this data to separate default data file in future
  reactionPackItems: any[] = [
    [
      {
        value: 1,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/1-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/1-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/1-3.png',
          },
        ],
      },
      {
        value: 2,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/2-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/2-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/2-3.png',
          },
        ],
      },
      {
        value: 3,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/3-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/3-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/3-3.png',
          },
        ],
      },
      {
        value: 4,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/4-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/4-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/4-3.png',
          },
        ],
      },
      {
        value: 5,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/5-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/5-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/5-3.png',
          },
        ],
      },
      {
        value: 6,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/6-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/6-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/6-3.png',
          },
        ],
      },
      {
        value: 7,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/7-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/7-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/7-3.png',
          },
        ],
      },
      {
        value: 8,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/8-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/8-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/8-3.png',
          },
        ],
      },
      {
        value: 9,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/9-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/9-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/9-3.png',
          },
        ],
      },
      {
        value: 10,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/10-1.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/10-2.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/10-3.png',
          },
        ],
      },
    ],
    [
      {
        value: 1,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/59-5.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/59-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/59-2.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/59-4.png',
          },
        ],
      },
      {
        value: 2,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/60-2.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/60-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/60-4.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/60-1.png',
          },
        ],
      },
      {
        value: 3,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/61-2.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/61-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/61-4.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/61-1.png',
          },
        ],
      },
      {
        value: 4,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/62-4.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/62-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/62-1.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/62-5.png',
          },
        ],
      },
      {
        value: 5,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/63-4.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/63-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/63-1.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/63-5.png',
          },
        ],
      },
    ],
    [
      {
        value: 1,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/59-5.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/59-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/59-2.png',
          },
          {
            text: 'Unhappy',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/59-1.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/59-4.png',
          },
        ],
      },
      {
        value: 2,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/60-2.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/60-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/60-4.png',
          },
          {
            text: 'Unhappy',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/60-2.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/60-1.png',
          },
        ],
      },
      {
        value: 3,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/61-2.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/61-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/61-4.png',
          },
          {
            text: 'Unhappy',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/61-2.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/61-1.png',
          },
        ],
      },
      {
        value: 4,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/62-4.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/62-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/62-1.png',
          },
          {
            text: 'Unhappy',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/62-4.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/62-5.png',
          },
        ],
      },
      {
        value: 5,
        icons: [
          {
            text: 'Negative',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/63-4.png',
          },
          {
            text: 'Neutral',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/63-3.png',
          },
          {
            text: 'Positive',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/63-1.png',
          },
          {
            text: 'Unhappy',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/63-4.png',
          },
          {
            text: 'Angry',
            url: 'https://aspectivestorage.blob.core.windows.net/staticfiles/Icons/Static/63-5.png',
          },
        ],
      },
    ],
  ];

  constructor(
    public dialog: MatDialog,
    private surveyService: SurveyService,
    private auth: AuthService,
    private staffService: StaffMembersService,
    private toastr: ToastrService
  ) {
    this.servierUrl = Customer360.serverUrl;
    this.clientUrl = Customer360.clientUrl;
    this.userTokenData = this.auth.decodeToken();
    this.previewSurveyData.organizationId = this.userTokenData.organizationId;
    this.surveyService.getDropDown(this.userTokenData.organizationId).subscribe(res => {
      this.headingVariant = res;
      this.originalHeadingVariants = JSON.parse(JSON.stringify(res));
      this.previewSurveyData.title = this.headingVariant[0].name;
      this.currentSelectedHeading = this.headingVariant[0];
    });
  }

  ngOnInit() {}

  getImgName(imgName: string): string {
    let getImg: any = imgName.split('/');
    getImg = getImg[getImg.length - 1];
    getImg = getImg.split('.')[0];

    return getImg;
  }

  getReviewType(count: any): number {
    return parseInt(count) + 1;
  }

  // handler to copy the html code of survey
  generateSendEmailCode() {
    const surveyIconsCount =
      this.reactionPackItems[this.previewSurveyData.reactionPack][this.previewSurveyData.iconSetId].icons.length;
    const width: any = {
      3: 250,
      4: 340,
      5: 421,
    };
    let imojiStr = `
    <table width="${width[surveyIconsCount]}" cellpadding="0" [cellSpacing]="0" class="preTable">
      <tr>
        <td colspan="${surveyIconsCount}">
          <h2 style="text-align: center; margin-bottom: 20px;">${this.currentSelectedHeading.name}</h2>
        </td>
      </tr>
      <tr>`;
    let i = 0;

    for (let icon of this.reactionPackItems[this.previewSurveyData.reactionPack][this.previewSurveyData.iconSetId]
      .icons) {
      imojiStr += `
                <td class="surveyImoji">
                 <a style="text-decoration: none; POINTER-NONE"
                 href="${this.clientUrl}/fill-survey/${this.userTokenData.userId}/${
        this.userTokenData.organizationId
      }/${this.getImgName(icon.url)}/${this.getReviewType(i)}"
                  >
          <img src="${icon.url}" height="45" width="45" style="display: block; margin: auto" />
          <p class="emojiText"> ${icon.text} </p>
          </a>
        </td>`;
      i++;
    }

    imojiStr += `</tr>
                  <tr>
                    <td colspan="${surveyIconsCount}">replaceWithUserSpecificButton</td>
                  <tr>
              </table>`;

    const copyTheCode = `
    <style>
      table.preTable td.surveyImoji {
        height: 110px;
        width: 70px;
        background-color: #f8f7f7;
        text-align: center;
        border-radius: 15px 15px 0 0;
        border-left: 2px solid #fff;
        padding-top: 5px
      }
      .emojiText {
        font-size: 13px;
        margin: 15px 0 0 0 !important;
        text-align: center;
        color: blue;
      }
    </style>
    <div style="display: flex; text-align: center; justify-content: center;">
        ${imojiStr}
    </div>`;

    return copyTheCode;
  }

  // to open the dialog when react pack selected
  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, packCount: number, packData: any[]): void {
    let dialogRef = this.dialog.open(StaticIconsDialog, {
      width: '470px',
      height: '500px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        packCount,
        packData,
        selectedData: {
          reactionPack: this.previewSurveyData.reactionPack,
          iconSetId: this.previewSurveyData.iconSetId,
        },
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        this.previewSurveyData.reactionPack = res.reactionPack;
        this.previewSurveyData.iconSetId = res.iconSetId;

        for (let reactCount of this.selectedPack) {
          reactCount.isSelected = false;
        }
        this.selectedPack[res.reactionPack].isSelected = true;
      }
    });
  }

  //  handler for choosing the heading variant
  variantHeadingeHandler(selectedOption: any) {
    this.currentSelectedHeading = selectedOption;
  }
  // handler for reaction pack count
  packCountHandler(count: number) {
    for (let reactCount of this.selectedPack) {
      if (reactCount.reactionPack === count) {
        reactCount.isSelected = true;
      } else {
        reactCount.isSelected = false;
      }
    }
  }

  // to open the dialog when react pack selected
  openStaffImportDialog(enterAnimationDuration: string, exitAnimationDuration: string, importedUser: any[]): void {
    let dialogRef = this.dialog.open(ImportStaffMember, {
      width: '670px',
      height: '500px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        users: importedUser,
        title: 'Select Staff to send Survey email',
        btnTxt: 'Send Survey To Staff',
        search: 'Search Staff Member',
      },
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      // received data from dialog-component
      if (res) {
        let selectedUsers = res.data.filter((user: any) => user.selected);
        debugger
        selectedUsers = selectedUsers.map((user: any) => {
          const data = {
            userId: user._id,
            email: user.email,
          };

          return data;
        });

        const apiData = {
          _id: '',
          userId: this.userTokenData.userId,
          organizationId: this.userTokenData.organizationId,
          surveySetupId: '',
          surveyName: this.previewSurveyData.surveyName,
          survey: this.generateSendEmailCode(),
          selectedUserList: selectedUsers,
          createdDate: new Date(),
          expireDate: new Date(),
          emailSend: 1,
          status: 1,
          lastModifiedDate: new Date(),
        };
        this.loadingImportedStaff = true;
        this.surveyService.saveStaffForSurvey(apiData).subscribe(
          (res: any) => {
            this.loadingImportedStaff = false;
            this.toastr.success(Constant.shared.SurveyEmailSent, Constant.shared.sucess);
            this.previewSurveyData.surveyName = '';
            this.headingVariant = JSON.parse(JSON.stringify(this.originalHeadingVariants));
            this.currentSelectedHeading = this.headingVariant[0];
            this.previewSurveyData.reactionPack = 0;
            this.previewSurveyData.iconSetId = 0;
          },
          err => {
            this.loadingImportedStaff = false;
          }
        );
      }
    });
  }

  getAllUserswithModel() {
    this.loadingImportedStaff = true;
    this.staffService.getAllUsers(this.userTokenData.organizationId).subscribe(res => {
      this.organizationStaff = res.map((ele: any) => {
        ele.selected = false;
        ele.isMatchingWithSearch = true;

        return ele;
      });

      this.openStaffImportDialog('0ms', '0ms', this.organizationStaff);
      this.loadingImportedStaff = false;
    });
  }

  // to open the dialog when react pack selected
  openSurveyHeadingEditModel(enterAnimationDuration: string, exitAnimationDuration: string, headingData: any[]): void {
    let dialogRef = this.dialog.open(EditSurveyHeadingModel, {
      width: '500px',
      height: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        heading: headingData,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        this.previewSurveyData.title = res.name;
        const data = {
          _id: '',
          organizationId: this.userTokenData.organizationId,
          name: res.text,
          type: 0,
        };
        this.headingVariant.push({ _id: this.headingVariant.length, name: res.text });
        this.currentSelectedHeading = this.headingVariant[this.headingVariant.length - 1];
        this.surveyService.saveHeadingVariant(data).subscribe(res => {});
      }
    });
  }
}

@Component({
  selector: 'static-icons-dialog',
  templateUrl: './static-icons-dialog.component.html',
  styleUrls: ['./ratings-survey-setup.component.css'],
})
export class StaticIconsDialog {
  constructor(public dialogRef: MatDialogRef<StaticIconsDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  cancel() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: 'you cancelled' });
  }

  confirm() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: 'you confirmed' });
  }
}

@Component({
  selector: 'edit-heading',
  templateUrl: 'edit-heading.html',
})
export class EditSurveyHeadingModel {
  headingText: string = '';

  constructor(public dialogRef: MatDialogRef<EditSurveyHeadingModel>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.headingText = data.heading.name;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
