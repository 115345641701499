<div style="padding: 15px 45px">
  <div class="setup-container flex-sb" style="align-items: flex-start !important; margin-bottom: 40px">
    <div class="flex-sb">
      <h1>My Account</h1>
    </div>
    <div>
      <div class="flex" style="justify-content: end">
        <app-logout-btn></app-logout-btn>
      </div>
    </div>
  </div>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-col mt-5">
          <mat-icon>receipt_long</mat-icon>
          <h5 style="margin: 0">Company Info</h5>
        </div>
      </ng-template>
      <div class="flex-sa">
        <div class="mt-20" style="display: flex; gap: 30px; flex-wrap: wrap">
          <mat-card class="company-card">
            <h2>Company ID:</h2>
            <p>{{ companyId }}</p>
            <h2>Company Name:</h2>
            <p>{{ companyName }}</p>
            <h2>Email:</h2>
            <p>{{ companyEmail }}</p>
            <div style="width: 150px; margin: auto">
              <button mat-fab extended color="primary" style="display: block; background-color: rgb(98, 177, 78)"
                class="ml-10" (click)="openEditCompanyDetailsDialog('0ms', '0ms', {companyName, companyEmail})">
                Edit details
              </button>
            </div>
          </mat-card>

          <mat-card class="company-card">
            <h2>Company Logo:</h2>
            <p class="mt-20">
              <img mat-card-sm-image style="width: auto; height: auto; max-width: 400px; max-height: 100px"
                src="{{ settingsService.orgDetails.logoURL }}" alt="Logo" />
            </p>
            <div style="width: 200px; margin: auto">
              <input type="file" #file (change)="onFileSelected(file.files)" />
              <button type="submit" mat-fab extended color="primary"
                style="background-color: rgb(98, 177, 78); margin-top: 55px" (click)="onSubmit()">
                Upload Logo
              </button>
            </div>
          </mat-card>

          <mat-card class="company-card">
            <h2>Facebook URL</h2>
            <p>{{ companyDetails?.facebookLink?.length > 0 ? companyDetails?.facebookLink : 'NA' }}</p>
            <h2>Twitter URL</h2>
            <p>{{ companyDetails?.twitterLink?.length > 0 ? companyDetails?.twitterLink : 'NA' }}</p>
            <h2>Instagram URL</h2>
            <p>{{ companyDetails?.instagramLink?.length > 0 ? companyDetails?.instagramLink : 'NA' }}</p>
            <h2>Linkedin URL</h2>
            <p>{{ companyDetails?.linkdinLink?.length > 0 ? companyDetails?.linkdinLink : 'NA' }}</p>
            <div style="width: 200px; margin: auto">
              <button type="submit" mat-fab extended color="primary"
                style="background-color: rgb(98, 177, 78); margin-top: 0; width: 100px"
                (click)="openEditCompanyUrlDialog('0ms', '0ms', {companyDetails})">
                Edit
              </button>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="auth.isSuperAdmin()">
      <ng-template mat-tab-label>
        <div class="flex-col mt-5">
          <mat-icon>subscriptions</mat-icon>
          <h5 style="margin: 0">Subscriptions</h5>
        </div>
      </ng-template>
      <div class="mt-20">
        <table class="plans-tbl" style="margin-bottom: 50px">
          <tr>
            <th>Plan Name</th>
            <th>Surveys</th>
            <th>Staff</th>
            <th>Admin</th>
            <th>Responses/Month</th>
            <th>Cost</th>
            <th>Action</th>
          </tr>
          <tr>
            <td style="width: 200px">
              <div class="flex flex-col plan-dd">
                <mat-form-field class="flex-row">
                  <mat-select [value]="selectedPlan?.planName" name="food">
                    <mat-option *ngFor="let plan of plans" [value]="plan.planName" (click)="selectPlanHandler(plan)">
                      {{ plan.planName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>{{ selectedPlan?.surveys }}</td>
            <td>{{ selectedPlan?.staff }}</td>
            <td>{{ selectedPlan?.admin }}</td>
            <td>{{ selectedPlan?.monthlyResponse }}</td>
            <td>${{ selectedPlan?.price }}</td>
            <td>
              <button *ngIf="selectedPlan?.planName !== initialSelectedPlan.planName" mat-fab extended color="primary"
                style="margin: auto; background-color: rgb(98, 177, 78)" class="ml-10" (click)="isUpgradeHandler()">
                Upgrade
              </button>
              <button *ngIf="selectedPlan?.planName == initialSelectedPlan.planName" mat-fab extended disabled
                color="primary" style="margin: auto" class="ml-10">
                Active
              </button>
            </td>
          </tr>
        </table>
        <div *ngIf="
            upgradePlanSelected && this.selectedPlan?._id !== '65295f6a7f315528b76649a9' && this.selectedPlan?._id !== 4
          " class="flex-c mt-20 upgrade-plan">
          <h2>
            Selected Plan: <span style="color: green">{{ planToUpdate.plan }}</span>
          </h2>
          <h2>
            Plan Price: <span style="color: green">${{ planToUpdate.price }}</span>
          </h2>
          <div class="promocode-container">
            <div class="flex-sb promocode">
              <mat-form-field style="width: 360px">
                <input type="text" matInput [(ngModel)]="promocode" placeholder="Enter Promo code" />
              </mat-form-field>
              <button mat-fab extended color="primary" [disabled]="promocode.trim().length === 0"
                style="display: block; width: 100px" (click)="ApplyPromoCode()">
                Apply
              </button>
            </div>
            <p *ngIf="!isPromoValid" style="color: red; margin-top: 10px; margin-bottom: 0; padding-left: 20px">
              Promo Code Not Valid!!
            </p>
            <p *ngIf="this.promoCodeApplyDetails.isPromoApplied && isPromoValid"
              style="color: green; margin-top: 10px; margin-bottom: 0; padding-left: 20px">
              Congratulations!! Promo Code is Valid.
            </p>
          </div>
          <button mat-fab extended color="primary"
            style="display: block; width: 200px; margin: auto; background-color: rgb(98, 177, 78)"
            (click)="makePayment()">
            Proceed
          </button>
        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="auth.isSuperAdmin()">
      <ng-template mat-tab-label>
        <div class="flex-col mt-5">
          <mat-icon>article</mat-icon>
          <h5 style="margin: 0">Invoices</h5>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <div class="mt-20">
          <table class="plans-tbl">
            <tr>
              <th>No.</th>
              <th>Start date</th>
              <th>End date</th>
              <th>Status</th>
              <th>Download</th>
            </tr>
            <tr *ngFor="let sub of subscriptionData; index as i">
              <td>{{ i + 1 }}</td>
              <td>{{ getDateAndTime(sub.createdDate) }}</td>
              <td>{{ getDateAndTime(sub.endDate) }}</td>
              <td>{{ sub.status == 1 ? 'Active' : 'Inactive' }}</td>
              <td>
                <button mat-raised-button color="primary" style="background-color: rgb(71, 116, 5); padding: 7px"
                  (click)="onViewDetailClick(sub)">
                  View Details
                </button>
              </td>
            </tr>
          </table>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-col mt-5">
          <mat-icon>account_box</mat-icon>
          <h5 style="margin: 0">Account</h5>
        </div>
      </ng-template>
      <div>
        <mat-card class="company-card">
          <p style="margin-top: -20px">
            <img mat-card-sm-image style="width: 230px; height: 150px" src="../../../assets/images/Password-img.png"
              alt="Logo" />
          </p>
          <div style="width: 265px; margin: auto; margin-top: 5px d-none">
            <button type="submit" mat-fab extended color="primary" style="background-color: rgb(98, 177, 78)"
              (click)="openResetPasswordDialog()">
              Update Your Password
            </button>
          </div>
          <div style="width: 265px; margin: auto; margin-top: 10px">
            <button type="submit" mat-fab extended color="primary" style="background-color: rgb(98, 177, 78)"
              (click)="openAccountDeactivateDialog('0ms', '0ms', { orgId: userTokenData.organizationId })">
              Deactivate Account
            </button>
          </div>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex-col mt-5">
          <mat-icon>email</mat-icon>
          <h5 style="margin: 0">Email Setup</h5>
        </div>
      </ng-template>
     <email-setup-view/>
    </mat-tab>
  </mat-tab-group>
</div>