import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer360 } from '../constants';
import { BehaviorSubject, Observable, map, of, tap } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MsalService } from '@azure/msal-angular';
import { Users } from '../models/users';
import { Role } from '../enums/enum';
import { UserProfileComponent } from '../pages/user-profile/user-profile.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

const USER_KEY = 'token';
const helper = new JwtHelperService();
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  refreshToken(): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/User/GetRefreshToken?Email=${this.getEmailId()}`).pipe(
      map(data => {
        this.settoken(data);
        this.isloggedIn = true;
      })
    );
  }

  private isloggedIn: boolean;
   public profiledata = new Map();
  servierUrl = Customer360.serverUrl;
  constructor(private httpClient: HttpClient,private msalAuthService: MsalService,private dialog:MatDialog,private router:Router) {
    this.isloggedIn = false;
  }
  isAuthenticated(): boolean {
    const ifLoggedIn = this.msalAuthService.instance.getAllAccounts().length;
    if (ifLoggedIn) {
      this.islogin.next(true);
      return true;
    } else {
      this.islogin.next(false);
      return false;
    }
  }

  public islogin = new BehaviorSubject<boolean>(false);
  islogin$ = this.islogin.asObservable();
  getAccountDetails(): any {
    return this.msalAuthService.instance.getAllAccounts();
  }
  getActiveAccount(): any {
    return this.msalAuthService.instance.getActiveAccount();
  }
  getUserEmail() {
    const user = this.msalAuthService.instance.getAllAccounts();
    console.log(user);
    const email = user[0]?.username;
    return email ? email : null;
  }
  getUserByEmail():Observable<any> {     
  
    return this.httpClient.get<Users>(`${this.servierUrl}/User/GetUser?id=${this.getUserEmail()}`);

  }
  registerUser(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/Organization/CreateOrganizationAndUser`, data);
  }
  // reset password
  forgotPassword(email: string): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/User/ForgotPassword?Email=${email}`, { responseType: 'text' });
  }
  // login User
  loginUser(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/User/Login`, data).pipe(
      tap((data: any) => {
        if (data?.token) {
          this.settoken(data);
          this.isloggedIn = true;
        }
      })
    );
  }
 // SendEmailVerificationCode
 sendverificationCode(email: string): Observable<any> {
  return this.httpClient.get(`${this.servierUrl}/User/SendEmailVerificationCode?Email=${email}`);
}
 // UnsubscribedList
 getAllUnsubscribedEmail(orgid:string): Observable<any> {
  return this.httpClient.get(`${this.servierUrl}/User/UnsubscribedList?_id=${orgid}`);
}
 // UnsubscribedEmail
 unsubscribedEmail(data: any): Observable<any> {
  return this.httpClient.post(`${this.servierUrl}/User/UnsubscribedEmail`,data, { responseType: 'text' });
}
  isUserLoggedIn(): boolean {
    return (this.isloggedIn = true);
  }

  decodeToken():any {
 if(this.profiledata.get('user')){
  return this.profiledata.get('user')}
else {
this.router.navigate(['/'])

}
    // if (this.getoken()) {
    //   return helper.decodeToken(this.getoken());
    // }
  }

  public getoken(): any {
    return this.msalAuthService.instance.getActiveAccount()?.idToken;
    window.localStorage.getItem(USER_KEY);
  }

  isSuperAdmin() {
    return this.decodeToken().role === Role.SuperAdmin;
  }

  getUser() {
    return this.decodeToken() ? this.decodeToken().displayName : null;
  }

  getEmailId() {
    return this.getUserEmail();
    return this.decodeToken() ? this.decodeToken().email : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return null// this.decodeToken() ? this.decodeToken().exp : null;
  }
  // isAdminUser():boolean {
  //     if (this.userName=='Admin') {
  //         return true;
  //     }
  //     return false;
  // }

  isTokenExpired(): boolean {
    const expiryTime: string | null = this.getExpiryTime();
    if (expiryTime) {
      return 1000 * Number(expiryTime) - new Date().getTime() < 5000;
    } else {
      return false;
    }
  }

  public settoken(token: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, token);
  }

    // to open the dialog when react pack selected
    openDialog(enterAnimationDuration: string, exitAnimationDuration: string, data: any): void {
      let dialogRef = this.dialog.open(UserProfileComponent, {
        width: '450px',
        height: '500px',
        enterAnimationDuration,
        exitAnimationDuration,
        data:data,disableClose: true 
      });
  
      dialogRef.afterClosed().subscribe((res:any) => {
        // received data from dialog-component
        if (res) {        
        dialogRef.close()
        }
      });
    }
}
