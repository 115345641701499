export const getCurrentDateTime = (date: string) => {
  const today = new Date(date);
  let datetext = today.toTimeString();
  datetext = datetext.split(' ')[0];

  const yyyy = today.getFullYear();
  let mm: any = today.getMonth() + 1; // Months start at 0!
  let dd: any = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + '/' + mm + '/' + yyyy;

  return formattedToday + ' ' + datetext;
};
