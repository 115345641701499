import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer360 } from '../constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  servierUrl = Customer360.serverUrl;

  constructor(private httpClient: HttpClient) {}
  getDropDown(orgid: any): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/DropDown/GetDropDownByOrgId?organizationId=${orgid}`);
  }
  createDropdown(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/DropDown/PostDropDown`, data);
  }
  getSurveyByOrgId(orgid: string): Observable<any> {
    return this.httpClient.get(
      `${this.servierUrl}/PreviewSurvey/GetPreviewSurveyByOrganizationId?organizationId=${orgid}`
    );
  }
  postUpdateSurveyData(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/PreviewSurvey/createandUpdatePreviewSurvey`, data);
  }

  saveStaffForSurvey(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/UsersSurvey/CreateandUpdateUsersSurvey`, data);
  }

  saveHeadingVariant(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/DropDown/CreateAndPostDropDown`, data);
  }

  getAllOrganizationSurvey(orgid: any): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/UsersSurvey/GetUsersSurveyByOrganizationId?organizationId=${orgid}`);
  }

  UpdateSurveyStatusById(data: any): Observable<any> {
    return this.httpClient.get(
      `${this.servierUrl}/UsersSurvey/PatchUpdateActiveSurveyById?id=${data._id}&status=${data.status}`
    );
  }

  UpdateSurveyStaffListById(data: any): Observable<any> {
    return this.httpClient.patch(`${this.servierUrl}/UsersSurvey/PatchUpdateUsersListSurvey?id=${data._id}`, data);
  }

  getCurrentStaffSurveyToCopyCode(data: any): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/UsersSurvey/survey/${data.orgId}/${data.surveyId}/${data.userId}`, {
      responseType: 'text',
    });
  }

  sendReviewAndGetSurvey(data: any): Observable<any> {
    return this.httpClient.get(
      `${this.servierUrl}/Ratings/fillrate/${data.orgId}/${data.surveyId}/${data.userId}/${data.imgName}/${data.rate}`
    );
  }

  sendFeedback(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/Ratings/feedback`, data);
  }
}
