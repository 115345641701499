import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/services/auth.service';
import { StaffMembersService } from 'src/app/services/staff-members.service';
import { SurveyjsCreatorService } from 'src/app/services/surveyjs-creator.service';
import { getCurrentDateTime } from 'src/app/utility';
import { SurveyCreatorModel } from 'survey-creator-core';
import { CsvData, ImportStaffMember, RemoveStaffMember } from '../staff-member/staff-member.component';
import { Customer360 } from 'src/app/constants';
import { ToastrService } from 'ngx-toastr';
import { Constant } from 'src/app/Constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactsService } from 'src/app/services/contacts.service';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
};

@Component({
  selector: 'app-survey-js-creator',
  templateUrl: './survey-js-creator.component.html',
  styleUrls: ['./survey-js-creator.component.css'],
})
export class SurveyJSCreatorComponent {
  surveyCreatorModel!: SurveyCreatorModel;
  displayedColumns: string[] = ['_id', 'name', 'actions'];
  dataSource: any[] = [];
  isSurveysLoaded = true;
  selection = new SelectionModel<any>(true, []);
  userTokenData!: any;
  showCreateSurvey = false;
  creator!: any;
  currentEditingSurvey!: any;
  isLoadingSurvey = false;
  isLoadingAllProjects = false;
  AllProjects!: any[];

  constructor(
    public dialog: MatDialog,
    public surveyCreatorService: SurveyjsCreatorService,
    public authSer: AuthService,
    private router: Router
  ) {
    this.isLoadingAllProjects = true;
    this.userTokenData = this.authSer.decodeToken();
    console.log('this.userTokenData: ', this.userTokenData);
  }

  downloadSurveyByOrgId(): void {
    this.surveyCreatorService.getSurveyResultByOrgId(this.userTokenData.organizationId).subscribe(res => {
      const reportData = res.map((x: any) => JSON.parse(x.json));
      this.surveyCreatorService.exportAsExcelFile(reportData, 'sample');
    });
  }

  downloadSurveyBySurveyId(surveyId: any): void {
    this.surveyCreatorService.getSurveyBySurveyId(surveyId).subscribe(res => {
      const reportData = res.map((x: any) => JSON.parse(x.json));
      this.surveyCreatorService.exportAsExcelFile(reportData, 'sample');
    });
  }

  ngOnInit() {
    this.surveyCreatorService.getAllProjects(this.userTokenData.organizationId).subscribe(res => {
      this.AllProjects = res;
      this.isLoadingAllProjects = false;
    });
    this.creator = new SurveyCreatorModel(creatorOptions);
    this.surveyCreatorModel = this.creator;
    this.creator.text = this.currentEditingSurvey?.json;
    this.creator.saveSurveyFunc = (saveNo: number, callback: Function) => {
      // If you use localStorage:
      callback(saveNo, true);

      // If you use a web service:
      const servierUrl = Customer360.serverUrl;
      this.surveyCreatorService
        .saveSurveyJson(this.creator.JSON, this.currentEditingSurvey._id, this.userTokenData.userId)
        .subscribe(
          res => {
            callback(saveNo, true);
          },
          err => {
            callback(saveNo, false);
          }
        );
      // this.saveSurveyJson(
      //   `${servierUrl}/SurveyJS/PatchUpdateSurveyJS?id=${this.currentEditingSurvey._id}`,
      //   this.creator.JSON,
      //   saveNo,
      //   callback
      // );
    };
  }

  // Open Share Surevy Email
  onShareClickHandler() {
    let dialogRef = this.dialog.open(SendSurveyEmailDialog, {
      width: '700px',
      height: '610px',
    });
  }

  // to open the dialog when click on remove project button
  openProjectRemoveDialog(enterAnimationDuration: string, exitAnimationDuration: string, userId: any): void {
    let dialogRef = this.dialog.open(RemoveStaffMember, {
      width: '400px',
      height: '180px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { userId, title: 'Are you sure to remove this Project?', btnTxt: 'Remove' },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        this.surveyCreatorService.deleteProject(userId.projectId).subscribe(res => {
          console.log('project deleted: ', res);
          this.AllProjects = this.AllProjects.filter((project: any) => project._id !== userId.projectId);
        });
      }
    });
  }

  openSurveyRemoveDialog(enterAnimationDuration: string, exitAnimationDuration: string, userId: any): void {
    let dialogRef = this.dialog.open(RemoveStaffMember, {
      width: '400px',
      height: '180px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { userId, title: 'Are you sure to remove this Survey?', btnTxt: 'Remove' },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        this.surveyCreatorService.deleteSurvey(userId.survey).subscribe(res => {
          console.log('survey deleted: ', res);
          this.AllProjects = this.AllProjects.map(project => {
            project.surveyList = project.surveyList.filter((survey: any) => survey._id !== userId.survey);

            return project;
          });
        });
      }
    });
  }

  // If you use a web service (this was used to save survey JSON without token):
  // saveSurveyJson(url: string | URL, json: object, saveNo: number, callback: Function) {
  //   const data = {
  //     _id: this.currentEditingSurvey._id,
  //     updatedByUserId: this.userTokenData.userId,
  //     updatedAt: new Date(),
  //     json: JSON.stringify(json),
  //   };
  //   fetch(url, {
  //     method: 'PATCH',
  //     headers: {
  //       'Content-Type': 'application/json;charset=UTF-8',
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then(response => {
  //       if (response.ok) {
  //         callback(saveNo, true);
  //       } else {
  //         callback(saveNo, false);
  //       }
  //     })
  //     .catch(error => {
  //       callback(saveNo, false);
  //     });
  // }

  // to open the dialog when add survey clicked
  openSurveyProjectNameModal(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    data: any,
    type: string
  ): void {
    let dialogRef = this.dialog.open(CreateEditSurveyProjectModal, {
      width: '500px',
      height: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        ...data,
        type,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        console.log('res: ', res);

        res = {
          ...res.data,
          name: res.name,
          isHrSurvey: res.isHrSurvey,
          isAnonymousSurvey: res.isAnonymousSurvey,
          isNPSSurvey: res.isNPSSurvey,
        };
        let data: any = null;
        if (res.type === 'project' && !res.isEdit) {
          data = {
            projectName: res.name,
            orgId: this.userTokenData.organizationId,
            createdByUserId: this.userTokenData.userId,
            updatedByUserId: this.userTokenData.userId,
            updatedAt: new Date(),
            createdAt: new Date(),
            _id: '',
          };
          this.surveyCreatorService.createProject(data).subscribe(res => {
            this.ngOnInit();
            console.log('Project created: ', res);
          });
        } else if (res.type === 'project' && res.isEdit) {
          data = {
            projectName: res.name,
            updatedByUserId: this.userTokenData.userId,
            updatedAt: new Date(),
            _id: res.projectId,
          };
          this.surveyCreatorService.editProjectName(data, res.projectId).subscribe(res => {
            this.ngOnInit();
            console.log('project Name updated: ', res);
          });
        } else if (res.type === 'survey' && !res.isEdit) {
          console.log('res: ', res);
          data = {
            _id: '',
            surveyName: res.name,
            createdByUserId: this.userTokenData.userId,
            updatedByUserId: this.userTokenData.userId,
            orgId: this.userTokenData.organizationId,
            projectId: res.projectId,
            createdAt: new Date(),
            updatedAt: new Date(),
            isHrSurvey: res.isHrSurvey,
            isAnonymousSurvey: res.isAnonymousSurvey,
            isNPS: res.isNPSSurvey,
            json: `{}`,
          };
          this.surveyCreatorService.createSurvey(data).subscribe(res => {
            this.ngOnInit();
            console.log('Survey created: ', res);
          });
        } else if (res.type === 'survey' && res.isEdit) {
          data = {
            surveyName: res.name,
            _id: res.surveyId,
            isNPSSurvey: res.isNPSSurvey,
            isHrSurvey: res.isHrSurvey,
            isAnonymousSurvey: res.isAnonymousSurvey,
            updatedByUserId: this.userTokenData.userId,
            updatedAt: new Date(),
          };
          this.surveyCreatorService.editSurveyName(data, res.surveyId).subscribe(res => {
            this.ngOnInit();
            console.log('Survey name updated!! ', res);
          });
        }
      }
    });
  }

  editSingleSurvey(surveyId: string) {
    this.isLoadingSurvey = true;
    this.surveyCreatorService.getSingleSurvey(surveyId).subscribe(res => {
      this.showCreateSurvey = !this.showCreateSurvey;
      this.currentEditingSurvey = res;
      this.ngOnInit();
      this.isLoadingSurvey = false;
    });
  }

  openSurvey(orgId: string, surveyId: string, userId: string) {
    this.router.navigate(['/customerFillSurvey/', orgId, surveyId, userId]);
  }

  openResultDashboard(orgId: string, surveyId: string) {
    this.router.navigate(['/onlineSurveyDashboard/', orgId, surveyId]);
  }

  getDateAndTime(time: string) {
    return getCurrentDateTime(time);
  }

  openSendSurveyModal(enterAnimationDuration: string, exitAnimationDuration: string, data: any) {
    let dialogRef = this.dialog.open(ImportContactsModal, {
      width: '700px',
      height: '450px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        ...data,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
      }
    });
  }
}

@Component({
  selector: 'create-survey',
  templateUrl: 'create-edit-survey.html',
})
export class CreateEditSurveyProjectModal {
  headingText: string = '';
  isHrSurvey: boolean = false;
  isAnonymousSurvey: boolean = false;
  isNPSSurvey: boolean = false;

  constructor(public dialogRef: MatDialogRef<CreateEditSurveyProjectModal>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.headingText = data.name;
    if (data.isHrSurvey) {
      this.isHrSurvey = data.isHrSurvey;
    }

    if (data.isAnonymousSurvey) {
      this.isAnonymousSurvey = data.isAnonymousSurvey;
    }

    if (data.isNPSSurvey) {
      this.isNPSSurvey = data.isNPSSurvey;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'import-contacts-modal',
  templateUrl: 'import-contacts-modal.html',
  styleUrls: ['./survey-js-creator.component.css'],
})
export class ImportContactsModal {
  loginDisplay = false;
  isAzureAdminChecked = false;
  loadingImportedStaff = false;
  importedUsersList: any[] = [];
  records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  jsondatadisplay: any;
  selectedFile: any;
  allUsersList: any = [];
  allMarketingList: any = [];
  loadingUsersList = false;
  loadingMarketingList = false;

  constructor(
    public dialogRef: MatDialogRef<ImportContactsModal>,
    private authService: MsalService,
    public dialog: MatDialog,
    public staffService: StaffMembersService,
    public surveyCreatorService: SurveyjsCreatorService,
    public toastr: ToastrService,
    public contactsService: ContactsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('modal data: ', data);
    this.loadingUsersList = true;
    this.surveyCreatorService.getAllListsOfOrg(this.data.orgId).subscribe(res => {
      this.allUsersList = res;
      this.loadingUsersList = false;
    });

    this.loadingMarketingList = true;
    this.contactsService.getAllCustomerLists(this.data.orgId).subscribe(res => {
      this.allMarketingList = res;
      this.loadingMarketingList = false;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  // login for azure AD
  login() {
    this.authService.loginPopup({ scopes: ['User.Read.All'] }).subscribe({
      next: result => {
        this.loadingImportedStaff = true;
        this.setLoginDisplay();
        this.staffService.getMS365UsersList(result.accessToken).subscribe(data => {
          console.log('data: ', data);
          this.importedUsersList = data.value.map((ele: any) => {
            ele.selected = false;
            ele.isMatchingWithSearch = true;
            ele.email = ele.mail;
            return ele;
          });
          this.openStaffImportDialog('0ms', '0ms', this.importedUsersList, 'sendOnlineSurvey');
          this.loadingImportedStaff = false;
        });
      },
      error: error => console.log(error),
    });
  }
  // to get the date and time formatted
  getDateAndTime(time: string) {
    return getCurrentDateTime(time);
  }

  // handler to view the user of the list
  viewListHandler(userList: any, listId: string, isMarketingList?: boolean) {
    if (isMarketingList) {
      this.contactsService.getSingleListData(listId).subscribe(res => {
        userList = res.contacts.map((user: any) => {
          user.isMatchingWithSearch = true;
          user.selected = false;
          user.displayName = user.name;

          return user;
        });

        if (userList.length > 0) {
          this.openUserListDialog('0ms', '0ms', { userList, listId }, 'ViewUserList', true);
        }
      });
    } else {
      userList = userList.map((user: any) => {
        user.isMatchingWithSearch = true;
        user.selected = false;
        user.displayName = user.name;

        return user;
      });

      if (userList.length > 0) {
        this.openUserListDialog('0ms', '0ms', { userList, listId }, 'ViewUserList');
      }
    }
  }
  // Modal to show the users of the list - where admin can send the email to created list user also
  openUserListDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    data: any,
    type?: string,
    isMarketingList?: boolean
  ): void {
    let dialogRef = this.dialog.open(ImportStaffMember, {
      width: '700px',
      height: '500px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { users: data.userList, title: 'Users List', btnTxt: 'Send Survey', type, search: 'Search User' },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      console.log('res: ', res);
      if (res) {
        const payload = {
          _id: '',
          listId: data.listId,
          surveyId: this.data.surveyId,
          sentByUserId: this.data.createdByUserId,
          sentAt: new Date(),
          orgId: this.data.orgId,
          isAnonymousSurvey: this.data.isAnonymousSurvey,
        };

        if (isMarketingList) {
          this.surveyCreatorService.sendSurveyToMarketingList(payload).subscribe(res => {
            this.toastr.success(Constant.shared.SurveyEmailSent, Constant.shared.sucess);
            console.log('survey sent to existing list!!');
          });
        } else {
          this.surveyCreatorService.sendSurveyToExistingList(payload).subscribe(res => {
            this.toastr.success(Constant.shared.SurveyEmailSent, Constant.shared.sucess);
            console.log('survey sent to existing list!!');
          });
        }
      }
      this.dialogRef.close(ImportContactsModal);
    });
  }

  // to open the modal when users imported
  openStaffImportDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    importedUser: any[],
    type?: string
  ): void {
    let dialogRef = this.dialog.open(ImportStaffMember, {
      width: '670px',
      height: '500px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { users: importedUser, title: 'Imported Users List', btnTxt: 'Send Survey', type },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        const selectedUsers = [];
        for (let user of res.data) {
          if (user.selected === true) {
            const singleUser = {
              _id: '',
              name: user.displayName,
              email: user.mail,
              listId: '',
              createdDate: new Date(),
              status: 1,
            };

            selectedUsers.push(singleUser);
          }
        }

        const payloadData = {
          _id: '',
          listName: res.surveyListName,
          orgId: this.data.orgId,
          createdByUserId: this.data.createdByUserId,
          createdAt: new Date(),
          surveyId: this.data.surveyId,
          listUsers: selectedUsers,
        };
        console.log('payload Data: ', payloadData);
        if (selectedUsers.length) {
          this.surveyCreatorService.createSurveyList(payloadData).subscribe(res => {
            console.log('list created: ', res);
          });
        }
      }
      this.dialogRef.close(ImportContactsModal);
      this.toastr.success(Constant.shared.SurveyEmailSent, Constant.shared.sucess);
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  logout() {
    this.authService.logoutPopup({
      postLogoutRedirectUri: '/dashboard',
      mainWindowRedirectUri: '/dashboard',
    });
  }

  uploadListener($event: any): void {
    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

        let headersRow = this.getHeaderArray(csvRecordsArray);

        const ImportedUsers = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
        this.records = ImportedUsers.filter(user => this.validateEmail(user.email));
        if (this.records.length === 0) {
          alert('Please import valid .csv file with name and email.');
          this.fileReset();
        } else {
          this.records = this.records.map((ele: any) => {
            ele.selected = false;
            ele.isMatchingWithSearch = true;
            ele.displayName = ele.name;
            ele.email = ele.email;

            return ele;
          });
          this.openStaffImportDialog('0ms', '0ms', this.records);
        }
      };

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };
    } else {
      alert('Please import valid .csv file.');
      this.fileReset();
    }
  }

  //check etension
  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord: CsvData = new CsvData();
        csvRecord.name = curruntRecord[0].trim();
        csvRecord.email = curruntRecord[1].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  validateEmail(email: any) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
    this.jsondatadisplay = '';
  }

  // to open the dialog when react pack selected
  openUserListRemoveDialog(enterAnimationDuration: string, exitAnimationDuration: string, userId: any): void {
    let dialogRef = this.dialog.open(RemoveStaffMember, {
      width: '400px',
      height: '180px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { userId, title: 'Are you sure to remove this List?', btnTxt: 'Remove' },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        this.surveyCreatorService.deleteUserList(res.userId).subscribe(resp => {
          this.allUsersList = this.allUsersList.filter((list: any) => list._id !== res.userId);
          console.log('list deleted!!');
        });
      }
    });
  }
}

//Share Surevy Email
@Component({
  selector: 'send-survey-email-dialog',
  templateUrl: 'send-survey-email-dialog.html',
  styleUrls: ['./survey-js-creator.component.css'],
})
export class SendSurveyEmailDialog {
  sendSurveyEmailLoader: boolean = false;
  nonWhitespaceRegExp: RegExp = new RegExp('\\S');
  sendEmailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    subject: new FormControl('', [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]),
    message: new FormControl('', [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]),
  });

  get email(): any {
    return this.sendEmailForm.get('email');
  }

  get subject(): any {
    return this.sendEmailForm.get('subject');
  }

  get message(): any {
    return this.sendEmailForm.get('message');
  }

  constructor(
    public dialogRef: MatDialogRef<SendSurveyEmailDialog>,
    private _surveyJsService: SurveyjsCreatorService,
    private toastr: ToastrService
  ) {}

  sendSurvryEmail() {
    const payload = {
      to: [
        {
          address: this.email.value,
          displayName: '',
        },
      ],
      subject: this.subject.value,
      body: this.message.value,
    };

    this.sendSurveyEmailLoader = true;
    this._surveyJsService.SendSurveyEmail(payload).subscribe(
      res => {
        this.toastr.success(Constant.shared.sendSurveyEmail, Constant.shared.sucess);
        this.dialogRef.close();
        this.sendSurveyEmailLoader = false;
        this.sendEmailForm.reset();
      },
      err => {
        this.toastr.error('', 'Something went wrong');
        this.dialogRef.close();
        this.sendSurveyEmailLoader = false;
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
