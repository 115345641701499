<style>
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
</style>
<div style="padding: 20px">
  <div>
    <h2>{{data.title}} (Users: {{data.users.length}})</h2>
    <div class="flex-sb">
      <mat-form-field style="width: 50%; margin-right: 10px">
        <input
          type="text"
          #searchStaffMember
          matInput
          [(ngModel)]="searchUserName"
          (input)="searchStaffMemberHandler(searchStaffMember.value)"
          placeholder="{{data.search}}"
        />
      </mat-form-field>
      <form *ngIf="data.type == 'marketingList'" [formGroup]="addListNameForm">
        <mat-form-field style="margin-right: 10px">
          <input
            type="text"
            formControlName="addListName"
            #sendListName
            matInput
            required
            placeholder="Enter List Name*"
          />
        </mat-form-field>
        <div
          style="color: red; margin-top: -20px; margin-left: 20px"
          *ngIf="addListName.invalid && (addListName.dirty || addListName.touched)"
        >
          List name is required
        </div>
      </form>

      <button
        *ngIf="data.type == 'marketingList'"
        mat-fab
        extended
        color="primary"
        style="align-self: flex-start"
        [disabled]="isAddStaffDisabled || !addListNameForm.valid"
        [mat-dialog-close]="{ data: data.users,addListName }"
      >
        {{data.btnTxt}}
      </button>
      <button
        *ngIf="!data.isSendToAllEmailBtn && data.type !== 'sendOnlineSurvey' && data.type !== 'ViewUserList' && data.type!== 'marketingList'"
        mat-fab
        extended
        color="primary"
        style="align-self: flex-start"
        [disabled]="isAddStaffDisabled"
        [mat-dialog-close]="{ data: data.users, sendEmailTo: 1 }"
      >
        {{data.btnTxt}}
      </button>
      <button
        *ngIf="data.isSendToAllEmailBtn"
        mat-raised-button
        [disabled]="isAddStaffDisabled"
        [mat-dialog-close]="{ data: data.users, sendEmailTo: 1 }"
        style="padding: 7px; background-color: yellowgreen; margin-bottom: 25px"
      >
        {{data.btnTxt}}
      </button>
      <button
        *ngIf="data.type === 'sendOnlineSurvey'"
        mat-raised-button
        [disabled]="isAddStaffDisabled"
        [mat-dialog-close]="{ data: data.users, surveyListName: surveyUserList }"
        style="padding: 7px; background-color: yellowgreen; margin-bottom: 25px"
      >
        {{data.btnTxt}}
      </button>
      <button
        *ngIf="data.type === 'ViewUserList'"
        mat-raised-button
        [mat-dialog-close]="{ data: data.users, surveyListName: surveyUserList }"
        style="padding: 7px; background-color: yellowgreen; margin-bottom: 25px"
      >
        {{data.btnTxt}}
      </button>
    </div>
    <div
      *ngIf="data.type === 'sendOnlineSurvey' && !isAddStaffDisabled"
      style="display: flex; justify-content: flex-end"
    >
      <mat-form-field style="width: 100%">
        <input
          type="text"
          #listName
          matInput
          [(ngModel)]="surveyUserList"
          (input)="listNameHandler(listName.value)"
          placeholder="Enter list name if you want to make it a list e.g Sales Employee"
        />
      </mat-form-field>
    </div>
    <table>
      <tr>
        <th *ngIf="data.type !== 'ViewUserList'">
          <mat-checkbox class="example-margin" (click)="selectAllUsers(searchStaffMember.value)"
            ><b>Select</b></mat-checkbox
          >
        </th>
        <th>User Name</th>
        <th>User Email</th>
      </tr>
      <tr
        *ngFor="let user of data.users; let i = index"
        [ngClass]="{
        'hide': !user.isMatchingWithSearch
      }"
      >
        <td style="width: 30px" *ngIf="data.type !== 'ViewUserList'">
          <mat-checkbox [(ngModel)]="user.selected" (click)="checkforAddStaffBtnDisable()"></mat-checkbox>
        </td>
        <td>{{user.displayName}}</td>
        <td>{{user.email}}</td>
      </tr>
    </table>
  </div>
</div>
