import { Component, Inject, Input, Optional } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "src/app/services/auth.service";
import { SettingsService } from "src/app/services/settings.service";
import { EmailSetupForm } from "./settings.component";

@Component({
    selector: 'email-setup-view',
    templateUrl: 'email-setup-view.html',
    styleUrls: ['./settings.component.css'],
  })
  export class EmailSetupViewComponent {
    userTokenData: any;
    @Input() provider: number = 0;
    getMailSettingData: any;
    emailProvider: any;
    constructor(
      public dialog: MatDialog,
      private auth: AuthService,
      private settingsService: SettingsService
    ) {
      this.userTokenData = auth.decodeToken();
    
    }
  
    ngOnInit() {
      this.settingsService.getEmailSettingByEmailId(this.userTokenData.email).subscribe((res: any) => {
        this.getMailSettingData = res[0];
        
       
      });
    }
    getMailSetting(){
        this.settingsService.getEmailSettingByEmailId(this.userTokenData.email).subscribe((res: any) => {
          this.emailProvider=res[0]?.mailSetting.provider+"";
      })
      }
      openEmailSetupFormHandler(val: number) {
        let dialogR = this.dialog.open(EmailSetupForm, {
          height: '600px',
          width: '700px',
          data: { provider: val },
        });
        dialogR.afterClosed().subscribe(res=>{
          this.getMailSetting()
         })
      }
  
 
  }