import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MsalService } from '@azure/msal-angular';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ContactsService } from 'src/app/services/contacts.service';

@Component({
  selector: 'app-unsubscriber-list',
  templateUrl: './unsubscriber-list.component.html',
  styleUrls: ['./unsubscriber-list.component.css']
})

export class UnsubscriberListComponent {
  records: any[] = [];
  jsondatadisplay: any;
  recordNotFound: Boolean = false;
  loadingImportedStaff: boolean = false;
  organizationId: string = '';
  isListLoaded: boolean = false;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: any[] = ['email', 'type'];
  downloadReport: any = [];
  allSurveyList: any[] = [];
  userTokenData: any;
  staffMembers: any[] = [];
  isMarkitingListCreated: boolean = false;
  allCustomerLists: any[] = [];
  selectedList: any = {
    listName: '',
  };



  constructor(
    private authService: MsalService,
    public _toastr: ToastrService,
    public authSer: AuthService,
    private _contactService: ContactsService,
  ) {
    this.userTokenData = this.authSer.decodeToken();
    this.organizationId = this.userTokenData.organizationId;
    this.isListLoaded = false;
    this.authSer.getAllUnsubscribedEmail(this.organizationId).subscribe((allLists: any) => {
      this.isListLoaded = true;
      if (allLists.length === 0) {
        this.recordNotFound = true;

        return;
      }

      this.allCustomerLists = allLists;
      
        this.dataSource = allLists.map((list: any) => {
          return { email: list.email,type:list.type==1?"Business":"ALL" };
        });
      });
   
  }

  exportAsXLSX(): void {
    this._contactService.exportAsExcelFile(this.downloadReport, 'sample');
  }

  getSingleList(list: any) {
    this.isListLoaded = false;
    this._contactService.getSingleListData(list._id).subscribe(res => {
      this.isListLoaded = true;
      this.dataSource = res.contacts.map((list: any) => {
        return { name: list.name, email: list.email };
      });
    });
  }

  // to open the dialog when react pack selected
 

  logout() {
    this.authService.logoutPopup({
      postLogoutRedirectUri: '/dashboard',
      mainWindowRedirectUri: '/dashboard',
    });
  }

  // Mat table functions
  dataSource = new MatTableDataSource<any>();



  // check etension
  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

 



  validateEmail(email: any) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
}

