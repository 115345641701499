<div *ngIf="showCreateSurvey">
  <div style="display: flex; justify-content: flex-end" class="mb-20">
    <button
      mat-fab
      extended
      color="primary"
      style="display: block"
      class="ml-20"
      (click)="showCreateSurvey = !showCreateSurvey"
    >
      Go to Project list
    </button>
  </div>
  <div id="surveyCreator">
    <survey-creator [model]="surveyCreatorModel"></survey-creator>
  </div>
</div>
<div
  *ngIf="isLoadingSurvey"
  style="
    margin-top: 130px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 999;
  "
>
  <mat-spinner style="margin: auto"></mat-spinner>
</div>
<div *ngIf="!showCreateSurvey" style="padding: 15px 45px">
  <div class="setup-container flex-sb" style="align-items: flex-start !important">
    <div>
      <h1>Online Surveys</h1>
      <button mat-fab extended color="primary" (click)="downloadSurveyByOrgId()">Download Survey Result</button>
    </div>
    <div>
      <div class="flex" style="justify-content: end">
        <app-logout-btn></app-logout-btn>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: flex-end" class="mb-20">
    <button
      mat-fab
      extended
      color="primary"
      style="display: block"
      class="ml-20"
      (click)="openSurveyProjectNameModal('0ms', '0ms', { name: 'New Project Name', isEdit: false }, 'project')"
    >
      Create New Project
    </button>
  </div>

  <mat-accordion displayMode="flat" multi class="mat-table">
    <section matSort class="mat-elevation-z2 mat-header-row">
      <span class="mat-header-cell" mat-sort-header="projectName">Project Name</span>
      <span class="mat-header-cell" mat-sort-header="createdDate">Created Date</span>
      <span class="mat-header-cell" mat-sort-header="totalSurveys">Total Surveys</span>
      <span class="mat-header-cell" mat-sort-header="action">Action</span>
    </section>

    <mat-expansion-panel *ngFor="let project of AllProjects">
      <mat-expansion-panel-header class="mat-row">
        <span class="mat-cell">{{ project.projectName }}</span>
        <span class="mat-cell">{{ getDateAndTime(project.createdAt) }}</span>
        <span class="mat-cell">{{ project?.surveyList?.length }}</span>
        <span class="mat-cell" style="width: 30%; height: 22px">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            style="padding: 0; height: 0"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="
                openSurveyProjectNameModal(
                  '0ms',
                  '0ms',
                  { name: project.projectName, projectId: project._id, isEdit: true },
                  'project'
                )
              "
            >
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button *ngIf="!project?.surveyList?.length"
              mat-menu-item
              (click)="
                openSurveyProjectNameModal(
                  '0ms',
                  '0ms',
                  { name: 'New Survey Name', projectId: project._id, isEdit: false },
                  'survey'
                )
              "
            >
              <mat-icon>create</mat-icon>
              <span >Create Survey</span>
            </button>
            <button mat-menu-item (click)="openProjectRemoveDialog('0ms', '0ms', { projectId: project._id })">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </span>
      </mat-expansion-panel-header>
      <div *ngIf="project?.surveyList?.length > 0">
        <mat-card style="border: 1px solid gray">
          <mat-card-content>
            <h4>All Surveys</h4>
            <table>
              <tr>
                <th>Survey Name</th>
                <th>Created Date</th>
                <th>Survey type</th>
                <th>Is NPS</th>
                <th>Anonymous</th>
                <th>Actions</th>
              </tr>
              <tr *ngFor="let survey of project.surveyList">
                <td style="width: 30%">{{ survey.surveyName }}</td>
                <td>{{ getDateAndTime(survey.createdAt) }}</td>
                <td>{{ survey.isHrSurvey ? 'Staff' : 'Other' }}</td>
                <td>{{ survey.isNPS ? 'true' : 'false' }}</td>
                <td>{{ survey.isAnonymousSurvey ? 'Yes' : 'No' }}</td>

                <td>
                  <span class="mat-cell" style="width: 30%; height: 22px">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      style="padding: 0; height: 0"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu style="display: initial" #menu="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          openSurveyProjectNameModal(
                            '0ms',
                            '0ms',
                            {
                              name: survey.surveyName,
                              surveyId: survey._id,
                              isHrSurvey: survey.isHrSurvey,
                              isAnonymousSurvey: survey.isAnonymousSurvey,
                              isEdit: true
                            },
                            'survey'
                          )
                        "
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Edit Name</span>
                      </button>
                      <button mat-menu-item (click)="editSingleSurvey(survey._id)">
                        <mat-icon>create</mat-icon>
                        <span>add content</span>
                      </button>
                      <button
                        mat-menu-item
                        (click)="openSurvey(userTokenData.organizationId, survey._id, userTokenData.userId)"
                      >
                        <mat-icon>remove_red_eye</mat-icon>
                        <span>View</span>
                      </button>
                      <button mat-menu-item (click)="openResultDashboard(userTokenData.organizationId, survey._id)">
                        <mat-icon>list</mat-icon>
                        <span>Result</span>
                      </button>
                      <button mat-menu-item (click)="downloadSurveyBySurveyId(survey._id)">
                        <mat-icon>list</mat-icon>
                        <span>Download Result</span>
                      </button>

                      <button
                        mat-menu-item
                        (click)="
                          openSendSurveyModal('0ms', '0ms', {
                            surveyId: survey._id,
                            isHrSurvey: survey.isHrSurvey,
                            createdByUserId: userTokenData.userId,
                            orgId: userTokenData.organizationId,
                            isAnonymousSurvey: survey.isAnonymousSurvey
                          })
                        "
                      >
                        <mat-icon>send</mat-icon>
                        <span>Send</span>
                      </button>
                      <button (click)="onShareClickHandler()" mat-menu-item>
                        <mat-icon>share</mat-icon>
                        <span>Share</span>
                      </button>
                      <button mat-menu-item (click)="openSurveyRemoveDialog('0ms', '0ms', { survey: survey._id })">
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                      </button>
                    </mat-menu>
                  </span>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <h3 *ngIf="!isLoadingAllProjects && AllProjects.length === 0" style="text-align: center; margin-top: 20px">
    No Project created yet!!
  </h3>
  <div
    *ngIf="isLoadingAllProjects"
    class="flex-c"
    style="height: 100%; width: 100%; position: absolute; top: 0; z-index: 99"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
