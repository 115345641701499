<div mat-dialog-content style="padding: 20px">
  <h2 style="text-align: center">Edit Heading</h2>
</div>
<div class="flex-c flex-col">
  <mat-form-field style="width: 80%; margin: auto">
    <input type="text" matInput [(ngModel)]="headingText" placeholder="Enter staff full name" />
    <mat-error> Full name is <strong>required</strong> </mat-error>
  </mat-form-field>
  <div mat-dialog-actions class="flex-sb">
    <button
      extended
      mat-stroked-button
      (click)="onNoClick()"
      style="height: 56px; border-radius: 30px; margin-right: 70px; width: 100px"
    >
      Cancel
    </button>
    <button
      mat-fab
      extended
      color="primary"
      style="display: block; width: 100px"
      [mat-dialog-close]="{ id: data.heading._id, text: headingText}"
    >
      Save
    </button>
  </div>
</div>
