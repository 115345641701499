import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Constant } from './Constant';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HandleErrorsInterceptorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService, private router: Router,private msalService: MsalService,private authService:AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        debugger
        console.log('error: ', err);
        if (err.status === 401) {
          const accessTokenRequest = {
            scopes: environment.apiConfig.scopes,
            account: this.msalService.instance.getAllAccounts()[0],
          };
              this.msalService.acquireTokenSilent(accessTokenRequest).subscribe( (accessTokenReponse) => {
                if(accessTokenReponse != null) {
                  this.authService.settoken(accessTokenReponse.accessToken);
                }
              })
          this.toastr.error(
            typeof err.error === 'string' ? err.error : Constant.shared.errorMsg,
            Constant.shared.error
          );
          this.router.navigate(['/']);
        } else {
          this.toastr.error(err.error || Constant.shared.errorMsg, Constant.shared.error);
        }
        return throwError(err);
      })
    );
  }
}
