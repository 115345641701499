import { Dialog } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, catchError, filter, throwError } from 'rxjs';
import { Constant } from 'src/app/Constant';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,private dialog:Dialog
  ) {
    console.log("data",this.data)
  }
  registerForm = new FormGroup(
    {
      fullName: new FormControl(this.data[0].name, [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      registerEmail: new FormControl(this.data[0].username, [Validators.required, Validators.email]),      
      acceptTerms: new FormControl(false, [Validators.required]),
    },
    
  );
  isCreateSignUpClicked: boolean | undefined;

  agreementChecked = false;
  subscriptions: Subscription[] = [];

  get fullName(): any {
    return this.registerForm.get('fullName');
  }

  get companyName(): any {
    return this.registerForm.get('companyName');
  }

  get registerEmail(): any {
    return this.registerForm.get('registerEmail');
  }



  get acceptTerms(): any {
    return this.registerForm.get('acceptTerms');
  }
  ngOnInit() {
    console.log("data",this.data)
  }
   // register form submit
   onRegisterFormSubmit(val: any) {
    this.isCreateSignUpClicked = true;
    var registerobj = {
      userName: this.registerForm.value.fullName,
      organizationName: this.registerForm.value.companyName,
      email: this.registerForm.value.registerEmail,
      address: '',
      phoneNumber: '',
      status: 1,
    };
    this.authService.registerUser(registerobj).subscribe(
      (data: any) => {
        this.isCreateSignUpClicked = false;
        this.toastr.success(Constant.shared.successfullyRefistered, Constant.shared.sucess);
        this.dialog.closeAll()
        this.router.navigate(['dashboard']);
      },
      err => {
        if (err.status === 409) {
         this.toastr.error(err)
        }
        this.isCreateSignUpClicked = false;
      }
    );
  }
}
