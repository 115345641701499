<div mat-dialog-content style="padding: 20px">
  <h2 style="text-align: center">
    {{data.isEdit ? 'Edit ' : 'Create'}} {{data.type === 'project' ? 'Project Name' : 'Survey Name'}}
  </h2>
</div>
<div class="flex-c flex-col">
  <mat-form-field style="width: 80%; margin: auto">
    <input type="text" matInput [(ngModel)]="headingText" placeholder="Enter Survey Name" />
    <mat-error *ngIf="headingText.trim().length === 0"> Survey Name is <strong>required</strong> </mat-error>
  </mat-form-field>
  <!-- <mat-form-field> -->
  <div class="flex mb-20">
    <div style="padding-right: 25px">
      <div class="">Is Staff Survey</div>
      <mat-slide-toggle
        *ngIf="data.type !== 'project'"
        class="example-margin allow-user mb-5"
        color="accent"
        [checked]="isHrSurvey"
        [(ngModel)]="isHrSurvey"
      >
      </mat-slide-toggle>
    </div>
    <div style="padding-right: 25px">
      <div class="">Is Anonymous Survey</div>
      <mat-slide-toggle
        *ngIf="data.type !== 'project'"
        class="example-margin allow-user mb-5"
        color="accent"
        [checked]="isAnonymousSurvey"
        [(ngModel)]="isAnonymousSurvey"
      >
      </mat-slide-toggle>
    </div>
    <div class="">
      <div class="">Is NPS Survey</div>
      <mat-slide-toggle
        *ngIf="data.type !== 'project'"
        class="example-margin allow-user mb-5"
        color="accent"
        [checked]="isNPSSurvey"
        [(ngModel)]="isNPSSurvey"
      >
      </mat-slide-toggle>
    </div>
  </div>
  <div mat-dialog-actions class="flex-sb">
    <button
      extended
      mat-stroked-button
      (click)="onNoClick()"
      style="height: 56px; border-radius: 30px; margin-right: 70px; width: 100px"
    >
      Cancel
    </button>
    <button
      mat-fab
      extended
      color="primary"
      style="display: block; width: 100px"
      [mat-dialog-close]="{data, name: headingText, isHrSurvey, isAnonymousSurvey,isNPSSurvey}"
    >
      {{data.isEdit ? 'Update' : 'Save'}}
    </button>
  </div>
</div>
