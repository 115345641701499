<div style="padding: 15px 45px">
  <div>
    <div style="width: 25%">
      <h1 style="margin-top: 10px">Marketing List</h1>
    </div>
    <div style="width: 45%" class="flex"></div>
    <!-- <div style="width: 30%; text-align: right">
      <button mat-fab extended color="primary" class="mr-20" (click)="exportAsXLSX()">
        Download Survey of Customers List
      </button>
      <app-logout-btn></app-logout-btn>
    </div> -->
  </div>

  <!-- CSV  -->

  <div
    *ngIf="isMarkitingListCreated"
    class="flex-c"
    style="height: 100%; width: 100%; position: absolute; top: 0; z-index: 9"
  >
    <mat-spinner style="top: -200px"></mat-spinner>
  </div>
  <div>
    <mat-card class="shadow-none">
      <mat-card-content style="padding: 0">
        <h4 class="mt-20">Upload csv file of Contacts List</h4>
        <div class="flex-c">
          <div class="flex-c mt-20" style="gap: 30px">
            <img src="../../../assets/images/csv upload.svg" />
            <div>
              <input
                type="file"
                #csvReader
                name="Upload CSV"
                id="txtFileUpload"
                (change)="uploadListener($event)"
                accept=".csv"
                style="font-size: 20px"
              />
              <mat-icon
                matTooltip="Please upload csv file with two columns 'name' and 'email'"
                matTooltipPosition="above"
                aria-hidden="false"
                aria-label="Admin"
                fontIcon="info"
                style="margin-left: 10px; margin-bottom: -6px"
                color="warn"
              ></mat-icon>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div style="margin-top: 50px">
    <div class="list-options flex">
      <div style="padding-right: 10px">
        <h2 style="font-weight: 400 !important">Filter by List Name</h2>
        <mat-form-field class="flex-row" style="width: 500px">
          <mat-select [value]="selectedList.listName" name="food">
            <mat-option [value]="list.listName" *ngFor="let list of allCustomerLists" (click)="getSingleList(list)">
              {{ list.listName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="flex flex-col" style="width: 300px">
        <div class="flex flex-col" style="width: 200px">
          <h2 style="font-weight: 400 !important">Filter by Survey</h2>
          <mat-form-field class="flex-row" style="width: 300px">
            <mat-select [value]="selectedSurvey.surveyName" name="food">
              <mat-option *ngFor="let survey of allSurveyList" [value]="survey.surveyName">
                {{ survey.surveyName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->

      <!-- <div class="flex flex-col ml-20" style="width: 200px">
        <h2 style="font-weight: 400 !important">Filter by Staff</h2>
        <mat-form-field class="flex-row" style="width: 300px">
          <mat-label>Show All</mat-label>
          <mat-select name="food">
            <mat-option value="All"> All Staff </mat-option>
            <mat-option *ngFor="let staff of staffMembers" [value]="staff.value">
              {{ staff.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <!-- <div class="flex flex-col ml-20" style="width: 200px">
        <h2 style="font-weight: 400 !important">Filter by Rating</h2>
        <mat-form-field class="flex-row" style="width: 300px">
          <mat-label>Show All</mat-label>
          <mat-select name="food">
            <mat-option value="0"> All Ratings </mat-option>
            <mat-option *ngFor="let opt of ratings" [value]="opt.value">
              {{ opt.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <!-- <div class="flex flex-col ml-20" style="width: 300px">
        <h2 style="font-weight: 400 !important">Filter by Time Range</h2>
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date" #dateRangeStart />
            <input matEndDate formControlName="end" placeholder="End date" #dateRangeEnd />
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div> -->
    </div>

    <div class="results"></div>
  </div>

  <div class="add-agent-tab">
    <div class="mt-40">
      <h3>Customers List</h3>

      <div>
        <div *ngIf="!isListLoaded" style="margin: auto; margin-top: 130px; text-align: center">
          <mat-spinner style="margin: auto"></mat-spinner>
        </div>
        <table *ngIf="isListLoaded" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- contactName Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Full Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
          </ng-container>

          <!-- isCrmContact Column -->
          <!-- <ng-container matColumnDef="isCrmContact">
            <th mat-header-cell *matHeaderCellDef>IsCrmContact</th>
            <td mat-cell *matCellDef="let element">False</td>
          </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div *ngIf="recordNotFound" style="text-align: center; margin-top: 50px">
  <h1>no record found</h1>
</div>
<div
  *ngIf="loadingImportedStaff"
  class="flex-c"
  style="height: 100%; width: 100%; position: absolute; top: 0; z-index: 9"
>
  <mat-spinner style="top: -200px"></mat-spinner>
</div>
