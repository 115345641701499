import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup, FormControlName } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/services/auth.service';
import { StaffMembersService } from 'src/app/services/staff-members.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SettingsService } from 'src/app/services/settings.service';
import { Constant } from 'src/app/Constant';
import { ToastrService } from 'ngx-toastr';

export class CsvData {
  public name: any;
  public email: any;
}

export interface DialogData {
  memberName: string;
  memberEmail: string;
}
@Component({
  selector: 'app-staff-member',
  templateUrl: './staff-member.component.html',
  styleUrls: ['./staff-member.component.css'],
})
export class StaffMemberComponent implements OnInit {
  selectedAdminType!: string;
  selectedFile: any;
  memberName!: string;
  memberEmail!: string;
  importedUsersList: any[] = [];
  loadingImportedStaff: boolean = false;
  records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  jsondatadisplay: any;
  organizationId: string | null | undefined;
  isAzureAdminChecked: boolean = false;

  isIframe = false;
  loginDisplay = false;
  isStaffLoaded: boolean = false;

  globalAdmin: any[] = [
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' },
  ];
  isAllAdmin: boolean = false;
  isAllActive: boolean = false;

  selection = new SelectionModel<any>(true, []);
  displayedColumns: any[] = ['displayName', '_id', 'email', 'admin', 'remove'];

  constructor(
    public dialog: MatDialog,
    private authService: MsalService,
    public staffService: StaffMembersService,
    public authSer: AuthService,
    private toastr: ToastrService
  ) {
    const userTokenData = this.authSer.decodeToken();
    this.organizationId = userTokenData.organizationId;
    this.isStaffLoaded = false;
    this.staffService.getAllUsers(this.organizationId).subscribe(res => {
      this.dataSource = res;
      this.isAllAdminChecked('status');
      this.isAllAdminChecked('role');
      this.isStaffLoaded = true;
    });
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    if (!this.authSer.isSuperAdmin()) {
      this.displayedColumns = ['displayName', '_id', 'email', 'admin'];
    }
  }

  login() {
    this.authService.loginPopup({ scopes: ['User.Read.All'] }).subscribe({
      next: result => {
        this.loadingImportedStaff = true;
        this.setLoginDisplay();
        this.staffService.getMS365UsersList(result.accessToken).subscribe(data => {
          this.importedUsersList = data.value.map((ele: any) => {
            ele.selected = false;
            ele.isMatchingWithSearch = true;
            ele.email = ele.mail;
            return ele;
          });
          // to avoid duplicate users from importing
          const sourceDataEmails: any = {};
          const sourceUsersList: any = this.dataSource;
          for (let user of sourceUsersList) {
            sourceDataEmails[user.email] = true;
          }

          this.importedUsersList = this.importedUsersList.filter(user => !sourceDataEmails[user.email]);
          this.openStaffImportDialog('0ms', '0ms', this.importedUsersList);
          this.loadingImportedStaff = false;
        });
      },
      error: error => console.log(error),
    });
  }

  openAssignAdminRoleModal(enterAnimationDuration: string, exitAnimationDuration: string, userList: any): void {
    const originalUserList = JSON.parse(JSON.stringify(userList));
    const usersList = [];
    for (let user of userList) {
      if (user._id !== this.authSer.decodeToken()._id) {
        user.isMatchingWithSearch = true;
        user.selected = false;

        if (user?.role === 1) {
          user.selected = true;
        }

        usersList.push(user);
      }
    }

    let dialogRef = this.dialog.open(ImportStaffMember, {
      width: '670px',
      height: '500px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { users: usersList, title: 'Assign Admin Permission', btnTxt: 'Assign Permission' },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        const selectedUsers = [];
        for (let user of res.data) {
          user.selected === true ? (user.role = 1) : (user.role = 0);
          delete user.selected;
          delete user.isMatchingWithSearch;

          for (let uu of originalUserList) {
            if (uu._id === user._id && uu.role !== user.role) {
              selectedUsers.push(user);
            }
          }
        }
        this.staffService.addStaffMembers(selectedUsers).subscribe(res => {
          this.toastr.success(Constant.shared.adminAdded, Constant.shared.sucess);
        });
      }
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  logout() {
    this.authService.logoutPopup({
      postLogoutRedirectUri: '/dashboard',
      mainWindowRedirectUri: '/dashboard',
    });
  }

  openAddStaffMemberDialog(): void {
    const dialogRef = this.dialog.open(AddStaffMember, {
      data: { memberEmail: this.memberEmail, memberName: this.memberName },
    });

    dialogRef.afterClosed().subscribe(user => {
      if (user) {
        const singleUser = {
          _id: '',
          displayName: user.displayName,
          organizationId: this.organizationId,
          email: user.mail,
          password: 'abc',
          createdDate: new Date(),
          status: 0,
        };
        this.staffService.addStaffMembers([singleUser]).subscribe(res => {
          this.dataSource = res;
        });
      }
    });
  }

  // Mat table functions
  dataSource = new MatTableDataSource<any>([]);

  // to open the dialog when react pack selected
  openStaffImportDialog(enterAnimationDuration: string, exitAnimationDuration: string, importedUser: any[]): void {
    let dialogRef = this.dialog.open(ImportStaffMember, {
      width: '670px',
      height: '500px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        users: importedUser,
        title: 'Imported Users List',
        btnTxt: 'Add Staff Members',
        search: 'Search Staff Member',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        const selectedUsers = [];
        for (let user of res.data) {
          if (user.selected === true) {
            const singleUser = {
              _id: '',
              displayName: user.displayName,
              organizationId: this.organizationId,
              email: user.mail,
              password: 'abc',
              createdDate: new Date(),
              status: 0,
            };

            selectedUsers.push(singleUser);
          }
        }
        if (selectedUsers.length) {
          this.staffService.addStaffMembers(selectedUsers).subscribe(res => {
            this.dataSource = res;
          });
        }
      }
    });
  }

  // to open the dialog when react pack selected
  openStaffRemoveDialog(enterAnimationDuration: string, exitAnimationDuration: string, userId: string): void {
    let dialogRef = this.dialog.open(RemoveStaffMember, {
      width: '400px',
      height: '180px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { userId, title: 'Are you sure to remove this user?', btnTxt: 'Remove' },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      if (res) {
        const data: any = [];
        for (let user of Object.entries(this.dataSource)) {
          if (user[1]._id !== res.userId) {
            data.push(user[1]);
          }
        }
        this.dataSource = data;
        this.staffService.removeStaffMember(res.userId).subscribe(() => {});
      }
    });
  }

  uploadListener($event: any): void {
    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

        let headersRow = this.getHeaderArray(csvRecordsArray);

        const ImportedUsers = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
        this.records = ImportedUsers.filter(user => this.validateEmail(user.email));
        if (this.records.length === 0) {
          alert('Please import valid .csv file with name and email.');
          this.fileReset();
        } else {
          this.records = this.records.map((ele: any) => {
            ele.selected = false;
            ele.isMatchingWithSearch = true;
            ele.displayName = ele.name;
            ele.email = ele.email;

            return ele;
          });
          this.openStaffImportDialog('0ms', '0ms', this.records);
        }
      };

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };
    } else {
      alert('Please import valid .csv file.');
      this.fileReset();
    }
  }

  //check etension
  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord: CsvData = new CsvData();
        csvRecord.name = curruntRecord[0].trim();
        csvRecord.email = curruntRecord[1].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
    this.jsondatadisplay = '';
  }

  validateEmail(email: any) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  adminCheckHandler(event: MatCheckboxChange, data: any, role: string) {
    data[role] = event.checked ? 1 : 0;
    this.isAllAdminChecked(role);
    this.staffService.addStaffMembers(this.dataSource).subscribe(res => {});
  }

  isAllAdminChecked(role: string) {
    const data: any = this.dataSource;
    role === 'role'
      ? (this.isAllAdmin = data.every((ele: any) => ele[role] === 1))
      : (this.isAllActive = data.every((ele: any) => ele[role] === 1));
  }

  allAdminCheckHandler(event: MatCheckboxChange, role: string) {
    const data: any = this.dataSource;
    for (let user of data) {
      user[role] = event.checked ? 1 : 0;
    }
    this.isAllAdminChecked(role);
    this.staffService.addStaffMembers(this.dataSource).subscribe(res => {});
  }
}

@Component({
  selector: 'add-staff-member',
  templateUrl: 'add-staff-member.html',
})
export class AddStaffMember {
  addStaffForm = new FormGroup({
    staffName: new FormControl('', [Validators.required]),
    staffEmail: new FormControl('', [Validators.required, Validators.email]),
  });

  get staffName(): any {
    return this.addStaffForm.get('staffName');
  }

  get staffEmail(): any {
    return this.addStaffForm.get('staffEmail');
  }

  constructor(
    public dialogRef: MatDialogRef<AddStaffMember>,
    public settingsService: SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'import-staff-member',
  templateUrl: 'import-staff-member.html',
})
export class ImportStaffMember {
  isAllSelected: boolean = false;
  searchUserName: string = '';
  isAddStaffDisabled: boolean = true;
  surveyUserList = '';

  addListNameForm = new FormGroup({
    addListName: new FormControl('', [Validators.required]),
  });

  get addListName(): any {
    return this.addListNameForm.get('addListName');
  }

  constructor(public dialogRef: MatDialogRef<ImportStaffMember>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('modal data: ', data);
    this.checkforAddStaffBtnDisable();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectAllUsers(searchText: string) {
    this.isAllSelected = !this.isAllSelected;
    if (searchText.trim().length > 0) {
      this.data.users = this.data.users.map((ele: any) => {
        this.isAllSelected && ele.isMatchingWithSearch
          ? ((ele.selected = true), (this.isAddStaffDisabled = false))
          : ((ele.selected = false), (this.isAddStaffDisabled = true));
        return ele;
      });
    } else {
      this.data.users = this.data.users.map((ele: any) => {
        this.isAllSelected
          ? ((ele.selected = true), (this.isAddStaffDisabled = false))
          : ((ele.selected = false), (this.isAddStaffDisabled = true));
        return ele;
      });
    }
  }

  listNameHandler(listName: string) {
    if (listName.trim().length === 0) {
      this.surveyUserList = '';
    } else {
      this.surveyUserList = listName;
    }
  }

  searchStaffMemberHandler(searchText: string): void {
    if (searchText.trim().length === 0) {
      this.data.users = this.data.users.map((user: any) => {
        user.isMatchingWithSearch = true;

        return user;
      });
      return;
    }

    this.data.users = this.data.users.map((user: any) => {
      if (user.displayName.toLowerCase().includes(searchText.trim().toLowerCase())) {
        user.isMatchingWithSearch = true;
      } else {
        user.isMatchingWithSearch = false;
      }

      return user;
    });
  }

  checkforAddStaffBtnDisable() {
    this.isAddStaffDisabled = !this.data.users.some((user: any) => user.selected);
  }

  closeStaffDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'remove-staff-member',
  templateUrl: 'remove-staff-member.html',
})
export class RemoveStaffMember {
  constructor(public dialogRef: MatDialogRef<AddStaffMember>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
