export class Constant {
  public static shared = {
    sucess: 'Success',
    error: 'Error',
    errorMsg: 'Something went wrong please try again!!',
    invalidPassword: 'Invalid credentials',
    invalidEmail: 'Invald Email! Please enter correct email.',
    successfullyRefistered: 'Successfully Registred, Please login',
    paymentSuccess: 'Payment Successfully done.',
    surveyCopied: 'Copied to Clipboard',
    surveyCodeCopied: 'Code Copied to Clipboard',
    SurveyEmailSent: 'Survey Email sent to Staff',
    adminAdded: 'Email sent to Staff',
    sendSurveyEmail: 'Email sent Successfuly',
  };
}
