<div mat-dialog-content style="width: 350px; height: 250px; max-height: 380px; margin: auto">
  <p style="text-align: center">Update Your Password</p>
  <form [formGroup]="resetPassword">
    <mat-form-field style="width: 100%; margin: auto">
      <input type="password" matInput formControlName="newPassword" placeholder="Enter Your New Password" />
      <mat-error *ngIf="newPassword.hasError('newPassword')"> New Password is <strong>required</strong> </mat-error>
    </mat-form-field>
    <div mat-dialog-actions class="flex-c">
      <button
        extended
        mat-stroked-button
        (click)="onNoClick()"
        style="height: 56px; border-radius: 30px; margin-right: 20px; width: 100px"
      >
        Cancel
      </button>
      <button
        mat-fab
        extended
        color="primary"
        [mat-dialog-close]="{ newPassword: resetPassword.value.newPassword}"
        [disabled]="!resetPassword.valid"
        style="display: block; width: 120px"
      >
        Update Password
      </button>
    </div>
  </form>
</div>
