import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { DocumentAnalysisService } from 'src/app/services/document-analysis.service';
import { getCurrentDateTime } from 'src/app/utility';

@Component({
  selector: 'app-document-analysis',
  templateUrl: './document-analysis.component.html',
  styleUrls: ['./document-analysis.component.css'],
})
export class DocumentAnalysisComponent {
  selectedFile: any;
  @ViewChild('docReader') docReader: any;
  userTokenData: any;
  isDocResultLoaded = false;
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['userName', 'createdAt', 'result', 'url'];
  selection = new SelectionModel<any>(true, []);
  isResultEmpty = true;
  isFileProcessing = false;

  constructor(public authSer: AuthService, private docService: DocumentAnalysisService, public dialog: MatDialog) {
    this.userTokenData = this.authSer.decodeToken();
    console.log('userTokenData: ', this.userTokenData);
    this.docService.getDocumentResultList(this.userTokenData.organizationId).subscribe(res => {
      this.dataSource = res;
      this.isDocResultLoaded = true;
      this.isResultEmpty = res.length > 0 ? false : true;
    });
  }

  // to open the dialog when react pack selected
  openProcessedModal(enterAnimationDuration: string, exitAnimationDuration: string, data: any): void {
    let dialogRef = this.dialog.open(ViewProcessedResultModel, {
      width: '400px',
      height: '260px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { ...data },
    });

    dialogRef.afterClosed().subscribe(res => {
      // received data from dialog-component
      this.docService.getDocumentResultList(this.userTokenData.organizationId).subscribe(res => {
        this.dataSource = res;
        this.isDocResultLoaded = true;
        this.isResultEmpty = res.length > 0 ? false : true;
      });
    });
  }

  getScore(score: number) {
    return `${(score * 10000) / 100}%`;
  }

  getDateAndTime(time: string) {
    return getCurrentDateTime(time);
  }

  //check etension
  isValidCSVFile(file: any) {
    return (
      file.name.endsWith('.docx') ||
      file.name.endsWith('.doc') ||
      file.name.endsWith('.txt') ||
      file.name.endsWith('.csv') ||
      file.name.endsWith('.xlsx')
    );
  }

  sendFileToAnalyse($event: any) {
    console.log('event: ', $event);
    let files = $event.srcElement.files;
    console.log(files, files.item[0]);

    if (this.isValidCSVFile(files[0])) {
      this.isFileProcessing = true;
      const formData = new FormData();
      formData.append('file', files[0], files[0].name);
      formData.append('userId', this.userTokenData.userId);
      formData.append('userName', this.userTokenData.displayName);
      formData.append('orgId', this.userTokenData.organizationId);
      this.docService.sendDocumentForAnalysis(formData).subscribe(res => {
        if (files[0].name.endsWith('.docx') || files[0].name.endsWith('.doc') || files[0].name.endsWith('.txt')) {
          const score = res?.result[0]?.sentiment?.score;
          this.openProcessedModal('0ms', '0ms', {
            score,
          });
        }
        this.isFileProcessing = false;
      });
    } else {
      alert('Please import valid file (.docx, .doc, .txt, .csv, .xlsx).');
    }
  }
}

@Component({
  selector: 'view-processed-result',
  templateUrl: 'processed-doc-result.component.html',
})
export class ViewProcessedResultModel {
  constructor(public dialogRef: MatDialogRef<ViewProcessedResultModel>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('data: ', data);
  }

  getScore(score: number) {
    return `${(score * 10000) / 100}`;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
