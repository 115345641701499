<div *ngIf="isSurveyLoaded && !isFeedbackSent">
  <div *ngIf="surveyDetails?.logo?.length > 0" style="padding: 10px 0">
    <img src="{{ surveyDetails?.logo }}" style="height: 50px; width: 200px; display: block; margin: auto" />
  </div>
  <div style="background-color: #d9e5ed">
    <div class="main-cont">
      <div class="row" style="background-color: #d9e5ed; padding-bottom: 50px">
        <div class="row">
          <div class="col-12">
            <h2 style="text-align: center; padding: 20px 0 !important">{{ surveyDetails?.name }}</h2>
          </div>
        </div>
        <div class="col-md-3 d-sm-none d-md-block"></div>
        <div
          style="background-color: #fff; box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.3); padding: 35px !important"
          class="col-md-6 col-sm-12 d-flex flex-column px-4 py-4"
        >
          <p>
            Dear Customer, <br />
            Thank you for rating our service. We would like to know how we performed. Please spare some moments to give
            us your valuable feedback as it will help us in improving our service.
          </p>
          <h5 style="padding: 26px 0 !important">
            We appreciate your rating! Your feedback will help us to become a self-improver.
          </h5>
          <div>
            <img
              src="{{ surveyDetails?.url }}"
              height="76"
              width="80"
              style="border-radius: 20px; margin: 0px 0 20px 0; display: block"
              class="mb-2"
            />
            <p style="margin-bottom: 40px; font-size: 18px">
              <strong [ngStyle]="{ color: surveyDetails.color }">{{ surveyDetails?.rate }} </strong>
            </p>
            <form [formGroup]="sendSurveyFeedback">
              <mat-form-field style="width: 100%; margin: auto">
                <input type="text" matInput formControlName="fullname" placeholder="Your Full Name" />
                <mat-error *ngIf="fullname.hasError('required')"> Full Name is <strong>required</strong> </mat-error>
              </mat-form-field>
              <mat-form-field style="width: 100%; margin: auto">
                <input type="email" matInput formControlName="email" placeholder="Your Email" />
                <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                  Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="email.hasError('required')"> Email is <strong>required</strong> </mat-error>
              </mat-form-field>
              <mat-form-field style="width: 100%; margin: auto">
                <textarea
                  matInput
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="5"
                  formControlName="feedbackmsg"
                  placeholder="Your Feedback"
                ></textarea>
                <mat-error *ngIf="feedbackmsg.hasError('required')">
                  Your Feedback is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <div mat-dialog-actions class="flex-c">
                <button
                  mat-fab
                  extended
                  color="primary"
                  style="display: block; width: 200px"
                  [disabled]="isSendingFeedback || !sendSurveyFeedback.valid"
                  (click)="submitFeedback()"
                >
                  Submit
                </button>
              </div>
            </form>
            <div style="width: 50%" id="divthankyou"></div>
          </div>
        </div>
        <div class="col-md-3 d-sm-none d-md-block"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isError">
  <h1 style="text-align: center; margin-top: 50px">Something Went Wrong, please contact the Survey Admin</h1>
</div>
<div *ngIf="!isSurveyLoaded" style="margin: auto; margin-top: 130px; text-align: center">
  <mat-spinner style="margin: auto"></mat-spinner>
</div>
<div
  *ngIf="isSendingFeedback"
  style="position: absolute; left: 0; right: 0; margin-left: auto; margin-right: auto; top: 300px"
>
  <mat-spinner style="margin: auto"></mat-spinner>
</div>
<div *ngIf="isFeedbackSent" style="display: flex; justify-content: center; align-items: center; padding-top: 120px">
  <div class="content">
    <div class="wrapper-1">
      <div class="wrapper-2">
        <h1>Thank you !</h1>
        <p>We really appriciate Your Feedback. Your valuable feedback</p>
        <p>will help us in improving our service</p>
        <button class="go-home" (click)="goHome()">go home</button>
        <p style="font-size: 10px; color: gray">We'll be navigating you to main website in 5 seconds</p>
      </div>
    </div>
  </div>
</div>

<link href="https://fonts.googleapis.com/css?family=Kaushan+Script|Source+Sans+Pro" rel="stylesheet" />
