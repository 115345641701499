import { Injectable } from '@angular/core';
import { Customer360 } from '../constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
//@ts-ignore
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  servierUrl = Customer360.serverUrl;

  constructor(private httpClient: HttpClient) {}
  // All Contacts List
  getAllContactsList(orgid: any): Observable<any> {
    return this.httpClient.get(
      `${this.servierUrl}/Contact/GetAllContactMappedWithRatingByAccountId?accountId=${orgid}`
    );
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  addCustomerListName(data: any): Observable<any> {
    return this.httpClient.post(`${this.servierUrl}/CustomerList/PostCustomerList`, data);
  }

  getAllCustomerLists(orgId: any): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/CustomerList/GetCustomerListByOrgId?orgId=${orgId}`);
  }

  getSingleListData(listId: any): Observable<any> {
    return this.httpClient.get(`${this.servierUrl}/CustomerList/GetCustomerListById?id=${listId}`);
  }
}
