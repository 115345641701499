
<div class="mr-20 ml-20" style="display: flex; justify-content: space-between">
  <div class="ml-20">
    <h1 style="margin-top: 10px">Staff Dashboard</h1>
  </div>
  <div class="mr-20">
    <button mat-fab extended color="primary" (click)="exportAsXLSX()">Download</button>
    <div style="text-align: right"><app-logout-btn></app-logout-btn></div>
  </div>
</div>
<div class="mt-10">	<canvas id="npsChart" width="100%" height="250" #npsChart></canvas></div>
<div class="mt-40">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <th class="col-size" mat-header-cell *matHeaderCellDef>Staff Members</th>
      <td class="col-size" mat-cell *matCellDef="let element">{{ element.staffMemberName }}</td>
    </ng-container>

    <!-- <ng-container matColumnDef="star">
      <th
        style="background-color: #fbf7ed"
        class="col-size col-width border-top-style"
        mat-header-cell
        *matHeaderCellDef
      >
        <img style="height: 55px; width: 60px" src="assets/images/emoji-4.png" alt="" />
      </th>
      <td style="background-color: #fbf7ed" class="col-size" mat-cell *matCellDef="let element">
        {{ element.star }}
      </td>
    </ng-container> -->

    <ng-container matColumnDef="positive" style="padding-right: 30px">
      <th
        style="background-color: #ebf7ed"
        class="col-size col-width border-top-style"
        mat-header-cell
        *matHeaderCellDef
      >
        <img style="height: 45px; width: 50px" src="assets/images/emoji-1.png" alt="" />
      </th>
      <td style="background-color: #ebf7ed" class="col-size" mat-cell *matCellDef="let element">
        {{ element.positive }}
      </td>
    </ng-container>

    <ng-container matColumnDef="negative">
      <th
        style="background-color: #fdf7eb"
        class="col-size col-width border-top-style"
        mat-header-cell
        *matHeaderCellDef
      >
        <img style="height: 47px; width: 50px" src="assets/images/emoji-2.png" alt="" />
      </th>
      <td style="background-color: #fdf7eb" class="col-size" mat-cell *matCellDef="let element">
        {{ element.neutral }}
      </td>
    </ng-container>

    <ng-container matColumnDef="angry">
      <th
        style="background-color: #ffe5e5"
        class="col-size col-width border-top-style"
        mat-header-cell
        *matHeaderCellDef
      >
        <img style="height: 48px; width: 52px" src="assets/images/emoji-3.png" alt="" />
      </th>
      <td style="background-color: #ffe5e5" class="col-size" mat-cell *matCellDef="let element">
        {{ element.negative }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalResponse">
      <th class="col-size" mat-header-cell *matHeaderCellDef>Total Responses</th>
      <td class="col-size" mat-cell *matCellDef="let element">{{ element.count }}</td>
    </ng-container>

    <ng-container matColumnDef="NPS">
      <th class="col-size" mat-header-cell *matHeaderCellDef>NPS</th>
      <td class="col-size" mat-cell *matCellDef="let element">{{ element.nps }}</td>
    </ng-container>

    <ng-container matColumnDef="temperature">
      <th style="width: 150px" mat-header-cell *matHeaderCellDef>Engagement</th>
      <td style="width: 150px" mat-cell *matCellDef="let element">{{ element.temperature }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
  </table>
  <mat-card class="spinner" *ngIf="!isAllRatingsLoaded">
    <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
  </mat-card>
  <mat-card class="spinner" *ngIf="recordNotFound">
    <h1>no record found</h1>
  </mat-card>
</div>
