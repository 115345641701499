<div style="padding: 10px;">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="emailProvider">
   
    <div class="flex-sb mb-20 mt-5 align-items-center">
      <div class="flex">
        <mat-radio-button value="0">
          <img src="assets/images/amazon-service1.png" alt=" " class="mr-20" width="60px" height="40px" />
        </mat-radio-button>
        <div class="ml-20">
          <h3 class="m-0">Amazon Web Service </h3>
          <p>Amazon's Simple Email service through SMTP and  protocal</p>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="openEmailSetupFormHandler(0)">Choose</button>
    </div>
    
    <div class="flex-sb mb-20 align-items-center">
      <div class="flex">
        <mat-radio-button value="1">
          <img src="assets/images/brevo-logo.png" alt=" " class="mr-20" width="60px" height="40px" />
        </mat-radio-button>
        <div class="ml-20">
          <h3 class="m-0">Brevo Web API Service </h3>
          <p>Brevo Simple Email service through Web API and SMTP</p>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="openEmailSetupFormHandler(1)">Choose</button>
    </div>
    <div class="flex-sb mb-20 align-items-center">
      <div class="flex">
        <mat-radio-button value="2">
          <img src="assets/images/sendgrid.png" alt=" " class="mr-20" width="60px" height="40px" />
        </mat-radio-button>
        <div class="ml-20">
          <h3 class="m-0">SendGrid API Service</h3>
          <p>SendGrid Simple Email service through Web API and SMTP</p>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="openEmailSetupFormHandler(2)">Choose</button>
    </div>
    <div class="flex-sb mb-20 align-items-center">
      <div class="flex">
        <mat-radio-button value="3">
          <img src="assets/images/mailgun.png" alt=" " class="mr-20" width="60px" height="40px" />
        </mat-radio-button>
        <div class="ml-20">
          <h3 class="m-0">Mailgun API Service</h3>
          <p>Mailgun Email service through Web API v3 and SMTP</p>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="openEmailSetupFormHandler(3)">Choose</button>
    </div>
    <div class="flex-sb mb-20 align-items-center">
      <div class="flex">
        <mat-radio-button value="4">
          <img src="assets/images/mailjet-logo.png" alt=" " class="mr-20" width="60px" height="40px" />
        </mat-radio-button>
        <div class="ml-20">
          <h3 class="m-0">Mailjet API Service</h3>
          <p>Mailjet Email service through Web API and SMTP</p>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="openEmailSetupFormHandler(4)">Choose</button>
    </div>
    <div class="flex-sb mb-20 align-items-center">
      <div class="flex">
        <mat-radio-button value="5">
          <img src="assets/images/Elasticmail-logo.png" alt=" " class="mr-20" width="60px" height="40px" />
        </mat-radio-button>
        <div class="ml-20">
          <h3 class="m-0">Elasticmail API Service </h3>
          <p>Elasticmail Simple Email service through Web API and SMTP</p>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="openEmailSetupFormHandler(5)">Choose</button>
    </div>
  </mat-radio-group>
  </div>