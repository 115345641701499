<div style="padding: 15px 45px">
  <div class="setup-container flex-sb" style="align-items: flex-start !important">
    <div class="flex-sb">
      <h1>Review Analysis (Upload text, Excel and PDF files. No word Documents) </h1>
    </div>
    <div>
      <div class="flex" style="justify-content: end">
        <app-logout-btn></app-logout-btn>
      </div>
    </div>
  </div>
  <div class="flex-c" style="margin-bottom: 60px">
    <div class="flex-c mt-20" style="gap: 30px">
      <div class="flex-c" style="gap: 20px">
        <mat-icon
          matTooltip="Please upload text or excel files only"
          matTooltipPosition="above"
          aria-hidden="false"
          aria-label="Admin"
          fontIcon="info"
          style="margin-left: 10px; margin-bottom: -6px"
          color="warn"
        ></mat-icon>
        <img src="../../../assets/images/csv upload.svg" />
      </div>
      <div>
        <input
          type="file"
          #docReader
          (change)="sendFileToAnalyse($event)"
          name="UploadFile"
          id="txtFileUpload"
          style="font-size: 20px"
          accept=".txt,.xlsx,.pdf"
        />
        <span class="file-name ml-20">{{ selectedFile?.name }}</span>
      </div>
    </div>
  </div>
  <div
    *ngIf="!isDocResultLoaded || isFileProcessing"
    style="top: 200px; position: absolute; left: 0; right: 0; margin-left: auto; margin-right: auto"
  >
    <mat-spinner style="margin: auto"></mat-spinner>
  </div>
  <table *ngIf="isDocResultLoaded" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- userName Column -->
    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef>Uploaded By</th>
      <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
    </ng-container>

    <!-- date Column -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ this.getDateAndTime(element.createdAt) }}</td>
    </ng-container>

    <!-- id Column -->
    <ng-container matColumnDef="result">
      <th mat-header-cell *matHeaderCellDef>
        <span>Result</span>
        <mat-icon
          matTooltip="Gray - Negative, Green - Positive, Orange - Neutral"
          matTooltipPosition="above"
          aria-hidden="false"
          aria-label="Admin"
          fontIcon="info"
          style="margin-left: 10px; margin-bottom: -6px"
          color="warn"
        ></mat-icon>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="flex">
          <div
            [ngStyle]="{ width: getScore(element?.results[0]?.sentiment?.score?.negative) }"
            style="height: 20px; background-color: #808080"
            matTooltip="Negative"
            matTooltipPosition="above"
          ></div>
          <div
            [ngStyle]="{ width: getScore(element?.results[0]?.sentiment?.score?.positive) }"
            style="height: 20px; background-color: #259e0d"
            matTooltip="Positive"
            matTooltipPosition="above"
          ></div>
          <div
            [ngStyle]="{ width: getScore(element?.results[0]?.sentiment?.score?.neutral) }"
            style="height: 20px; background-color: #cc5500"
            matTooltip="Neutral"
            matTooltipPosition="above"
          ></div>
        </div>
      </td>
    </ng-container>

    <!-- staffMember Column -->
    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef>View File</th>
      <td mat-cell *matCellDef="let element">
        <a href="{{ element.url }}">Download</a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
  </table>

  <h3 *ngIf="isDocResultLoaded && isResultEmpty" style="text-align: center; margin-top: 20px">
    No documents uploaded yet!!
  </h3>
</div>
